import * as R from 'ramda';

const getSBHistoryGridData = state => R.pathOr([], ['admintools', 'SBHistoryGridData'], state);
const getEvalStatusOptions = state => R.pathOr([], ['admintools', 'evalStatusOptions'], state);
const getEvalSubstatusOptions = state => R.pathOr([], ['admintools', 'evalSubStatusOptions'], state);
const getCaseStatusOptions = state => R.pathOr([], ['admintools', 'caseStatusOptions'], state);
const getCaseSubstatusOptions = state => R.pathOr([], ['admintools', 'caseSubStatusOptions'], state);
const getChangeStatusResponseData = state => R.pathOr([], ['admintools', 'submitStatusResponse'], state);
const getChangeBulkStatusResponseData = state => R.pathOr([], ['admintools', 'submitBulkStatusResponse'], state);
const getServiceTickets = state => R.pathOr([], ['admintools', 'serviceNowTicket'], state);
const getRequestorIds = state => R.pathOr([], ['admintools', 'requestorId'], state);
const getSAselectedTab = state => R.pathOr('', ['admintools', 'saSelectedTab'], state);
const isFilterEnabled = state => R.pathOr('', ['admintools', 'isFilterEnabled'], state);
const filterPayload = state => R.pathOr({}, ['admintools', 'filterPayload'], state);
const getIdType = state => R.pathOr('', ['admintools', 'idType'], state);
const getRecordsInfo = state => R.pathOr({}, ['admintools', 'recordsInfo'], state);
const getCaseTypes = state => R.pathOr([], ['admintools', 'caseTypes'], state);
const getBulkActionReasonCodes = state => R.pathOr({}, ['admintools', 'bulkActionReasonCodes'], state);
const getLoader = state => R.pathOr(false, ['admintools', 'loader'], state);
const getBusinessAdminGridData = state => R.pathOr({}, ['admintools', 'businessAdminGridData'], state);
const getBaSelectedTab = state => R.pathOr('', ['admintools', 'baSelectedTab'], state);
const getUsers = state => R.pathOr([], ['admintools', 'createdBy'], state);
const getModSupportResponseData = state => R.pathOr({}, ['admintools', 'modSupportResponse'], state);
const getModInvestors = state => R.pathOr([], ['admintools', 'modInvestors'], state);
const getInvestorName = state => R.pathOr('', ['admintools', 'investorName'], state);

const selectors = {
  getSBHistoryGridData,
  getLoader,
  getEvalStatusOptions,
  getEvalSubstatusOptions,
  getCaseStatusOptions,
  getCaseSubstatusOptions,
  getChangeStatusResponseData,
  getChangeBulkStatusResponseData,
  getServiceTickets,
  getRequestorIds,
  getSAselectedTab,
  isFilterEnabled,
  filterPayload,
  getIdType,
  getRecordsInfo,
  getCaseTypes,
  getBulkActionReasonCodes,
  getBusinessAdminGridData,
  getBaSelectedTab,
  getUsers,
  getModSupportResponseData,
  getModInvestors,
  getInvestorName,
};

export default selectors;
