
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Select,
  MenuItem,
  Button,
  TextField,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './EditContributor.css';
import { operations as docChecklistOperations } from 'ducks/document-checklist';
import { operations as incomeCalcChecklistOperations } from 'ducks/income-calculator';
import moment from 'moment-timezone';
import { FORMAT } from '../../../lib/Formatters';
import { CONTRIBUTOR_AFFL_CODE, ASSUMPTOR_AFFL_CODE } from '../../../constants/incomeCalc/DocumentList';
import ConfirmationPopup from '../../Eval/ConfirmationPopup/ConfirmationPopup';

function EditContributor(props) {
  const {
    onClose, contributorData, docChklistEditContributor, checklistType, editContributor,
    deleteContributor, docChklistDeleteContributor,
  } = props;
  const {
    firstName: firstNm, lastName: lastNm, taxpyrIdVal, birthDate,
    phoneNumber, borrowerAffilCd, emailAddress,
  } = contributorData;
  const [firstName, setFirstName] = useState(firstNm);
  const [lastName, setLastName] = useState(lastNm);
  const [ssn, setSsn] = useState(taxpyrIdVal || '');
  const [dateOfBirth, setDateOfBirth] = useState(birthDate
    ? moment(birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : '');
  const [email, setEmail] = useState(emailAddress || null);
  const [phone, setPhone] = useState(phoneNumber || '');
  const [typeOfUser, setTypeOfUser] = useState(borrowerAffilCd);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [showConfPopup, setShowConfPopup] = useState(false);

  const isRequiredFieldsFilled = () => firstName !== '' && lastName !== '' && typeOfUser !== '';

  const handleUpdate = () => {
    if (isRequiredFieldsFilled) {
      const isDobNil = dateOfBirth === '';
      const dob = dateOfBirth.split('-');
      const contributorFields = {
        firstName,
        lastName,
        emailAddress: email !== '' ? email : null,
        phoneNumber: phone !== '' ? phone : null,
        birthDate: !isDobNil ? `${dob[1]}-${dob[2]}-${dob[0]}` : '',
        borrowerAffilCd: typeOfUser,
        taxpyrIdVal: ssn !== '' ? ssn : null,
        borrowerId: contributorData.borrowerId,
        borrowerPstnNumber: contributorData.pstnNum,
        oldContr: `${contributorData.firstName}_${contributorData.pstnNum}`,
        description: contributorData.description,
      };
      if (checklistType === 'Fico') {
        // FEUW
        editContributor(contributorFields);
      } else {
        // PROC or Indexer
        docChklistEditContributor(contributorFields);
      }
      onClose();
    }
  };


  const disableDates = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);
    const minAgeDate = new Date();
    minAgeDate.setFullYear(currentDate.getFullYear());
    return selectedDate > minAgeDate;
  };

  const deleteFn = () => {
    setShowConfPopup(false);
    contributorData.borrowerPstnNumber = contributorData.pstnNum;
    if (checklistType === 'Fico') {
      deleteContributor(contributorData);
    } else {
      docChklistDeleteContributor(contributorData);
    }
    onClose();
  };

  const getRole = (data) => {
    let role = '';
    role = data.description && data.description.toLowerCase().startsWith('contrib') ? 'Contributor'
      : 'Assumptor';
    return role;
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open
        PaperProps={{
          style: {
            borderRadius: '10px',
            border: '1px solid #4E586E',
            maxWidth: '700px',
          },
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item styleName="edit-contrib-pop-grid-title" xs={20}>
              <Typography
                styleName="edit-contrib-pop-grid-title-name"
              >
                {`Edit ${getRole(contributorData)}`}
              </Typography>
            </Grid>
            <Grid item styleName="edit-contrib-pop-grid-close-icon">
              <IconButton onClick={onClose} style={{ height: '30px', width: '30px' }}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ overflow: 'hidden' }}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}>
                  <div>
                 First Name
                    {' '}
                    <span styleName="required">*</span>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-required" onChange={e => setFirstName(e.target.value)} size="small" value={firstName} variant="outlined" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}>
                  <div>
                  Last Name
                    {' '}
                    <span styleName="required">*</span>
                  </div>

                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-required" onChange={e => setLastName(e.target.value)} size="small" value={lastName} variant="outlined" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}>
                  <div>
                  SSN
                    {' '}
                  </div>

                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-required" onChange={e => setSsn(e.target.value)} size="small" value={FORMAT.ssn(ssn.toString())} variant="outlined" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}><div>DOB</div></Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-required"
                    inputProps={{
                      max: new Date().toISOString().split('T')[0],
                      disabled: disableDates(dateOfBirth),
                    }}
                    onBlur={() => setIsDatePickerOpen(true)}
                    onChange={e => setDateOfBirth(e.target.value)}
                    onFocus={() => setIsDatePickerOpen(false)}
                    open={isDatePickerOpen}
                    size="small"
                    style={{ width: '14.9rem' }}
                    type="date"
                    value={dateOfBirth}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}><div>Email</div></Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-required" onChange={e => setEmail(e.target.value)} size="small" value={email} variant="outlined" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}><div>Phone</div></Grid>
                <Grid item xs={3}>
                  <FormControl>
                    <TextField id="outlined-required" onChange={e => setPhone(e.target.value)} required size="small" value={FORMAT.phone(phone.toString())} variant="outlined" />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item styleName="edit-contrib-label" xs={4}>
                  <div>
                  Type Of User
                    {' '}
                    <span styleName="required">*</span>
                  </div>

                </Grid>
                <Grid item xs={3}>
                  <Select
                    onChange={e => setTypeOfUser(e.target.value)}
                    required
                    size="small"
                    styleName="edit-contrib-select"
                    value={typeOfUser}
                    variant="outlined"
                  >
                    <MenuItem styleName="user-type-menu-item" value={CONTRIBUTOR_AFFL_CODE}>Contributor</MenuItem>
                    <MenuItem styleName="user-type-menu-item" value={ASSUMPTOR_AFFL_CODE}>Assumptor</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={!isRequiredFieldsFilled()} onClick={handleUpdate} styleName="edit">Update</Button>
          <Button onClick={() => setShowConfPopup(true)} styleName="cancel">Delete</Button>
        </DialogActions>
      </Dialog>

      <ConfirmationPopup
        cancelButtonText="NO"
        confirmButtonText="YES"
        message={`Are you sure you want to delete the ${getRole(contributorData)} ?`}
        onCancel={() => setShowConfPopup(false)}
        onConfirm={deleteFn}
        show={showConfPopup}
        showCancelButton
        showConfirmButton
      />
    </>
  );
}

EditContributor.defaultProps = {
  checklistType: '',
};

EditContributor.propTypes = {
  checklistType: PropTypes.string,
  contributorData: PropTypes.shape().isRequired,
  deleteContributor: PropTypes.func.isRequired,
  docChklistDeleteContributor: PropTypes.func.isRequired,
  docChklistEditContributor: PropTypes.func.isRequired,
  editContributor: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  docChklistEditContributor: docChecklistOperations.editContributorOperation(dispatch),
  editContributor: incomeCalcChecklistOperations.editContributorOperation(dispatch),
  docChklistDeleteContributor: docChecklistOperations.deleteContributorOperation(dispatch),
  deleteContributor: incomeCalcChecklistOperations.deleteContributorOperation(dispatch),
});

export default connect(null, mapDispatchToProps)(EditContributor);
