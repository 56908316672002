import React, { useState } from 'react';
import {
  Typography, Grid, IconButton, MenuItem, Select, FormControl, Button, TextField,
} from '@material-ui/core/index';
import { PropTypes } from 'prop-types';
import SweetAlertBox from 'components/SweetAlertBox';
import * as R from 'ramda';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import './AddPriorWorkout.css';
import {
  operations as smduOperations,

} from 'ducks/smdu';
import { connect } from 'react-redux';
import WidgetLoader from 'components/Widgets/WidgetLoader';

const defaultFormData = {
  workoutTypes: '',
  statusTypes: '',
  statusDate: '',
  failCancelReasons: '',
  paymentReduction: '',
  subsequentDelinquencySeverities: '',
  activityTypes: '',
};

const AddPriorWorkout = (props) => {
  const [priorData, setPriorData] = useState(defaultFormData);
  const {
    handleBackPriorWorkout, SMDUPriorWorkoutDropdownOptions, savePriorWorkout, getPopUpMessage,
    isPopUpEnabled, popUpType, loading,
  } = props;

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...priorData, [name]: value };
    setPriorData(updatedObject);
  };

  const handleClose = () => {
    const { closePopUp } = props;
    closePopUp();
  };

  let renderComponent = null;
  renderComponent = (
    <SweetAlertBox
      message={getPopUpMessage}
      onConfirm={() => handleClose()}
      show={isPopUpEnabled}
      type={popUpType}
    />
  );

  const renderStatusOptions = (option) => {
    if (!R.isEmpty(SMDUPriorWorkoutDropdownOptions) && !R.has('status', SMDUPriorWorkoutDropdownOptions)) {
      let severityData = [];

      if (option === 'severity') {
        severityData = R.filter(val => !R.isEmpty(val), R.split('.', SMDUPriorWorkoutDropdownOptions.subsequentDelinquencySeverities[0].value)).map(n => Number(n));
        // eslint-disable-next-line max-len
        return R.unfold(n => n <= severityData[1] && [n, n + 1], severityData[0]).map(ele => <MenuItem key={ele} value={ele}>{ele}</MenuItem>);
      }

      const optionData = SMDUPriorWorkoutDropdownOptions[option];
      if (optionData && typeof optionData === 'object') {
        const optionArray = Object.entries(optionData);
        // eslint-disable-next-line max-len
        return optionArray.map(([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>);
      }
    }

    return null;
  };

  return (
    <>
      <div styleName="main-container">
        <section>
          <Grid container>
            <Grid item xs={12}>
              <IconButton aria-label="back" onClick={() => handleBackPriorWorkout(false)}>
                <KeyboardBackspaceIcon fontSize="inherit" />
              </IconButton>
              <Typography styleName="title" variant="h2">Prior Workout</Typography>
            </Grid>
            {loading ? <WidgetLoader /> : (
              <Grid item xs={12}>
                <div styleName="grey-bg form-container">
                  <FormControl styleName="select-container">
                    <p>Workout Type</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="workoutTypes"
                      onChange={handleChange}
                      value={priorData.workoutTypes}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('workoutTypes')}
                    </Select>
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Status Type</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="statusTypes"
                      onChange={handleChange}
                      value={priorData.statusTypes}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('statusTypes')}
                    </Select>
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Status Date</p>
                    <TextField
                      hiddenLabel
                      name="statusDate"
                      onChange={handleChange}
                      type="date"
                      value={priorData.statusDate}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Fail/Cancel Reason</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="failCancelReasons"
                      onChange={handleChange}
                      value={priorData.failCancelReasons}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('failCancelReasons')}
                    </Select>
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Payment Reduction</p>
                    <TextField
                      hiddenLabel
                      name="paymentReduction"
                      onChange={handleChange}
                      value={priorData.paymentReduction}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl styleName="select-container">
                    <p>Severity</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="subsequentDelinquencySeverities"
                      onChange={handleChange}
                      value={priorData.subsequentDelinquencySeverities}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('subsequentDelinquencySeverities')}
                    </Select>
                  </FormControl>

                  <FormControl styleName="select-container">
                    <p>Activity Type</p>
                    <Select
                      displayEmpty
                      label="Select"
                      name="activityTypes"
                      onChange={handleChange}
                      value={priorData.activityTypes}
                    >
                      <MenuItem value="">Select</MenuItem>
                      {renderStatusOptions('activityTypes')}
                    </Select>
                  </FormControl>
                  <Button
                    color="primary"
                    onClick={() => savePriorWorkout(priorData)}
                    variant="contained"
                  >
                  Add to Prior Workout
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </section>
      </div>
      {renderComponent}
    </>
  );
};


AddPriorWorkout.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  getPopUpMessage: PropTypes.string.isRequired,
  handleBackPriorWorkout: PropTypes.func.isRequired,
  isPopUpEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  popUpType: PropTypes.string.isRequired,
  savePriorWorkout: PropTypes.func.isRequired,
  SMDUPriorWorkoutDropdownOptions: PropTypes.shape({
    activityTypes: PropTypes.object.isRequired,
    failCancelReasons: PropTypes.object.isRequired,
    statusTypes: PropTypes.object.isRequired,
    subsequentDelinquencySeverities: PropTypes.object.isRequired,
    workoutTypes: PropTypes.object.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  savePriorWorkout: smduOperations.savePriorWorkout(dispatch),
  closePopUp: smduOperations.closePopUpOperation(dispatch),
});

const TestHooks = {
  AddPriorWorkout,
};
export default connect(null, mapDispatchToProps)(AddPriorWorkout);
export { TestHooks };
