import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkoutType from './WorkoutType';
import styles from './History.css';

const History = ({ data, transactionData }) => {
  const {
    date,
    messageDate,
    operationType,
    requestType,
    validationData,
    workoutDetails,
    user,
    type,
  } = data;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => setIsExpanded(!isExpanded);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
    }
  };

  const handleDownloadClick = (event) => {
    event.stopPropagation();

    const transaction = transactionData.find(
      t => t.requestReceiptTimeStamp === messageDate && t.operationType === operationType,
    );

    if (transaction) {
      const { requestXml, responseXml } = transaction;

      if (typeof window !== 'undefined' && window.Blob) {
        const blob = new window.Blob(
          [`Request XML:\n${requestXml}\n\nResponse XML:\n${responseXml}`],
          { type: 'application/xml' },
        );
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Transaction_${requestType}_${date}.xml`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        console.error('Blob is not supported in this environment');
      }
    }
  };

  const handleDownloadKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDownloadClick(event);
    }
  };

  return (
    <>
      <div
        aria-expanded={isExpanded}
        className={styles.accordion}
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        role="button"
        style={{ height: '4rem' }}
        tabIndex={0}
      >
        <div className={styles.accordion_content}>
          <div className={styles.accordion_title}>
            <strong>{requestType}</strong>
            <div className={styles.type_box}>
              <span>{type}</span>
            </div>
          </div>

          <div className={styles.info_section}>
            <div className={styles.contact_info}>
              <img alt="Contact" className={styles.icon} src="/static/img/user.png" />
              <span>{user}</span>
            </div>
            <div className={styles.calendar_info}>
              <img alt="Calendar" className={styles.icon} src="/static/img/calendar.png" />
              <span>{date}</span>
            </div>
            <button
              className={styles.download_info}
              onClick={handleDownloadClick}
              onKeyDown={handleDownloadKeyDown}
              style={{ background: 'none', border: 'none', padding: 0 }}
              type="button"
            >
              <img alt="Download" className={styles.icon} src="/static/img/download.svg" />
            </button>
          </div>

          <div className={styles.validation_rounds}>
            {validationData.map(({ label, status }) => (
              <div key={label} className={styles.validation_round}>
                <div className={`${styles.round} ${styles[status]}`} />
                <span>{label}</span>
              </div>
            ))}
          </div>

          <div className={styles.accordion_arrow}>
            <img
              alt="toggle"
              height="7"
              src="/static/img/downArrow.svg"
              style={{ width: '14px', marginLeft: '5px' }}
            />
          </div>
        </div>
      </div>

      {isExpanded && workoutDetails.map(({ WorkoutTypeCode, categories }) => (
        <WorkoutType
          key={WorkoutTypeCode}
          categories={categories}
          validationData={validationData}
          workoutTypeCode={WorkoutTypeCode}
        />
      ))}
    </>
  );
};

History.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    messageDate: PropTypes.string.isRequired,
    operationType: PropTypes.string.isRequired,
    requestType: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    validationData: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        status: PropTypes.oneOf(['green', 'red']).isRequired,
      }),
    ).isRequired,
    workoutDetails: PropTypes.arrayOf(
      PropTypes.shape({
        categories: PropTypes.objectOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              Message: PropTypes.string.isRequired,
              MessageID: PropTypes.string.isRequired,
            }),
          ),
        ).isRequired,
        WorkoutTypeCode: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  transactionData: PropTypes.arrayOf(
    PropTypes.shape({
      operationType: PropTypes.string.isRequired,
      requestReceiptTimeStamp: PropTypes.string.isRequired,
      requestXml: PropTypes.string.isRequired,
      responseXml: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default History;
