
import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { FORMAT } from 'lib/Formatters';
import './RepaymentTableSummary.css';
import { NON_CURRENCY_ONLY_REGEX } from 'constants/eval';

function RepaymentTableSummary({
  row, handleMonthlyRepaymentValueChange,
  isPlanExtended, isEditable, status, disasterFlag,
}) {
  const [monthlyRepaymentValue,
    setMonthlyRepaymentValue] = React.useState(row.monthlyRepaymentAmount);
  const [isDisabled, setIsDisabled] = React.useState(true);

  const caseSts = status !== 'Open';
  const isDisasterFlagMatch = ['E', 'B', 'PE', 'PB'].includes(disasterFlag);
  const isRepaymentAmtEditable = caseSts && isDisasterFlagMatch;

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {row.description}
      </TableCell>
      <TableCell align="right">{row.date}</TableCell>
      <TableCell align="right">
        <OutlinedInput
          disabled
          id="outlined-contractualPortion-textBox"
          startAdornment={(
            <InputAdornment position="start">
              $
            </InputAdornment>
          )}
          styleName="disabled-textBox"
          type="text"
          value={FORMAT.currency(row.contractualPortion.toString())}
        />
      </TableCell>
      <TableCell align="right">
        <OutlinedInput
          disabled
          id="outlined-delinqunecyPortion-textBox"
          startAdornment={(
            <InputAdornment position="start">
              $
            </InputAdornment>
          )}
          styleName="disabled-textBox"
          type="text"
          value={FORMAT.currency(row.delinqunecyPortion.toString())}
        />
      </TableCell>
      <TableCell align="right">
        <OutlinedInput
          disabled={isDisabled}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton edge="end" style={{ padding: '0' }}>
                {isEditable && isRepaymentAmtEditable && (
                <EditIcon onClick={() => { setIsDisabled(false); }} styleName="editIcon" />
                )}
              </IconButton>
            </InputAdornment>
          )}
          id="outlined-monthlyRepaymentAmount-textBox"
          onBlur={() => {
            handleMonthlyRepaymentValueChange(monthlyRepaymentValue);
            setIsDisabled(true);
          }}
          onChange={(e) => {
            if (
              !NON_CURRENCY_ONLY_REGEX.test(
                e.target.value,
              )
            ) {
              setMonthlyRepaymentValue(e.target.value);
            }
          }
        }
          startAdornment={(
            <InputAdornment position="start">
              $
            </InputAdornment>
          )}
          styleName={isDisabled ? 'monthly-payment-disabled-textBox' : 'enabled-textBox'}
          type="text"
          value={FORMAT.currency(monthlyRepaymentValue.toString())}
        />
      </TableCell>
      {
        isPlanExtended && (
          <TableCell align="right">{row.extendedDuration}</TableCell>
        )
      }
    </TableRow>
  );
}

RepaymentTableSummary.propTypes = {
  disasterFlag: PropTypes.string.isRequired,
  handleMonthlyRepaymentValueChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPlanExtended: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    changeType: PropTypes.string.isRequired,
    contractualPortion: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    delinqunecyPortion: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    extendedDuration: PropTypes.string.isRequired,
    monthlyRepaymentAmount: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.string.isRequired,
};


export default RepaymentTableSummary;
