import React, { useEffect } from 'react';
import {
  Box, Grid, Typography,
} from '@material-ui/core';
import './NpvDetails.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import moment from 'moment';
import { operations, selectors } from '../../../../state/ducks/eval';

const NpvDtl = ({
  selectedCaseId, inProgress, fetchNpvDetails, npvData,
}) => {
  useEffect(() => {
    fetchNpvDetails(selectedCaseId);
  }, []);

  // const REOFrclsPath = [
  //   {
  //     id: 0,
  //     title: 'Liquidation Date',
  //     value: '1/1/2024',
  //     type: 'date',
  //   },
  //   {
  //     id: 1,
  //     title: 'REO Value',
  //     value: '240',
  //     type: 'currency',
  //   },
  //   {
  //     id: 2,
  //     title: 'REO Stigma Discount',
  //     value: '9.08',
  //     type: 'percentage',
  //   },
  //   {
  //     id: 3,
  //     title: 'PV Probability Wtd Loss',
  //     value: '350',
  //     type: 'currency',
  //   },
  // ];
  // const ModificationPath = [
  //   {
  //     id: 0,
  //     title: 'Mod Redef Liquidation Date',
  //     value: '1/2/2024',
  //     type: 'date',
  //   },
  //   {
  //     id: 1,
  //     title: 'PV Estimated Loss',
  //     value: '240',
  //     type: 'currency',
  //   },
  //   {
  //     id: 2,
  //     title: 'Benefit From Modification',
  //     value: '9.08',
  //     type: 'currency',
  //   },
  //   {
  //     id: 3,
  //     title: 'Mod REO Value',
  //     value: '24',
  //     type: 'currency',
  //   },
  //   {
  //     id: 4,
  //     title: 'Modification Value',
  //     value: '4179087',
  //     type: 'currency',
  //   },
  // ];

  // function renderNpvItem(item, width) {
  //   const { title, value, type } = item;
  //   let content = value;
  //   if (type === 'currency') {
  //     content = parseFloat((content * 100).toFixed(10));
  //     content = `$${(Math.round(content) / 100).toFixed(2).toString()
  //       .replace(/(\d)(?=(\d{3})+(\.\d*)?$)/g, '$1,')}`;
  //   } else if (type === 'percentage') {
  //     content = `${value}%`;
  //   }
  //   return (
  //     <Grid item md={width} sm={12} styleName="item" xs={12}>
  //       <span styleName="title">{title}</span>
  //       <span styleName="content">
  //         {
  //           value === '' ? '-' : content
  //         }
  //       </span>
  //     </Grid>
  //   );
  // }

  return (
    <>
      { inProgress && (
      <Loader size={40} />
      )}
      { !inProgress && (
        <Box styleName="npvDetails" sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} styleName="npvContainer">
            <Grid item md={4} sm={12} styleName="caseForms" xs={12}>
              <Typography styleName="header-font">REO Foreclosure Path</Typography>
              <Grid container direction="row" spacing={4} styleName="itemContainer">
                {/* {REOFrclsPath.map(item => (renderNpvItem(item, 4)))} */}

                <Grid item md={4} sm={12} styleName="item" xs={12}>
                  <span styleName="title">Liquidation Date</span>
                  <span styleName="content">
                    {npvData.liquidationDate && npvData.liquidationDate.includes('T')
                      ? moment(npvData.liquidationDate.split('T')[0]).format('DD/MM/YYYY') : '-' }
                  </span>
                </Grid>
                <Grid item md={4} sm={12} styleName="item" xs={12}>
                  <span styleName="title">REO Value</span>
                  <span styleName="content">
                    {npvData.reoValueAmount ? `$${npvData.reoValueAmount}` : '-' }
                  </span>
                </Grid>
                <Grid item md={4} sm={12} styleName="item" xs={12}>
                  <span styleName="title">REO Stigma Discount</span>
                  <span styleName="content">
                    {npvData.reoStigmaDiscount ? `${npvData.reoStigmaDiscount}%` : '-' }
                  </span>
                </Grid>
                <Grid item md={4} sm={12} styleName="item" xs={12}>
                  <span styleName="title">PV Probability Wtd Loss</span>
                  <span styleName="content">
                    {npvData.pvProbabilityWtdLoss ? `$${npvData.pvProbabilityWtdLoss}` : '-' }
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={7} sm={12} styleName="caseForms" xs={12}>
              <Typography styleName="header-font">Modification Path</Typography>
              <Grid container direction="row" spacing={4} styleName="itemContainer">
                {/* {ModificationPath.map(item => (renderNpvItem(item, 3)))} */}
                <Grid item md={3} sm={12} styleName="item" xs={12}>
                  <span styleName="title">Mod Redef Liquidation Date</span>
                  <span styleName="content">
                    {npvData.modRedefLiquidationDate && npvData.modRedefLiquidationDate.includes('T')
                      ? moment(npvData.modRedefLiquidationDate.split('T')[0]).format('DD/MM/YYYY') : '-' }
                  </span>
                </Grid>
                <Grid item md={3} sm={12} styleName="item" xs={12}>
                  <span styleName="title">PV Estimated Loss</span>
                  <span styleName="content">
                    {npvData.pvEstimatedLossAmt ? `$${npvData.pvEstimatedLossAmt}` : '-' }
                  </span>
                </Grid>
                <Grid item md={3} sm={12} styleName="item" xs={12}>
                  <span styleName="title">Benefit From Modification</span>
                  <span styleName="content">
                    {npvData.benefitFromModificationAmt ? `$${npvData.benefitFromModificationAmt}` : '-' }
                  </span>
                </Grid>
                <Grid item md={3} sm={12} styleName="item" xs={12}>
                  <span styleName="title">Mod REO Value</span>
                  <span styleName="content">
                    {npvData.modReoValueAmount ? `$${npvData.modReoValueAmount}` : '-' }
                  </span>
                </Grid>
                <Grid item md={3} sm={12} styleName="item" xs={12}>
                  <span styleName="title">Modification Value</span>
                  <span styleName="content">
                    {npvData.modificationValue ? `$${npvData.modificationValue}` : '-' }
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

NpvDtl.defaultProps = {
  npvData: {},
  fetchNpvDetails: () => {},
};

NpvDtl.propTypes = {
  fetchNpvDetails: PropTypes.func,
  inProgress: PropTypes.bool.isRequired,
  npvData: PropTypes.shape({
    benefitFromModificationAmt: PropTypes.number,
    liquidationDate: PropTypes.string,
    modificationValue: PropTypes.number,
    modRedefLiquidationDate: PropTypes.string,
    modReoValueAmount: PropTypes.number,
    pvEstimatedLossAmt: PropTypes.number,
    pvProbabilityWtdLoss: PropTypes.number,
    reoStigmaDiscount: PropTypes.number,
    reoValueAmount: PropTypes.number,
  }),
  resultOperation: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    saga: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  selectedCaseId: PropTypes.number.isRequired,

};


const mapStateToProps = state => ({
  selectedCaseId: selectors.getSelectedCaseId(state),
  inProgress: selectors.inProgress(state),
  npvData: selectors.caseNpvData(state),
});

const mapDispatchToProps = dispatch => ({
  fetchNpvDetails: operations.fetchCaseNpvDetailsOperation(dispatch),
});

const NpvDetails = connect(mapStateToProps, mapDispatchToProps)(NpvDtl);

export default NpvDetails;
