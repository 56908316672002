const FETCH_GRID_DATA = 'app/admintools/FETCH_GRID_DATA';
const SET_SB_HISTORY_GRID_DATA = 'app/admintools/SET_SB_HISTORY_GRID_DATA';
const SET_AUTO_COMPLETE_DATA = 'app/admintools/SET_AUTO_COMPLETE_DATA';
const SET_SA_SELECTED_TAB = 'app/admintools/SET_SA_SELECTED_TAB';
const FILTER_RESULTS = 'app/admintools/FILTER_RESULTS';
const IS_FILTER_ENABLED = 'app/admintools/IS_FILTER_ENABLED';
const FILTER_CRITERIA = 'app/admintools/FILTER_CRITERIA';
const UNREJECT_REQUEST = 'app/admintools/UNREJECT_REQUEST';
const SET_SUBSTATUS_REQUEST_OPTIONS = 'app/admintools/SET_SUBSTATUS_REQUEST_OPTIONS';
const FETCH_SUBSTATUS_REQUEST_OPTIONS = 'app/admintools/FETCH_SUBSTATUS_REQUEST_OPTIONS';
const SUBMIT_STATUS_OPTIONS = 'app/admintools/SUBMIT_STATUS_OPTIONS';
const SUBMIT_BULK_STATUS_OPTIONS = 'app/admintools/SUBMIT_BULK_STATUS_OPTIONS';
const SET_ID_TYPE = 'app/admintools/SET_ID_TYPE';
const SET_RECORDS_INFO = 'app/admintools/SET_RECORDS_INFO';
const EVAL_DISASTER_ID_REQUEST_SAGA = 'app/admintools/EVAL_DISASTER_ID_REQUEST_SAGA';
const GATHER_AUTO_COMPLETE_MATCH = 'app/admintools/GATHER_AUTO_COMPLETE_MATCH';
const FETCH_CASE_TYPE = 'app/admintools/FETCH_CASE_TYPE';
const SET_CASE_TYPES = 'app/admintools/SET_CASE_TYPES';
const SUBMIT_FORCE_CREATE_CASE = 'app/admintools/SUBMIT_FORCE_CREATE_CASE';
const SUBMIT_FORCE_CREATE_EVAL_CASE = 'app/admintools/SUBMIT_FORCE_CREATE_EVAL_CASE';
const FETCH_BULK_ACTION_RSN_CDS = 'app/admintools/FETCH_BULK_ACTION_RSN_CDS';
const SET_BULK_ACTION_RSN_CDS = 'app/admintools/SET_BULK_ACTION_RSN_CDS';
const EXECUTE_ACTION = 'app/admintools/EXECUTE_ACTION';
const TOGGLE_LOADER = 'app/admintools/TOGGLE_LOADER';
const FETCH_BUSINESS_ADMIN_GRID_DATA = 'app/admintools/FETCH_BUSINESS_ADMIN_GRID_DATA';
const SET_BUSINESS_ADMIN_GRID_DATA = 'app/admintools/SET_BUSINESS_ADMIN_GRID_DATA';
const SET_BA_SELECTED_TAB = 'app/admintools/SET_BA_SELECTED_TAB';
const FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY = 'app/admintools/FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY';
const FILTER_BA_RESULTS = 'app/admintools/FILTER_BA_RESULTS';
const GATHER_AUTO_COMPLETE_BA_MATCH = 'app/admintools/GATHER_AUTO_COMPLETE_BA_MATCH';
const SET_AUTO_COMPLETE_BA_DATA = 'app/admintools/SET_AUTO_COMPLETE_BA_DATA';
const SUBMIT_MOD_SUPPORT_REQUEST = 'app/admintools/SUBMIT_MOD_SUPPORT_REQUEST';
const SET_MOD_SUPPORT_RESPONSE = 'app/admintools/SET_MOD_SUPPORT_RESPONSE';
const SUBMIT_UPLOAD_SHEET_REQUEST = 'app/admintools/SUBMIT_UPLOAD_SHEET_REQUEST';
const FETCH_MOD_INVESTORS = 'app/admintools/FETCH_MOD_INVESTORS';
const SET_MOD_INVESTORS = 'app/admintools/SET_MOD_INVESTORS';
const SET_INVESTOR_NAME = 'app/admintools/SET_INVESTOR_NAME';
const SET_CASE_STATUS = 'app/admintools/SET_CASE_STATUS';
const SET_CASE_SUBSTATUS = 'app/admintools/SET_CASE_SUBSTATUS';
const SET_EVAL_STATUS = 'app/admintools/SET_EVAL_STATUS';
const SET_EVAL_SUBSTATUS = 'app/admintools/SET_EVAL_SUBSTATUS';

export {
  SET_MOD_INVESTORS,
  FETCH_MOD_INVESTORS,
  SET_CASE_TYPES,
  SUBMIT_FORCE_CREATE_EVAL_CASE,
  SUBMIT_FORCE_CREATE_CASE,
  GATHER_AUTO_COMPLETE_MATCH,
  EVAL_DISASTER_ID_REQUEST_SAGA,
  SET_INVESTOR_NAME,
  SET_RECORDS_INFO,
  TOGGLE_LOADER,
  FETCH_GRID_DATA,
  SET_SB_HISTORY_GRID_DATA,
  SET_AUTO_COMPLETE_DATA,
  SET_SA_SELECTED_TAB,
  FILTER_RESULTS,
  IS_FILTER_ENABLED,
  FILTER_CRITERIA,
  UNREJECT_REQUEST,
  SET_SUBSTATUS_REQUEST_OPTIONS,
  FETCH_SUBSTATUS_REQUEST_OPTIONS,
  SUBMIT_STATUS_OPTIONS,
  SUBMIT_BULK_STATUS_OPTIONS,
  SET_ID_TYPE,
  FETCH_CASE_TYPE,
  FETCH_BULK_ACTION_RSN_CDS,
  SET_BULK_ACTION_RSN_CDS,
  EXECUTE_ACTION,
  FETCH_BUSINESS_ADMIN_GRID_DATA,
  SET_BUSINESS_ADMIN_GRID_DATA,
  SET_BA_SELECTED_TAB,
  FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY,
  FILTER_BA_RESULTS,
  GATHER_AUTO_COMPLETE_BA_MATCH,
  SET_AUTO_COMPLETE_BA_DATA,
  SUBMIT_MOD_SUPPORT_REQUEST,
  SET_MOD_SUPPORT_RESPONSE,
  SUBMIT_UPLOAD_SHEET_REQUEST,
  SET_CASE_STATUS,
  SET_CASE_SUBSTATUS,
  SET_EVAL_STATUS,
  SET_EVAL_SUBSTATUS,
};
