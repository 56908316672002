
import {
  gatherEscrowData,
  setLoanEval,
  onChange,
  handleConfirmDialogBoxActions,
  storeAccountServicesHistory,
  getCapModHistory,
  toggleHistoryViewAction,
  toggleAnalysisScreenAction,
  closeDialogAction,
  completeEscrowAction,
  setEventTypeAction,
  toggleAccountServiceAction,
  fetchTombstoneDataAction,
  storeCapModIdAction,
  storeLoanNumberAction,
  updateNetDisbursement,
  resetValueChangeAction,
  isRejectBtnClickedAction,
  clearInfo,
  clearHistoryInfo,
  showLoaderAction,
  showLoaderInPopupAction,
  clearPromptErrorAction,
  setRequestSubmittedAction,
  storeAnalysisBreakdownDataAction,
} from './actions';

const gatherEscrowDataOperation = dispatch => (eventType) => {
  dispatch(gatherEscrowData(eventType));
};

const setLoanInfoFromStager = dispatch => (loanNumber, evalId, taskId) => {
  dispatch(setLoanEval(loanNumber, evalId, taskId));
};

const onValueChange = dispatch => (id, value) => {
  dispatch(onChange(id, value));
};

const handleConfirmDialogBoxButtons = dispatch => (value) => {
  dispatch(handleConfirmDialogBoxActions(value));
};

const storeAccountServicesHistoryOperation = dispatch => () => {
  dispatch(storeAccountServicesHistory());
};

const getCapModHistoryOperation = dispatch => (capModId) => {
  dispatch(getCapModHistory(capModId));
};

const toggleHistoryView = dispatch => (value) => {
  dispatch(toggleHistoryViewAction(value));
};

const toggleAnalysisScreen = dispatch => () => {
  dispatch(toggleAnalysisScreenAction());
};

const closedialog = dispatch => () => {
  dispatch(closeDialogAction());
};

const completeEscrow = dispatch => () => {
  dispatch(completeEscrowAction());
};

const setEventType = dispatch => (value) => {
  dispatch(setEventTypeAction(value));
};

const toggleAccountServiceScreen = dispatch => (value) => {
  dispatch(toggleAccountServiceAction(value));
};

const fetchTombstoneData = dispatch => (payload) => {
  dispatch(fetchTombstoneDataAction(payload));
};

const storeCapModId = dispatch => (value) => {
  dispatch(storeCapModIdAction(value));
};

const storeLoanNumber = dispatch => (value) => {
  dispatch(storeLoanNumberAction(value));
};

const updateNetDisbursementOperation = dispatch => () => {
  dispatch(updateNetDisbursement());
};

const resetValueChange = dispatch => () => {
  dispatch(resetValueChangeAction());
};

const isRejectBtnClickedOperation = dispatch => (payload) => {
  dispatch(isRejectBtnClickedAction(payload));
};

const clearInfoOperation = dispatch => () => dispatch(clearInfo());

const clearHistoryInfoOperation = dispatch => () => dispatch(clearHistoryInfo());

const showLoaderOperation = dispatch => (value) => {
  dispatch(showLoaderAction(value));
};

const showLoaderInPopupOperation = dispatch => (value) => {
  dispatch(showLoaderInPopupAction(value));
};

const clearPromptError = dispatch => () => {
  dispatch(clearPromptErrorAction());
  dispatch(setRequestSubmittedAction(false));
};

const storeAnalysisBreakdownDataOperation = dispatch => (payload) => {
  dispatch(storeAnalysisBreakdownDataAction(payload));
};

const operations = {
  clearPromptError,
  resetValueChange,
  updateNetDisbursementOperation,
  storeCapModId,
  storeLoanNumber,
  fetchTombstoneData,
  toggleAccountServiceScreen,
  completeEscrow,
  closedialog,
  handleConfirmDialogBoxButtons,
  gatherEscrowDataOperation,
  setLoanInfoFromStager,
  onValueChange,
  storeAccountServicesHistoryOperation,
  getCapModHistoryOperation,
  toggleHistoryView,
  toggleAnalysisScreen,
  setEventType,
  isRejectBtnClickedOperation,
  clearInfoOperation,
  clearHistoryInfoOperation,
  showLoaderOperation,
  showLoaderInPopupOperation,
  storeAnalysisBreakdownDataOperation,
};

export default operations;
