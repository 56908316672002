import {
  UPDATE_REPAY_TRACKPAYMENT_DATES,
  FETCH_MONTHLY_PAYMENT_DETAILS, UPDATE_MONTHLY_PAYMENT_DETAILS,
  RULE_CHECK_SAGA, LOCK_CALC, ENABLE_CALC, FETCH_PAYMENT_CHANGES_DETAILS, SHOW_LOADER, HIDE_LOADER,
  SEND_FORAPPROVAL_RULES,
  FETCH_REJECT_REASONS, SET_REJECT_REASONS,
  REJECT_CASE_RULES, SEND_FORAPPROVAL,
  CALCULATE_REPAYMENT_DATA,
} from './types';


const updateRepayTrackPaymentDatesAction = payload => ({
  type: UPDATE_REPAY_TRACKPAYMENT_DATES,
  payload,
});

const fetchMonthlyPaymentDetailsAction = () => ({
  type: FETCH_MONTHLY_PAYMENT_DETAILS,
});

const updateMonthlyPaymentDetailsAction = (key, value) => ({
  type: UPDATE_MONTHLY_PAYMENT_DETAILS,
  payload: { key, value },
});

const ruleCheckAction = () => ({
  type: RULE_CHECK_SAGA,
});

const lockCalculationAction = () => ({
  type: LOCK_CALC,
});

const enableCalcAction = value => ({
  type: ENABLE_CALC,
  payload: value,
});

const fetchPaymentChangesDetailsAction = payload => ({
  type: FETCH_PAYMENT_CHANGES_DETAILS,
  payload,
});

const showLoader = () => ({
  type: SHOW_LOADER,
});

const hideLoader = () => ({
  type: HIDE_LOADER,
});

const sendForApprovalAction = payload => ({
  type: SEND_FORAPPROVAL_RULES,
  payload,
});

const approvalAction = payload => ({
  type: SEND_FORAPPROVAL,
  payload,
});

const fetchResolutionRejectReasonAction = payload => ({
  type: FETCH_REJECT_REASONS,
  payload,
});

const setResolutionRejectReasonAction = payload => ({
  type: SET_REJECT_REASONS,
  payload,
});

const rejectCaseRulesAction = payload => ({
  type: REJECT_CASE_RULES,
  payload,
});

const calcRepayAction = value => ({
  type: CALCULATE_REPAYMENT_DATA,
  payload: value,
});


export {
  updateMonthlyPaymentDetailsAction,
  fetchMonthlyPaymentDetailsAction,
  updateRepayTrackPaymentDatesAction,
  ruleCheckAction,
  lockCalculationAction,
  enableCalcAction,
  fetchPaymentChangesDetailsAction,
  showLoader,
  hideLoader,
  sendForApprovalAction,
  approvalAction,
  fetchResolutionRejectReasonAction,
  setResolutionRejectReasonAction,
  rejectCaseRulesAction,
  calcRepayAction,
};
