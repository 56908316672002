import {
  Button, MenuItem, Select, FormControlLabel, Checkbox, Radio, RadioGroup,
} from '@material-ui/core';
import SubmittedRecords from 'components/SubmittedRecords';
import { caseOptions, varifyName, varifyOption } from 'constants/SupportAdmin';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import Popup from '../../../components/Popup';
import './ForceCreateEvalCase.css';

const ForceCreateEvalCase = ({ setIdType, recordsInfo, submitForceCreateEvalCaseOperation }) => {
  const [caseObj, setCaseObj] = useState({
    ticket: '',
    loanId: '',
    requestorID: '',
    evalType: '',
    preApprovedType: '',
    comments: '',
    evaluationRules: false,
    caseRules: false,
  });
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [disableCreate, setDisableCreate] = useState(true);
  const [ispopupVisible, setIspopupVisible] = useState(false);

  React.useEffect(() => {
    setIdType('loan');
  }, []);


  const handleOnBlur = () => {
    const {
      ticket,
      loanId,
      requestorID,
      evalType,
      preApprovedType,
      comments,
    } = caseObj;
    // Disaster Case/ Standard Eval/ Pandemic Eva - skip rejection -disable
    let disablebtn = true;
    if (ticket !== '' || loanId !== '' || requestorID !== '' || comments !== '' || evalType !== '') {
      disablebtn = false;
    }
    setDisableButton(disablebtn);
    let disableCrt = true;
    if (ticket !== '' && loanId !== '' && requestorID !== '' && comments !== '' && evalType !== '') {
      if (evalType === 'pre-approved' && preApprovedType !== '') {
        disableCrt = false;
      } else if (varifyOption.includes(evalType)) {
        disableCrt = false;
      }
    }
    setDisableCreate(disableCrt);
  };

  const {
    evalType, preApprovedType, caseRules, evaluationRules,
  } = caseObj;

  React.useEffect(() => {
    handleOnBlur();
  }, [evalType, preApprovedType, evaluationRules, caseRules]);

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = !varifyName.includes(name) ? value : checked;
    if (name === 'evalType') {
      updatedObject.evaluationRules = false;
      updatedObject.caseRules = false;
      updatedObject.preApprovedType = '';
      setIsCheckboxVisible(['pre-approved', 'state-eval-case'].includes(value));
    }
    setCaseObj(updatedObject);
  };

  const clearEdits = () => {
    setCaseObj({
      ticket: '',
      loanId: '',
      requestorID: '',
      evalType: '',
      preApprovedType: '',
      comments: '',
      evaluationRules: false,
      caseRules: false,
    });
    setDisableButton(true);
    setDisableCreate(true);
  };

  const handleSubmit = () => {
    submitForceCreateEvalCaseOperation(caseObj);
    setIspopupVisible(false);
  };

  // eslint-disable-next-line max-len
  const renderCaseOptions = options => options.map((option) => {
    const value = option.replace('/', '').split(' ').join('-').toLowerCase();
    return <MenuItem value={value}>{option}</MenuItem>;
  });

  return (
    <div styleName="form-fields-container">
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="ServiceNow Ticket" name="ticket" type="number" value={caseObj.ticket} />
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="Loan ID" name="loanId" type="number" value={caseObj.loanId} />
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="Requestor ID" name="requestorID" value={caseObj.requestorID} />
      <div styleName="flex-container select-field-container">
        <p>
          Eval Type
        </p>
        <Select
          displayEmpty
          name="evalType"
          onChange={handleChange}
          value={caseObj.evalType}
        >
          <MenuItem value="">Select</MenuItem>
          {renderCaseOptions(caseOptions)}
        </Select>
      </div>
      {caseObj.evalType === 'pre-approved' && (
        <div styleName="flex-container radio-container">
          <p>
            Pre-Approved Type
          </p>
          <RadioGroup
            name="preApprovedType"
            onChange={handleChange}
            row
            value={caseObj.preApprovedType}
          >
            <FormControlLabel
              control={<Radio size="small" />}
              label="Inflight"
              value="inflight"
            />
            <FormControlLabel
              control={<Radio size="small" />}
              label="Non Delegated"
              value="nonDelegated"
            />
          </RadioGroup>
        </div>
      )}
      <InputField handleBlur={handleOnBlur} handleChange={handleChange} label="Comments" multiline name="comments" value={caseObj.comments} />
      <div styleName="flex-container radio-container">
        <p>
          Skip Rejection Rules
        </p>
        <div style={{ width: '11rem' }}>
          <FormControlLabel control={<Checkbox checked={caseObj.evaluationRules} disabled={!isCheckboxVisible} name="evaluationRules" onChange={handleChange} />} label="Evaluation Rules" style={{ minWidth: '125px', marginBottom: '6px' }} />
          <FormControlLabel control={<Checkbox checked={caseObj.caseRules} disabled={!isCheckboxVisible} name="caseRules" onChange={handleChange} />} label="Case Rules" style={{ minWidth: '125px', marginBottom: '6px' }} />
        </div>
      </div>
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={disableCreate} onClick={() => setIspopupVisible(true)} variant="contained">
          CREATE
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Loan ID"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
    </div>
  );
};

ForceCreateEvalCase.propTypes = {
  recordsInfo: PropTypes.shape().isRequired,
  setIdType: PropTypes.func.isRequired,
  submitForceCreateEvalCaseOperation: PropTypes.func.isRequired,
};

export default ForceCreateEvalCase;
