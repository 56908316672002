import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Darts.css';
import DartsTypes from './DartsType';

const Darts = ({ dartHistorys = { dartHistory: [] } }) => {
  // const {
  //   dartHistory,
  // } = dartHistorys;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => setIsExpanded(!isExpanded);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
    }
  };

  return (
    <>
      <div
        aria-expanded={isExpanded}
        className={styles.accordion}
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <div className={styles.accordion_content}>
          <div className={styles.accordion_title}>
            <strong>Darts</strong>
          </div>
          {/* <div className={styles.type_box}>
            <span />
          </div> */}

          <div className={styles.accordion_arrow} style={{ background: '#F3F5F9' }}>

            <img
              alt="toggle"
              height="7"
              src="/static/img/downArrow.svg"
              style={{ width: '14px', marginLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
      <DartsTypes dartHistorys={dartHistorys} />
      )}
    </>
  );
};


Darts.propTypes = {
  dartHistorys: PropTypes.shape({
    reportedWorkoutCampaignName: PropTypes.string.isRequired,
    reportedWorkoutCaseStatus: PropTypes.string.isRequired,
    reportedWorkoutPaymentDueDates: PropTypes.string.isRequired,
    reportedWorkoutServicerName: PropTypes.string.isRequired,
    reportedWorkoutType: PropTypes.string.isRequired,

    resolutionId: PropTypes.string.isRequired,
  }),
};

Darts.defaultProps = {
  dartHistorys: { dartHistory: [] },
};
export default Darts;
