import {
  Button, MenuItem, Select,
} from '@material-ui/core';
import SubmittedRecords from 'components/SubmittedRecords';
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/admin-tools';
import InputFieldMultiline from 'components/ProductSupport/InputFieldMultiline';
import InputField from '../../../components/InputField';
import './BulkAction.css';
import Popup from '../../../components/Popup';

const actionsWithoutRsnCD = ['clear-door-25', 'update-door-25'];
const validateReason = ['reject-eval-with-reason', 'reject-case-with-reason', ...actionsWithoutRsnCD];
const actionOptions = ['Reject Eval with Reason', 'Reject Case with Reason', 'Clear Door 25', 'Update Door 25'];

const notEmpty = R.compose(R.not, R.empty);

const getIdArray = ids => R.filter(notEmpty, ids.trim().replace(/\n/g, ',').split(',').map(s => s.trim()));

const getUnSuccessfullRecords = (records) => {
  if (!R.isEmpty(records)) {
    return R.map(key => [key, records[0][key]], Object.keys(records[0]));
  }
  return [];
};

const BulkAction = (props) => {
  const {
    setIdType, recordsInfo, executeAction, idType, reasonCodes,
  } = props;
  const [caseObj, setCaseObj] = useState({
    ticket: '',
    action: '',
    reasonCode: '',
    caseIDs: '0',
    evalIDs: '0',
    loanIDs: '0',
    requestorID: '',
    comments: '',
  });
  const [id, setID] = useState({ label: 'Case ID', value: 'caseIDs' });
  const [ispopupVisible, setIspopupVisible] = useState(false);
  const [enableReasonCode, setEnableReasonCode] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [disableActionBtn, setDisableActionBtn] = useState(true);
  const [idLimitValidation, setIdLimitValidation] = useState(false);

  useEffect(() => {
    if (caseObj.caseIDs === '') {
      setCaseObj(prevState => ({
        ...prevState,
        case: 0,
      }));
      setDisableActionBtn(true);
    }
  }, [caseObj.caseIDs]);

  useEffect(() => {
    if (caseObj.evalIDs === '') {
      setCaseObj(prevState => ({
        ...prevState,
        evalIDs: 0,
      }));
      setDisableActionBtn(true);
    }
  }, [caseObj.evalIDs]);

  useEffect(() => {
    if (caseObj.loanIDs === '') {
      setCaseObj(prevState => ({
        ...prevState,
        loanIDs: 0,
      }));
      setDisableActionBtn(true);
    }
  }, [caseObj.loanIDs]);

  function validateID(result) {
    switch (result) {
      case 'reject-eval-with-reason':
        return { label: 'Eval ID', value: 'evalIDs' };
      case 'clear-door-25':
        return { label: 'Loan ID', value: 'loanIDs' };
      default:
        return { label: 'Case ID', value: 'caseIDs' };
    }
  }

  const handleBlur = () => {
    const {
      ticket,
      action,
      reasonCode,
      caseIDs,
      evalIDs,
      loanIDs,
      requestorID,
      comments,
    } = caseObj;
    let disableClear = true;
    let disableAction = true;
    if (ticket !== '' || requestorID !== '' || comments !== '') {
      disableClear = false;
    }
    if (action !== '') {
      if (action === 'reject-eval-with-reason' && (getIdArray(evalIDs).length > 0)) {
        disableClear = false;
      } else if (action === 'clear-door-25' && (getIdArray(loanIDs).length > 0)) {
        disableClear = false;
      } else if (getIdArray(caseIDs).length > 0) {
        disableClear = false;
      }
    }
    setDisableButton(disableClear);
    if (ticket !== '' && requestorID !== '' && comments !== '' && action !== '') {
      if (action === 'reject-eval-with-reason' && (getIdArray(evalIDs).length > 0) && reasonCode !== '') {
        disableAction = false;
      } else if (action === 'reject-case-with-reason' && (getIdArray(caseIDs).length > 0) && reasonCode !== '') {
        disableAction = false;
      } else if (action === 'clear-door-25' && (getIdArray(loanIDs).length > 0)) {
        disableAction = false;
      } else if (action === 'update-door-25' && getIdArray(caseIDs).length > 0) {
        disableAction = false;
      }
    }
    setDisableActionBtn(disableAction);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    if (validateReason.includes(value)) {
      const selectedIdType = validateID(value);
      // eslint-disable-next-line no-nested-ternary
      setIdType(selectedIdType.value === 'caseIDs' ? 'case' : (selectedIdType.value === 'evalIDs' ? 'eval' : 'loan'));
      setID(selectedIdType);
      updatedObject.caseIDs = '0';
      updatedObject.evalIDs = '0';
      updatedObject.loanIDs = '0';
      updatedObject.reasonCode = '';
      setEnableReasonCode(false);
      idType(selectedIdType.value.substring(0, 4));
    }
    setCaseObj(updatedObject);
  };

  const handleChangeID = (event) => {
    const { name, value } = event.target;
    const updatedObjectNew = { ...caseObj };
    const validIdEntries = RegExp(/[a-zA-Z]|[~`(@!#$%^&*+._)=\-[\]\\';/{}|\\":<>?]/);
    updatedObjectNew[name] = !validIdEntries.test(value) ? value.split(/,| /).join('\n') : '';
    setCaseObj(updatedObjectNew);
    if (validateReason.includes(value)) {
      const selectedIdType = validateID(value);
      // eslint-disable-next-line no-nested-ternary
      setIdType(selectedIdType.value === 'caseIDs' ? 'case' : (selectedIdType.value === 'evalIDs' ? 'eval' : 'loan'));
      setID(selectedIdType);
    }
    const arr = R.filter(notEmpty, value.trim().replace(/\n/g, ',').split(',').map(s => s.trim()));
    const enable = arr.length > 0;
    setIdLimitValidation(arr.length > 100);
    setEnableReasonCode(enable);
    handleBlur();
  };

  const clearEdits = () => {
    setCaseObj({
      ticket: '',
      action: '',
      reasonCode: '',
      caseIDs: '0',
      evalIDs: '0',
      loanIDs: '0',
      requestorID: '',
      comments: '',
    });
    setEnableReasonCode(false);
    setDisableButton(true);
  };

  const renderActionOptions = () => actionOptions.map((option) => {
    const value = option.replace('/', '').split(' ').join('-').toLowerCase();
    return <MenuItem value={value}>{option}</MenuItem>;
  });

  const reasonCodeOptions = reasonCodes[id.value.substring(0, 4)];
  const renderreasonCodeOptions = () => reasonCodeOptions && reasonCodeOptions.map(
    option => <MenuItem value={option}>{option.split(',')[1].trim()}</MenuItem>,
  );

  const handleSubmit = () => {
    setIspopupVisible(false);
    executeAction(caseObj);
  };
  return (
    <div styleName="form-fields-container">
      <InputField handleBlur={handleBlur} handleChange={handleChange} label="ServiceNow Ticket" name="ticket" type="number" value={caseObj.ticket} />
      <div styleName="flex-container select-field-container">
        <p>
          Action
        </p>
        <Select
          displayEmpty
          name="action"
          onBlur={handleBlur}
          onChange={handleChange}
          value={caseObj.action}
        >
          <MenuItem disabled value="">Select</MenuItem>
          {renderActionOptions()}
        </Select>
      </div>
      {validateReason.includes(caseObj.action) && (
        <>
          <InputFieldMultiline
            errorMsg={idLimitValidation && 'Entries exceeded the maximum limit of 100'}
            handleChange={handleChangeID}
            label={id.label}
            name={id.value}
            value={caseObj[id.value]}
          />
          {!actionsWithoutRsnCD.includes(caseObj.action) && (
            <div styleName="flex-container select-field-container">
              <p>
                Reason Code
              </p>
              <Select
                disabled={!enableReasonCode}
                displayEmpty
                name="reasonCode"
                onBlur={handleBlur}
                onChange={handleChange}
                value={caseObj.reasonCode}
              >
                <MenuItem value="">Select</MenuItem>
                {renderreasonCodeOptions()}
              </Select>
            </div>
          )
          }
        </>
      )}
      <InputField handleBlur={handleBlur} handleChange={handleChange} label="Requestor ID" name="requestorID" value={caseObj.requestorID} />
      <InputField handleBlur={handleBlur} handleChange={handleChange} label="Comments" multiline name="comments" value={caseObj.comments} />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={idLimitValidation || disableActionBtn} onClick={() => setIspopupVisible(true)} variant="contained">
          EXECUTE ACTION
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type={id.label}
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={getUnSuccessfullRecords(recordsInfo.unsuccessfulRecords || [])}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
    </div>
  );
};

BulkAction.defaultProps = {
};

BulkAction.propTypes = {
  executeAction: PropTypes.func.isRequired,
  idType: PropTypes.func.isRequired,
  reasonCodes: PropTypes.shape().isRequired,
  recordsInfo: PropTypes.shape().isRequired,
  setIdType: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  reasonCodes: selectors.getBulkActionReasonCodes(state),
});

const mapDispatchToProps = dispatch => ({
  executeAction: operations.executeActionOperation(dispatch),
  idType: operations.setIdTypeOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkAction);
