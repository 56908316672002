const COMMENTS = 'Comments';
const ADDITIONAL_INFO = 'Additional Info';
const HISTORY = 'History';
const CUSTOM_COMM_LETTER = 'Customer Communication Letter';
const BOOKING = 'BookingAutomation';
const FINANCIAL_CALCULATOR = 'FinancialCalculator';
const FHLMC = 'FHLMC';
const LSAMS_NOTES = 'LSAMS Notes';
const DOCUMENT_CHECKLIST = 'DocumentChecklist';
const EVAL = 'Eval Info';
const WESTWING = 'lossmitigation';
const WESTWINGWIDGET = 'WESTWINGWIDGET';
const EVAL_SCRN = 'EVAL_SCRN';
const PAYMENT_SUPPLEMENT = 'Payment Supplement';
const SMDU = 'SMDU';
const FNMA = 'FNMA';

const SMDU_OPERATION_TYPE = {
  'Pre Qualify': 1,
  'Trial Case': 2,
  'Mod Case': 3,
  'Run IDI': 5,
};

const REQUEST_TYPE_MAP = {
  'Structuring-Final': 'Mod Case',
  'Structuring-Trial': 'Trial Case',
  'Pre-Qualification': 'PreQualify',
  'Imminent Default': 'Run Idi',
};

const FAILED_STATUS_CODES = [400, 404, 422, 500];
const PRIOR_WORKOUT_SUCCESS = 'Prior Workout Data Saved Successfully!';
const SUBMIT_FNMA_SUCCESS = 'Successfully Submitted to FNMA !';

const widgets = [
  COMMENTS,
  ADDITIONAL_INFO,
  HISTORY,
  CUSTOM_COMM_LETTER,
  BOOKING,
  FINANCIAL_CALCULATOR,
  FHLMC,
  LSAMS_NOTES,
  DOCUMENT_CHECKLIST,
  EVAL,
  WESTWING,
  WESTWINGWIDGET,
  EVAL_SCRN,
  PAYMENT_SUPPLEMENT,
  SMDU,
  FNMA,
];

export {
  COMMENTS,
  ADDITIONAL_INFO,
  HISTORY,
  CUSTOM_COMM_LETTER,
  BOOKING,
  FINANCIAL_CALCULATOR,
  widgets,
  FHLMC,
  LSAMS_NOTES,
  DOCUMENT_CHECKLIST,
  EVAL,
  WESTWING,
  WESTWINGWIDGET,
  EVAL_SCRN,
  PAYMENT_SUPPLEMENT,
  SMDU,
  SMDU_OPERATION_TYPE,
  REQUEST_TYPE_MAP,
  FAILED_STATUS_CODES,
  FNMA,
  PRIOR_WORKOUT_SUCCESS,
  SUBMIT_FNMA_SUCCESS,
};
