import {
  Button, MenuItem, Select,
} from '@material-ui/core';
import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField';
import Popup from '../../../components/Popup';
import SubmittedRecords from '../../../components/SubmittedRecords';
import './ForceCreateCase.css';


const ForceCreateCase = ({
  setIdType, recordsInfo, getCaseTypes, caseTypes, submitForceCreateCaseOperation, onClear,
}) => {
  const [caseObj, setCaseObj] = useState({
    ticket: '',
    evalId: '',
    loanId: '',
    requestorID: '',
    comments: '',
    case: '',
  });
  const [isLoanId, setIsLoanId] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [disableCreate, setDisableCreate] = useState(true);
  const [ispopupVisible, setIspopupVisible] = useState(false);
  const verifyId = ['deed-in-lieu', 'repayment-plan', 'short-sale'];

  React.useEffect(() => {
    getCaseTypes();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    setCaseObj(updatedObject);
    if (name === 'case') {
      setIdType(verifyId.includes(updatedObject.case) ? 'loan' : 'eval');
      setIsLoanId(verifyId.includes(updatedObject.case));
    }
  };


  const checkNotNullAndEmpty = value => !R.isNil(value) && !R.isEmpty(value);

  const handleBlur = () => {
    const {
      ticket, case: caseType, requestorID, comments, loanId, evalId,
    } = caseObj;
    const idNotNull = checkNotNullAndEmpty(verifyId.includes(caseType) ? loanId : evalId);
    const isCreateButtonEnabled = checkNotNullAndEmpty(ticket)
     && checkNotNullAndEmpty(requestorID) && checkNotNullAndEmpty(comments)
                                   && checkNotNullAndEmpty(caseType) && idNotNull;
    const isClearButtonEnabled = checkNotNullAndEmpty(ticket)
                                   || checkNotNullAndEmpty(requestorID)
                                    || checkNotNullAndEmpty(comments)
                                    || (checkNotNullAndEmpty(caseType) && idNotNull);
    setDisableButton(!isClearButtonEnabled);
    setDisableCreate(!isCreateButtonEnabled);
  };

  const clearEdits = () => {
    setCaseObj({
      ticket: '',
      evalId: '',
      loanId: '',
      requestorID: '',
      comments: '',
      case: '',
    });
    setDisableButton(true);
    setDisableCreate(true);
    onClear();
  };

  const handleSubmit = () => {
    submitForceCreateCaseOperation(caseObj);
    setIspopupVisible(false);
  };

  // eslint-disable-next-line max-len
  const renderCaseOptions = options => options.map(({ caseType }) => {
    const value = caseType && caseType.replace('/', '').split(' ').join('-').toLowerCase();
    return <MenuItem value={value}>{caseType}</MenuItem>;
  });

  return (
    <div styleName="form-fields-container">
      <InputField handleBlur={handleBlur} handleChange={handleChange} label="ServiceNow Ticket" name="ticket" type="number" value={caseObj.ticket} />
      <div styleName="flex-container select-field-container">
        <p>
          Select a case
        </p>
        <Select
          displayEmpty
          name="case"
          onBlur={handleBlur}
          onChange={handleChange}
          value={caseObj.case}
        >
          <MenuItem value="">Select</MenuItem>
          {renderCaseOptions(caseTypes)}
        </Select>
      </div>
      {caseObj.case.length > 0 && (
        <InputField
          handleBlur={handleBlur}
          handleChange={handleChange}
          label={isLoanId ? 'Loan ID' : 'Eval ID'}
          name={isLoanId ? 'loanId' : 'evalId'}
          type="number"
          value={isLoanId ? caseObj.loanId : caseObj.evalId}
        />
      )}
      <InputField handleBlur={handleBlur} handleChange={handleChange} label="Requestor ID" name="requestorID" value={caseObj.requestorID} />

      <InputField handleBlur={handleBlur} handleChange={handleChange} label="Comments" multiline name="comments" value={caseObj.comments} />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={disableCreate} onClick={() => setIspopupVisible(true)} variant="contained">
          CREATE
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type={verifyId.includes(caseObj.caseType) ? 'Loan ID' : 'Eval ID'}
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
    </div>
  );
};

ForceCreateCase.propTypes = {
  caseTypes: PropTypes.arrayOf(PropTypes.shape).isRequired,
  getCaseTypes: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  recordsInfo: PropTypes.shape().isRequired,
  setIdType: PropTypes.func.isRequired,
  submitForceCreateCaseOperation: PropTypes.func.isRequired,
};

export default ForceCreateCase;
