import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment-timezone';
import * as R from 'ramda';
import DashboardModel from 'models/Dashboard';
import './CommentsWidget.css';
import dashboardSelectors from 'ducks/dashboard/selectors';
import { selectors as loginSelectors } from 'ducks/login';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import { selectors, operations } from 'ducks/comments';
import { HISTORY, ADDITIONAL_INFO } from 'constants/widgets';


const formatDateWithoutTimeZone = (date) => {
  if (date) {
    const newDate = moment(`${date}Z`).tz('America/Chicago').format('MM/DD/YYYY hh:mm:ss A');
    return newDate;
  }
  return null;
};

const getFullName = (comments, userName) => {
  if (comments.userName === userName) return 'you';
  return comments.userName;
};

const getContextTaskName = (groupName) => {
  let taskName = '';
  switch (groupName) {
    case 'FEUW':
      taskName = 'Financial Calculation Review';
      break;
    case 'BEUW':
      taskName = 'Underwriting Review';
      break;
    case 'PROC':
      taskName = 'Processing Review';
      break;
    default:
      taskName = groupName;
      break;
  }
  return taskName;
};

const getContextData = (Context) => {
  try {
    const context = JSON.parse(Context);
    const taskName = getContextTaskName(context.task ? context.task : context.TASK);
    return (R.isNil(context.TASK_ACTN) || R.isEmpty(context.TASK_ACTN)) ? `${taskName}` : `${taskName} - ${context.TASK_ACTN}`;
  } catch (e) {
    return '';
  }
};

const showLoader = () => (
  <div styleName="circular-progress-loader">
    <CircularProgress
      left={10}
      size={40}
      status="loading"
      top={-10}
    />
  </div>
);

const renderNoCommentsArea = () => (
  <span styleName="no-comments-area">No Comments</span>
);

class CommentsWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader: true,
      content: '',
    };
    this.commentArea = React.createRef();
    this.loadComments = this.loadComments.bind(this);
    this.saveComments = this.saveComments.bind(this);
    this.onCommentChange = this.onCommentChange.bind(this);
    this.showCommentsArea = this.showCommentsArea.bind(this);
    this.renderCommentsActivity = this.renderCommentsActivity.bind(this);
  }

  componentDidMount() {
    this.loadComments();
  }

  componentDidUpdate() {
    this.commentArea.scrollTop = this.commentArea.scrollHeight;
  }

  componentWillUnmount() {
    const { clearOnSearch } = this.props;
    clearOnSearch(false);
  }

  onCommentChange(event) {
    if (event.charCode === 13 || event.key === 'Enter') {
      event.preventDefault();
      if (event.target.value !== '') {
        this.saveComments();
        this.setState({ content: '' });
      }
    } else {
      this.setState({ content: event.target.value });
    }
  }

  loadComments() {
    const {
      AppName,
      LoanNumber,
      ProcessId,
      ProcIdType,
      onGetComments,
      groupName,
      page,
      openWidgetList,
    } = this.props;

    const pageIns = DashboardModel.GROUP_INFO
      .find(pageInstance => pageInstance.group === groupName);
    const eventName = !R.isNil(pageIns) ? pageIns.taskCode : '';
    const searchArea = R.equals(page, 'SEARCH_LOAN') || R.equals(page, 'INDEXER');

    if (!R.contains(ADDITIONAL_INFO, openWidgetList)) {
      const payload = {
        applicationName: AppName,
        loanNumber: LoanNumber,
        processIdType: ProcIdType,
        processId: ProcessId,
        eventName,
        searchArea,
      };
      onGetComments(payload);
    }
    this.setState({ Loader: false });
  }

  saveComments() {
    const {
      content,
    } = this.state;
    const {
      AppName,
      LoanNumber,
      ProcessId,
      ProcIdType,
      TaskId,
      taskIterationCounter,
      User,
      onPostComment,
      groupName,
    } = this.props;

    const page = DashboardModel.GROUP_INFO.find(pageInstance => pageInstance.group === groupName);
    const eventName = !R.isNil(page) ? page.taskCode : '';
    const taskName = !R.isNil(page) ? page.task : '';
    const group = !R.isNil(page) && R.has('group', page) ? R.prop('group', page) : null;
    const searchArea = R.equals(group || page, 'SEARCH_LOAN') || R.equals(group || page, 'INDEXER');

    const payload = {
      applicationName: AppName,
      loanNumber: LoanNumber,
      processIdType: ProcIdType,
      processId: ProcessId,
      eventName,
      comment: content,
      commentContext: JSON.stringify({
        TASK: taskName,
        TASK_ID: TaskId,
        processId: ProcessId,
        TASK_ITRN_CNTR: taskIterationCounter,
      }),
      userName: User.userDetails.name,
      createdDate: new Date().toJSON(),
      searchArea,
    };

    onPostComment(payload);
    this.loadComments();
    this.setState({ content: '' });
  }


  showCommentsArea() {
    let {
      comments,
    } = this.props;
    const {
      User, evalComments, openWidgetList,
      addInfoEvalId,
      EvalId, showEvalId,
    } = this.props;
    comments = R.flatten(R.map(comm => comm.comments, evalComments.comments));
    comments = (R.contains(ADDITIONAL_INFO, openWidgetList) || R.contains(HISTORY, openWidgetList))
      || !showEvalId ? R.prop('comments', R.head(R.filter(data => R.equals(data.evalId, R.contains(ADDITIONAL_INFO, openWidgetList)
        ? addInfoEvalId : EvalId), evalComments.comments))) : comments;

    return (
      comments && comments.map(comment => (
        <div
          id="row_main_container"
          styleName={comment.userName === User.userDetails.name ? 'row-main-container-right' : 'row-main-container-left'}
        >
          <div id="row_header" styleName="row-header">
            <div styleName={comment.userName === User.userDetails.name ? 'messagee-body-current-user' : 'message-body-other-user'}>
              {showEvalId && !R.contains(ADDITIONAL_INFO, openWidgetList) && !R.contains(HISTORY, openWidgetList) && <div style={{ fontWeight: 'bold' }}>{comment.evalId}</div>}
              {comment.comment}
              <div styleName="message-body-bottom" />
              <div>
                <span styleName="user-name">{getFullName(comment, User.userDetails.name)}</span>
                <span styleName="display-date">{formatDateWithoutTimeZone(comment.createdDate)}</span>
              </div>
              <div styleName="check-icon-style">
                <CheckCircleIcon styleName="check-circle-icon" />
                <span styleName="disposition-selected">{getContextData(comment.commentContext)}</span>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  }

  renderCommentsActivity() {
    const {
      evalComments,
      LoanNumber, EvalId,
      isAssigned, addInfoEvalId, openWidgetList, page,
    } = this.props;
    const searchArea = R.equals(page, 'SEARCH_LOAN');
    let comments = R.propOr(null, 'comments', evalComments);
    comments = R.contains(ADDITIONAL_INFO, openWidgetList) ? R.prop('comments', R.head(R.filter(data => R.equals(data.evalId, R.contains(ADDITIONAL_INFO, openWidgetList)
      ? addInfoEvalId : EvalId), evalComments.comments))) : comments;
    const { content } = this.state;
    return (
      <>
        <div styleName="comment-header">Comments</div>
        <div id="comment_main" styleName={R.equals(page, 'INDEXER') ? 'comment-main-style-indexer' : 'comment-main-style'}>
          <div ref={(ref) => { this.commentArea = ref; }} styleName={R.equals(page, 'INDEXER') ? 'comment-area-indexer' : 'comment-area'}>
            {comments && comments.length ? this.showCommentsArea() : renderNoCommentsArea()}
          </div>
          <div id="send_area" styleName={R.equals(page, 'INDEXER') ? 'send-area-indexer' : 'send-area'}>
            <div id="send_text_area" styleName="send-text-area">
              <TextField
                disabled={!isAssigned || searchArea}
                InputProps={{ style: { fontSize: '1.1rem' } }}
                multiline
                onChange={event => this.onCommentChange(event)}
                onKeyPress={event => this.onCommentChange(event)}
                placeholder="Enter Comment"
                rowsMax="4"
                style={{ width: '100%', height: '5.75rem' }}
                value={content}
              />
            </div>
            <div id="send_button_area" styleName="send-button-area">
              <Button
                color="primary"
                disabled={content.length === 0
                  || R.isNil(LoanNumber) || R.isNil(EvalId) || searchArea}
                id="post_button"
                onClick={this.saveComments}
                styleName="post-button"
                variant="contained"
              >
                POST
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { Loader } = this.state;
    return (
      Loader ? showLoader() : this.renderCommentsActivity()
    );
  }
}

CommentsWidget.propTypes = {
  addInfoEvalId: PropTypes.string.isRequired,
  AppName: PropTypes.string,
  clearOnSearch: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
  })).isRequired,
  evalComments: PropTypes.shape().isRequired,
  EvalId: PropTypes.number.isRequired,
  groupName: PropTypes.string,
  isAssigned: PropTypes.bool.isRequired,
  LoanNumber: PropTypes.string.isRequired,
  onGetComments: PropTypes.func.isRequired,
  onPostComment: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.string,
  ProcessId: PropTypes.number.isRequired,
  ProcIdType: PropTypes.string,
  showEvalId: PropTypes.bool.isRequired,
  TaskId: PropTypes.number.isRequired,
  taskIterationCounter: PropTypes.number.isRequired,
  User: PropTypes.shape({
    userDetails: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

CommentsWidget.defaultProps = {
  AppName: 'CMOD',
  ProcIdType: 'WF_PRCS_ID',
  groupName: '',
  page: null,
  openWidgetList: [],
};

const mapStateToProps = state => ({
  comments: selectors.getCommentsData(state),
  evalComments: selectors.getEvalComments(state),
  showEvalId: selectors.showEvalInComments(state),
  EvalId: dashboardSelectors.evalId(state),
  TaskId: dashboardSelectors.taskId(state),
  LoanNumber: dashboardSelectors.loanNumber(state),
  ProcessId: dashboardSelectors.processId(state),
  groupName: dashboardSelectors.groupName(state),
  taskIterationCounter: dashboardSelectors.taskIterationCounter(state),
  User: loginSelectors.getUser(state),
  isAssigned: dashboardSelectors.isAssigned(state),
  addInfoEvalId: dashboardSelectors.addInfoEvalId(state),
  wasSearched: dashboardSelectors.wasSearched(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
});

const mapDispatchToProps = dispatch => ({
  onGetComments: operations.getComments(dispatch),
  onPostComment: operations.postComment(dispatch),
  clearOnSearch: operations.clearOnSearch(dispatch),
});

export {
  CommentsWidget,
};
export default connect(mapStateToProps, mapDispatchToProps)(CommentsWidget);
