/* eslint-disable no-unused-vars */
import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './Item.css';

const styleObj = {
  default: {
    wrapper: 'wrapper',
    item: 'item',
    title: 'title',
    content: 'content',
  },
};

function Item({
  content, title, Component, style, disableIcons, inflightIcon, preApprovedIcon,
}) {
  let contentStyle = 'content';
  let titleStyle = 'title';

  if (title === 'Previous Disposition' && content.trim().toLowerCase() === 'send for qc review') {
    contentStyle = 'qCReviewContent';
  } else if (title === 'Assumptor' && content !== 'NA') {
    contentStyle = 'assupmtor';
    titleStyle = 'assumptorTitle';
  }
  return (
    <div styleName={R.pathOr('wrapper', [style, 'wrapper'], styleObj)}>
      <div styleName={R.pathOr('item', [style, 'item'], styleObj)}>
        <span styleName={R.pathOr(titleStyle, [style, 'title'], styleObj)}>{title}</span>
        <span style={{ display: 'flex', alignItems: 'center' }} styleName={R.pathOr(contentStyle, [style, 'content'], styleObj)}>
          {content}
          <Tooltip arrow arrowPlacement="end" placement="top" title="Pre-Approved">
            <span style={{ marginLeft: '0.45rem' }}>{preApprovedIcon}</span>
          </Tooltip>
          <Tooltip arrow arrowPlacement="end" placement="top" title="Inflight">
            <span style={{ marginLeft: '0.3rem' }}>{inflightIcon}</span>
          </Tooltip>
        </span>
      </div>
      {!disableIcons ? Component : ''}
    </div>

  );
}

Item.defaultProps = {
  content: 'content',
  title: 'title',
  Component: null,
  style: {},
  disableIcons: false,
  inflightIcon: null,
  preApprovedIcon: null,
};

Item.propTypes = {
  Component: PropTypes.node,
  content: PropTypes.string,
  disableIcons: PropTypes.bool,
  inflightIcon: PropTypes.node,
  preApprovedIcon: PropTypes.node,
  style: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string,
};

export default Item;
