// AD GROUPS DEFINED BELOW MUST BE IN LOWER CASE
export const ADMIN = 'admin';
export const BACKEND_UNDERWRITER = 'beuw';
export const FRONTEND_UNDERWRITER = 'feuw';
export const MANAGER = 'manager';
export const FRONTEND_MANAGER = 'feuw-mgr';
export const STAGER = 'stager';
export const STAGER_MANAGER = 'stager-mgr';
export const DOC_PROCESSOR = 'proc';
export const DOC_GEN = 'docgen';
export const DOCS_IN = 'docsin';
export const BOOKING = 'booking';
export const DOCGEN_VENDOR = 'docgenvendor';
export const DOCGEN_VENDOR_MGR = 'docgenvendor-mgr';
export const FHLMCRESOLVE = 'fhlmcresolve';
export const RPS_STAGER = 'rpsstager';
export const RPS_STAGER_MGR = 'rpsstager-mgr';
export const INVSET = 'invset';
export const SECONDLOOK = 'secondlook';
export const VP = 'vp';
export const WESTWING = 'lossmitigation';
export const WESTWING_MGR = 'lossmitigation-mgr';
export const USERSKILLS = 'user-skills';
export const RSH_STAGER = 'rshstager';
export const RSH_STAGER_MGR = 'rshstager-mgr';
export const LOSS_MITIGATION = 'lossmitigation';
export const LOSS_MITIGATION_MGR = 'lossmitigation-mgr';
export const POSTMOD = 'postmodstager';
export const POSTMOD_MGR = 'postmodstager-mgr';
export const DOC_PROCESSING = 'docprocessing';
export const BACKEND_MANAGER = 'beuw-mgr';
export const POST_MOD = 'postmod';
export const INDEXER = 'indexer';
export const DOC_PROCESSOR_MGR = 'proc-mgr';
export const DOCS_IN_MGR = 'docsin-mgr';
export const DOC_GEN_MGR = 'docgen-mgr';
export const COVIUS = 'covius';
export const ESCROW = 'escrow';
export const ESCROW_MGR = 'escrow-mgr';
export const SUPPORT_ADMIN = 'supportadmin';
export const BUSINESS_ADMIN = 'businessadmin';
