import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import * as R from 'ramda';
import SubmittedRecords from 'components/SubmittedRecords';
import PropTypes from 'prop-types';
import InputField from '../../../components/InputField/InputField';
import Popup from '../../../components/Popup';
import './EvalDisasterIdRequest.css';

const defaultFormData = {
  ticket: '',
  evalId: '0',
  toUpdate: '',
  disasterID: '0',
  requestorID: '',
  comments: '',
};

const checkNotNullAndEmpty = value => !R.isNil(value) && !R.isEmpty(value);

const EvalDisasterIdRequest = ({ setIdType, updateRequest, recordsInfo }) => {
  const [evalDisasterObj, setevalDisasterObj] = useState(defaultFormData);
  const [disableButton, setDisableButton] = useState(true);
  const [disableUpdate, setDisableUpdate] = useState(true);

  useEffect(() => {
    setIdType('eval');
  }, []);
  const [ispopupVisible, setIspopupVisible] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...evalDisasterObj };
    updatedObject[name] = value;
    setevalDisasterObj(updatedObject);
  };

  const handleOnBlur = () => {
    const {
      ticket, evalId, disasterID, requestorID, comments,
    } = evalDisasterObj;
    setDisableUpdate(!(checkNotNullAndEmpty(ticket) && checkNotNullAndEmpty(evalId)
        && checkNotNullAndEmpty(requestorID) && checkNotNullAndEmpty(comments)
        && checkNotNullAndEmpty(disasterID)));
    setDisableButton(!(checkNotNullAndEmpty(ticket) || checkNotNullAndEmpty(evalId)
        || checkNotNullAndEmpty(requestorID) || checkNotNullAndEmpty(comments)
        || checkNotNullAndEmpty(disasterID)));
  };

  const handleSubmit = () => {
    updateRequest(evalDisasterObj);
    setIspopupVisible(false);
  };

  return (
    <div styleName="form-fields-container">
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="ServiceNow Ticket"
        name="ticket"
        type="number"
        value={evalDisasterObj.ticket}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Eval ID"
        name="evalId"
        type="number"
        value={evalDisasterObj.evalId}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Disaster ID"
        name="disasterID"
        type="number"
        value={evalDisasterObj.disasterID}
      />

      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Requestor ID"
        name="requestorID"
        value={evalDisasterObj.requestorID}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Comments"
        multiline
        name="comments"
        value={evalDisasterObj.comments}
      />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={() => {
            setevalDisasterObj(defaultFormData);
            setDisableButton(true);
            setDisableUpdate(true);
          }}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button
          color="primary"
          disabled={disableUpdate}
          onClick={() => setIspopupVisible(true)}
          variant="contained"
        >
          UPDATE REQUEST
        </Button>
      </div>
      <SubmittedRecords
        successfulRecords={recordsInfo.successCount || 0}
        type="Eval ID"
        unSuccessfulRecords={recordsInfo.failureCount || 0}
        unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
      />
      <Popup
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        level="Info"
        message="Are you sure you want to proceed?"
        onCancel={() => setIspopupVisible(false)}
        onConfirm={handleSubmit}
        show={ispopupVisible}
        showCancelButton
        showConfirmButton
      />
    </div>
  );
};

EvalDisasterIdRequest.propTypes = {
  recordsInfo: PropTypes.shape().isRequired,
  setIdType: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
};

export default EvalDisasterIdRequest;
