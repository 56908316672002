import React, { useState } from 'react';
import {
  Button,
} from '@material-ui/core';
import './UnRejectRequestCase.css';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import SubmittedRecords from 'components/SubmittedRecords';
import InputField from '../../../../components/InputField/InputField';
import Popup from '../../../../components/Popup';


const UnRejectRequestCase = ({ unRejectRequest, recordsInfo }) => {
  const [disableButton, setDisableButton] = useState(true);
  const [disableUnreject, setDisableUnReject] = useState(true);
  const [caseObj, setCaseObj] = useState({
    ticket: '',
    case: '0',
    requestorID: '',
    comments: '',
  });
  const [ispopupVisible, setIspopupVisible] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...caseObj };
    updatedObject[name] = value;
    setCaseObj(updatedObject);
  };

  const checkNotNullAndEmpty = value => !R.isNil(value) && !R.isEmpty(value);

  const handleOnBlur = () => {
    const {
      ticket, case: caseId, requestorID, comments,
    } = caseObj;
    setDisableUnReject(!(checkNotNullAndEmpty(ticket) && checkNotNullAndEmpty(caseId)
      && checkNotNullAndEmpty(requestorID) && checkNotNullAndEmpty(comments)));
    setDisableButton(!(checkNotNullAndEmpty(ticket) || checkNotNullAndEmpty(caseId)
      || checkNotNullAndEmpty(requestorID) || checkNotNullAndEmpty(comments)));
  };

  const clearEdits = () => {
    setCaseObj({
      ticket: '',
      case: '0',
      requestorID: '',
      comments: '',
    });
    setDisableButton(true);
    setDisableUnReject(true);
  };

  const handleSubmit = () => {
    unRejectRequest(caseObj);
    setIspopupVisible(false);
  };

  return (
    <div styleName="form-fields-container">
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="ServiceNow Ticket"
        name="ticket"
        type="number"
        value={caseObj.ticket}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Case ID"
        name="case"
        type="number"
        value={caseObj.case}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Requestor ID"
        name="requestorID"
        value={caseObj.requestorID}
      />
      <InputField
        handleBlur={handleOnBlur}
        handleChange={handleChange}
        label="Comments"
        multiline
        name="comments"
        value={caseObj.comments}
      />
      <span styleName="divider" />
      <div
        style={{ justifyContent: 'flex-end' }}
        styleName="flex-container"
      >
        <Button
          color="primary"
          disabled={disableButton}
          onClick={clearEdits}
          style={{ marginRight: '8px' }}
          variant="outlined"
        >
          CLEAR
        </Button>
        <Button color="primary" disabled={disableUnreject} onClick={() => setIspopupVisible(true)} variant="contained">
          UNREJECT REQUEST
        </Button>
      </div>
      <div>
        <SubmittedRecords
          successfulRecords={recordsInfo.successCount || 0}
          type="Case ID"
          unSuccessfulRecords={recordsInfo.failureCount || 0}
          unsuccessfulRecordsResp={recordsInfo.unsuccessfulRecords || []}
        />

        <Popup
          cancelButtonText="Cancel"
          confirmButtonText="Ok"
          level="Info"
          message="Are you sure you want to proceed?"
          onCancel={() => setIspopupVisible(false)}
          onConfirm={handleSubmit}
          show={ispopupVisible}
          showCancelButton
          showConfirmButton
        />
      </div>
    </div>
  );
};

UnRejectRequestCase.propTypes = {
  recordsInfo: PropTypes.shape().isRequired,
  unRejectRequest: PropTypes.func.isRequired,
};

export default UnRejectRequestCase;
