import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@material-ui/core/';
import RateInputBoxStyles from './RateInputBoxStyles';

const RateInput = ({
  id, label, onChange, override, value, type, columnType,
}) => {
  const [rateValue, setValue] = useState(value);
  const classes = RateInputBoxStyles({ override });
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    onChange(label, inputValue, columnType);
  };
  return (
    <FormControl className={classes.root}>
      <TextField
        className={classes.TextFieldDiv}
        disabled={override}
        id={id}
        InputProps={{
          inputProps: { min: 0 },
          disableUnderline: true,
          startAdornment: <InputAdornment className={classes.Currency} position="start">%</InputAdornment>,
        }}
        onChange={handleChange}
        type={type}
        value={rateValue}
      />
    </FormControl>
  );
};

RateInput.propTypes = {
  columnType: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  override: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

RateInput.defaultProps = {
  label: '',
  onChange: () => { },
  override: true,
  value: '',
  type: 'text',
  columnType: '',
};

export default RateInput;
