import {
  select,
  takeEvery,
  all,
  put,
  call,
} from 'redux-saga/effects';
import * as Api from 'lib/Api';
import * as R from 'ramda';
import { selectors as dashboardSelectors } from 'ducks/dashboard/index';
import {
  FETCH_PAYMENT_SUPPLEMENT_DATA,
  SET_PAYMENT_SUPPLEMENT_DATA,
} from './types';
import * as constants from '../../../constants/paymentSupplement';

const formatStepsObject = (step, columnVal, defaultValue, dataType, inputObj) => {
  let updatedRes = inputObj;
  try {
    const subSteps = step.split('.');
    const stepKey = subSteps[0];
    const firstLevelValue = updatedRes[stepKey];
    let updatedColumnVal = columnVal;
    if (dataType === 'boolean') {
      if (updatedColumnVal) {
        updatedColumnVal = JSON.parse(updatedColumnVal);
        updatedColumnVal = { dataType, booleanValue: updatedColumnVal };
      } else {
        updatedColumnVal = { dataType, booleanValue: defaultValue };
      }
    } else {
      updatedColumnVal = updatedColumnVal || defaultValue;
    }
    const secondLevelValue = firstLevelValue ? firstLevelValue[subSteps[1]] : {};
    if (subSteps[2]) {
      updatedRes = {
        ...updatedRes,
        [stepKey]: {
          ...firstLevelValue,
          [subSteps[1]]: {
            ...secondLevelValue,
            [subSteps[2]]: updatedColumnVal,
          },
        },
      };
    } else if (subSteps[1]) {
      updatedRes = {
        ...updatedRes,
        [stepKey]:
          { ...firstLevelValue, [subSteps[1]]: updatedColumnVal },
      };
    } else {
      updatedRes = { ...updatedRes, [stepKey]: updatedColumnVal };
    }
  } catch (e) {
    return updatedRes;
  }
  return updatedRes;
};

function* fetchPaymentSupplementData() {
  const { PAYMENT_SUPPLEMENT_DATA_MAPPING } = constants;
  try {
    const resolutionId = yield select(dashboardSelectors.resolutionId);
    let updatedRes = {};
    let ordered = {};
    let paymentSupplementRes = [];
    if (resolutionId) {
      paymentSupplementRes = yield call(Api.callGet, `/api/tkams/payment/mappingData/${resolutionId}`);
      if (paymentSupplementRes && Object.keys(paymentSupplementRes).length > 0) {
        Object.entries(paymentSupplementRes).forEach(([columnName, columnVal]) => {
          const stepsObj = R.pathOr(null, [columnName, 'step'], PAYMENT_SUPPLEMENT_DATA_MAPPING);
          const defaultValue = R.pathOr(null, [columnName, 'defaultValue'], PAYMENT_SUPPLEMENT_DATA_MAPPING);
          const dataType = R.pathOr(null, [columnName, 'dataType'], PAYMENT_SUPPLEMENT_DATA_MAPPING);
          if (stepsObj) {
            if (typeof (stepsObj) === 'object') {
              stepsObj.forEach((step) => {
                updatedRes = formatStepsObject(step, columnVal, defaultValue, dataType, updatedRes);
              });
            } else {
              updatedRes = formatStepsObject(stepsObj, columnVal, defaultValue, dataType,
                updatedRes);
            }
          }
        });
      }

      ordered = Object.keys(updatedRes).sort().reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = updatedRes[key];
        return obj;
      }, {});
    }

    yield put({ type: SET_PAYMENT_SUPPLEMENT_DATA, payload: ordered });
  } catch (e) {
    yield put({ type: SET_PAYMENT_SUPPLEMENT_DATA, payload: [] });
  }
}

function* watchFetchPaymentSupplementData() {
  yield takeEvery(FETCH_PAYMENT_SUPPLEMENT_DATA, fetchPaymentSupplementData);
}

// eslint-disable-next-line import/prefer-default-export
export function* combinedSaga() {
  yield all([
    watchFetchPaymentSupplementData(),
  ]);
}
