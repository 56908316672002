import {
  updateRepayTrackPaymentDatesAction,
  fetchMonthlyPaymentDetailsAction,
  updateMonthlyPaymentDetailsAction,
  ruleCheckAction,
  lockCalculationAction,
  enableCalcAction,
  fetchPaymentChangesDetailsAction,
  sendForApprovalAction,
  fetchResolutionRejectReasonAction,
  setResolutionRejectReasonAction,
  rejectCaseRulesAction,
  approvalAction,
  calcRepayAction,
} from './actions';


const updateRepayTrackPaymentsDates = dispatch => (payload) => {
  dispatch(updateRepayTrackPaymentDatesAction(payload));
};

const fetchMonthlyPaymentDetails = dispatch => () => {
  dispatch(fetchMonthlyPaymentDetailsAction());
};

const updateMonthlyPaymentDetails = dispatch => (key, value) => {
  dispatch(updateMonthlyPaymentDetailsAction(key, value));
};

const ruleCheckOperation = dispatch => () => {
  dispatch(ruleCheckAction());
};

const lockCalculationOperation = dispatch => (payload) => {
  dispatch(lockCalculationAction(payload));
};

const enableCalc = dispatch => (value) => {
  dispatch(enableCalcAction(value));
};

const paymentChangesOperation = dispatch => (payload) => {
  dispatch(fetchPaymentChangesDetailsAction(payload));
};

const sendForApprovalOperation = dispatch => (payload) => {
  dispatch(sendForApprovalAction(payload));
};

const sendForApproval = dispatch => (payload) => {
  dispatch(approvalAction(payload));
};

const fetchResolutionRejectReasonData = dispatch => (payload) => {
  dispatch(fetchResolutionRejectReasonAction(payload));
};

const setResolutionRejectReasonData = dispatch => (payload) => {
  dispatch(setResolutionRejectReasonAction(payload));
};

const rejectCaseRulesOperation = dispatch => (payload) => {
  dispatch(rejectCaseRulesAction(payload));
};

const calcRepayOperation = dispatch => (payload) => {
  dispatch(calcRepayAction(payload));
};


const operations = {
  enableCalc,
  lockCalculationOperation,
  ruleCheckOperation,
  updateRepayTrackPaymentsDates,
  updateMonthlyPaymentDetails,
  fetchMonthlyPaymentDetails,
  paymentChangesOperation,
  sendForApprovalOperation,
  sendForApproval,
  rejectCaseRulesOperation,
  fetchResolutionRejectReasonData,
  setResolutionRejectReasonData,
  calcRepayOperation,
};

export default operations;
