import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropdown from 'components/Eval/Dropdown/Dropdown';
import SweetAlertBox from 'components/SweetAlertBox';
import operations from 'ducks/eval/operations';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import './CollateralAcceptance.css';
import processCollateralData from '../../../../lib/CustomFunctions/Eval/mapCollateralAcceptance';

const CollateralAcceptance = (props) => {
  const {
    submitCollateralAcceptance, resultOperation, resetResultOperation, collateralData, isEditable,
    groups,
  } = props;
  const [option, setOption] = useState(collateralData.collateralAcceptanceType == null
    ? '' : collateralData.collateralAcceptanceType);
  const [options, setOptions] = useState(processCollateralData(
    collateralData.availableCollateralAcceptanceActions,
  ));
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [collateralDisable, setCollateralDisable] = useState(true);

  useEffect(() => {
    const requiredGroups = ['PROC', 'FEUW', 'BEUW', 'DOCGEN', 'DOCSIN', 'POSTMOD', 'INVSET'];
    if (groups
      && requiredGroups.every(requiredGroup => groups.includes(requiredGroup.toUpperCase()))) {
      setCollateralDisable(false);
    } else {
      setCollateralDisable(true);
    }
  }, [groups]);

  const handleSubmit = () => {
    submitCollateralAcceptance({ option });
  };

  useEffect(() => {
    setOptions(processCollateralData(collateralData.availableCollateralAcceptanceActions));
  }, [collateralData]);

  useEffect(() => {
    if (option !== collateralData.collateralAcceptanceType
      && option !== '') {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [option, collateralData]);

  return (
    <div styleName="collateralDiv">
      <Typography styleName="header">Collateral Acceptance:</Typography>
      <div styleName="optionDiv">
        <Dropdown
          defaultValue=""
          disabled={collateralDisable}
          displayEmpty
          handleSelect={(columnName, value) => { setOption(value); }}
          label=""
          options={options || []}
          override={false}
          value={option}
        />
      </div>
      <Button
        color="primary"
        disabled={disableSubmit || !isEditable}
        onClick={handleSubmit}
        styleName="submitBtn"
        variant="contained"
      >
        SUBMIT
      </Button>
      {resultOperation && resultOperation.status && resultOperation.saga === 'CollateralAcceptance' && (
      <SweetAlertBox
        message={resultOperation.status}
        onConfirm={() => {
          resetResultOperation();
        }}
        show={resultOperation.isOpen}
        type={resultOperation.level}
      />
      )}
    </div>
  );
};

CollateralAcceptance.defaultProps = {
  isEditable: true,
  submitCollateralAcceptance: () => {},
};

CollateralAcceptance.propTypes = {
  collateralData: PropTypes.shape().isRequired,
  groups: PropTypes.arrayOf(PropTypes.string),
  isEditable: PropTypes.bool,
  resetResultOperation: PropTypes.func.isRequired,
  resultOperation: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    saga: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  submitCollateralAcceptance: PropTypes.func,
};

const mapStateToProps = state => ({
  resultOperation: dashboardSelectors.resultOperation(state),
});

const mapDispatchToProps = dispatch => ({
  submitCollateralAcceptance: operations.submitCollateralAcceptanceOpn(dispatch),
  resetResultOperation: dashboardOperations.resetResultOperationOperation(dispatch),
});

CollateralAcceptance.defaultProps = {
  submitCollateralAcceptance: () => {},
  groups: [],
};

const CollateralAcceptanceComp = connect(mapStateToProps, mapDispatchToProps)(CollateralAcceptance);

export default CollateralAcceptanceComp;
