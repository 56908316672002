import React, { useState, useEffect } from 'react';
import EvalTable from 'components/Eval/EvalTable';
import Button from '@material-ui/core/Button';
import * as R from 'ramda';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './WaterFallSection.css';
import EvalHeader from 'components/Eval/EvalHeader';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { selectors as loginSelectors } from 'ducks/login';
import { operations as evalOperations } from 'ducks/eval';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { connect } from 'react-redux';
import { SECONDLOOK } from 'constants/Groups';
import CollateralAcceptance from './CollateralAcceptance/CollateralAcceptance';
import FinancialSection from '../FinancialSection';
import StreamlineModOffer from './StreamlineModOffer/StreamlineModOffer';
import {
  VA_STREAMLINE_TRIAL, MOD_OFFER_LOAN_TYPES, MOD_OFFER_INV_GRPS, ACTIVE, APPROVED,
} from '../../../constants/eval';

const WaterFallSection = (props) => {
  const {
    user, loanType, evalScreenData, isEditable, isWaterFallEditable,
    moveCaseSeq,
    removeWaterfallOperation,
  } = props;

  const {
    evalCase, evalHeader, waterFallInfo, collateralAcceptance, caseInfo, pvmId,
  } = evalScreenData;

  const [data, setData] = useState([]);
  const groups = user && user.groupList ? user.groupList : [];
  const evalStatus = R.pathOr('', ['status'], evalCase);

  useEffect(() => {
    if (!R.isEmpty(evalScreenData) && !R.isNil(waterFallInfo)
    && !R.isNil(waterFallInfo.investorWaterfallDetailsList)) {
      const singleWtfall = waterFallInfo.investorWaterfallDetailsList.length === 1;
      const updatedList = waterFallInfo.investorWaterfallDetailsList
        .map((item, index) => {
          const newItem = { ...item, singleWtfall };
          return index === 0 ? { ...newItem, isFirstRec: 'YES' } : newItem;
        });
      setData(updatedList);
    }
  }, [evalScreenData]);

  const removeWaterfall = (selectedRow) => {
    const caseData = !R.isNil(waterFallInfo.investorWaterfallDetailsList)
    && waterFallInfo.investorWaterfallDetailsList
      .find(row => row.sequenceNumber === selectedRow.sequenceNumber);
    removeWaterfallOperation(caseData);
  };

  const [columns, setColumns] = useState([{
    align: '',
    label: 'Seq',
    minWidthHead: '',
    name: 'sequenceNumber',
    options: {},
  }, {
    align: '',
    label: 'Case Type',
    minWidthHead: '',
    name: 'caseType',
    options: {},
  }, {
    align: '',
    label: 'Case ID',
    minWidthHead: '',
    name: 'caseId',
    options: {},
  }, {
    align: '',
    label: 'Action',
    minWidthHead: '',
    name: 'actionCode',
    options: {},
  }, {
    align: '',
    label: 'Reason',
    minWidthHead: '',
    name: 'reasonCode',
    options: {},
  }, {
    align: '',
    label: 'Date',
    minWidthHead: '',
    name: 'modDate',
    iconCheck: 'Yes',
    cellFormat: item => (item && item.modDate ? (moment(item.modDate).format('DD MMM YYYY')) : <>{null}</>),
    options: {},
  }, {
    align: '',
    label: '',
    minWidthHead: '',
    name: '',
    sort: 'YES',
    iconCheck: 'Yes',
    cellFormat: item => (
      <div styleName="items">
        <KeyboardArrowUpIcon
          fontSize="small"
          onClick={() => moveCaseSeq({ ...item, direction: -1 })}
          style={{ cursor: isWaterFallEditable ? 'pointer' : '', visibility: !item.isFirstRec ? 'visible' : 'hidden' }}
          styleName="upIcon"
        />
        <Button
          disabled={!(groups.includes(SECONDLOOK)
            ? isWaterFallEditable : (isWaterFallEditable
              && (evalStatus === ACTIVE || evalStatus === APPROVED)))}
          onClick={() => removeWaterfall(item)}
          style={{ visibility: !item.singleWtfall ? 'visible' : 'hidden' }}
          styleName="removeButton"
          type="button"
        >
          Remove from waterfall
        </Button>
      </div>
    ),
    options: {},
  }]);

  const {
    evalId, evalType, createdOn, status, subStatus, lastDocumentReceivedOn,
  } = evalHeader || {};
  const displayDisasterVal = (evalType && evalType === 'Disaster') && !R.isNil(R.path(['currentDueDisasterVal'], evalCase));
  const headerData = {
    evalCaseHeader: true,
    evalCaseType: evalType,
    createdOn,
    status,
    subStatus,
    lockedFlag: 0,
    data: {
      caseId: evalId,
    },
    evalHeader,
    createBy: evalCase ? evalCase.createBy : '',
    caseInfo,
  };

  const renderStreamlineModOffer = () => {
    const isVAStreamlineTrial = data && data.some(rec => rec.caseType === VA_STREAMLINE_TRIAL);
    if (evalCase.preApprovedFlag === 1 && ((headerData.status === 'Closed' && isVAStreamlineTrial) || ((MOD_OFFER_INV_GRPS.includes(waterFallInfo.waterFallName) && !(MOD_OFFER_LOAN_TYPES.includes(loanType)))))) {
      return (
        <StreamlineModOffer
          evalStatus={headerData.status}
          groups={groups}
          isEditable={isEditable}
        />
      );
    }
    return null;
  };
  return (
    <div styleName="container">
      <EvalHeader headerData={headerData} isEditable={isEditable} />
      <div styleName="section1">
        <div styleName="grey-bg">
          <div styleName="table-header">
            <h2>
              {!R.isNil(evalScreenData.waterFallInfo)
            && evalScreenData.waterFallInfo.waterFallName}
            </h2>
            <p>
Last document received on
              {' '}
              {lastDocumentReceivedOn}
            </p>
          </div>
          <EvalTable
            columns={columns}
            data={data}
            setColumns={setColumns}
            setData={setData}
          />
        </div>
        <div styleName="section2">
          <FinancialSection
            isEditable={isEditable}
            isEvalActive={evalStatus === ACTIVE}
            isEvalLandingPage
            pvmId={pvmId}
          />
        </div>
      </div>
      <div styleName="section3">
        {displayDisasterVal
            && (
            <span styleName="section3-span">
              {'Current due at the time of disaster: '}
              <strong>{evalCase.currentDueDisasterVal}</strong>
            </span>
            )
        }
        {!R.isEmpty(R.pathOr([], ['availableCollateralAcceptanceActions'], collateralAcceptance))
        && (
        <div styleName="collateral-section">
          <CollateralAcceptance
            collateralData={collateralAcceptance}
            groups={groups}
            isEditable={isEditable}
          />
        </div>
        )
        }
        <div styleName="streamline-section">
          {renderStreamlineModOffer()}
        </div>
      </div>
    </div>

  );
};

WaterFallSection.defaultProps = {
  evalHeader: {},
  isEditable: true,
  isWaterFallEditable: true,
};

WaterFallSection.propTypes = {
  evalHeader: PropTypes.shape({
    createdBy: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    evalHeader: PropTypes.shape({}),
    evalId: PropTypes.string.isRequired,
    evalType: PropTypes.string.isRequired,
    lastDocumentReceivedOn: PropTypes.string,
    status: PropTypes.string.isRequired,
    subStatus: PropTypes.string.isRequired,
  }),
  evalScreenData: PropTypes.shape().isRequired,
  isEditable: PropTypes.bool,
  isWaterFallEditable: PropTypes.bool,
  loanType: PropTypes.number.isRequired,
  moveCaseSeq: PropTypes.func.isRequired,
  removeWaterfallOperation: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  loanType: dashboardSelectors.getLoanType(state),
});

const mapDispatchToProps = dispatch => ({
  moveCaseSeq: evalOperations.moveCaseSeqOperation(dispatch),
  removeWaterfallOperation: evalOperations.removeWaterfallOperation(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(WaterFallSection);
