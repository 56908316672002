import React from 'react';
import * as R from 'ramda';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import './RepaymentTotalAmount.css';
import { convertToUSCurrency } from 'lib/Formatters';

const RepaymentTotalAmount = ({ setIsRepaymentSectionOpened, monthlyPaymentDetails }) => {
  const [isRepaymentSplitOpened, toggleRepaymentScreen] = React.useState(false);
  const { repaymentTotalAmount } = monthlyPaymentDetails;
  return (
    <Grid id="repaymentTotalAmount" item xs={4}>
      <Paper
        elevation={2}
        square
        styleName={
          isRepaymentSplitOpened
            ? 'repaymentTotalAmount-opened'
            : 'repaymentTotalAmount-closed'
        }
      >
        <div styleName="displayInColumn">
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item>
              <div styleName="card-header">
                Repayment Total Amount
              </div>
            </Grid>
            <Grid item>
              {!isRepaymentSplitOpened ? (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    toggleRepaymentScreen(!isRepaymentSplitOpened);
                    setIsRepaymentSectionOpened(!isRepaymentSplitOpened);
                  }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => {
                    toggleRepaymentScreen(!isRepaymentSplitOpened);
                    setIsRepaymentSectionOpened(!isRepaymentSplitOpened);
                  }}
                />
              )}
            </Grid>
          </Grid>
          <div styleName="repaymentTotalAmountValue">{convertToUSCurrency.format(R.pathOr(0, ['columnVal'], repaymentTotalAmount))}</div>
        </div>
      </Paper>
    </Grid>
  );
};

RepaymentTotalAmount.propTypes = {
  monthlyPaymentDetails: PropTypes.shape().isRequired,
  setIsRepaymentSectionOpened: PropTypes.func.isRequired,
};

export default RepaymentTotalAmount;
