/* eslint-disable no-restricted-syntax */
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import RouteAccess from 'lib/RouteAccess';
import { withRouter } from 'react-router-dom';
import DashboardModel from 'models/Dashboard';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import { unStagerReject, docGenStagerReject } from 'components/StagerConstants/StagerConstant';
import { ACCOUNT_SERVICE_EVENT_TYPES, RESEARCH_REQUESTED, STAGER } from 'constants/common';
import { operations as accountServiceOperations, selectors as accountServiceSelectors } from 'ducks/accountService';
import UITaskGenerator from 'components/UITaskGenerator';
import accountServiceTemplate from 'constants/AccountService/accountService';
import Loader from 'components/Loader/Loader';
import { operations as tombstoneOperations } from 'ducks/tombstone';
import { operations, selectors } from '../../state/ducks/dashboard';
import { operations as checkListOperations } from '../../state/ducks/tasks-and-checklist';
import { selectors as stagerSelectors } from '../../state/ducks/stager';
import ConfirmationDialogBox from '../Tasks/OptionalTask/ConfirmationDialogBox';
import './CustomReactTable.css';

const handleRowValue = value => (value.startsWith('cmod') ? 'Unassign' : value);

class CustomReactTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getCheckBox = this.getCheckBox.bind(this);
  }

  onSelectAllOption(checked) {
    const { onSelectAll, activeSearchTerm } = this.props;
    let selection = R.map(R.prop(''), this.table.getResolvedState().sortedData);
    if (activeSearchTerm === 'ValueOrdered') {
      selection = selection.filter(x => R.propOr('', 'Investor Name', x) === 'Freddie');
    }
    onSelectAll(checked, selection);
  }

  static getRowStyleName(value, pointerStyle) {
    if (value < 0) {
      return `${pointerStyle} days-until-sla-red`;
    }
    if (value === 0) {
      return `${pointerStyle} days-until-sla-gray`;
    }
    return `${pointerStyle} tableRow`;
  }

  static getCellContent(row, stagerTaskType, stagerTaskStatus) {
    const pointerStyle = (DashboardModel.POSTMOD_TASKNAMES.includes(stagerTaskType)
    || DashboardModel.UWSTAGER_TASKNAMES.includes(stagerTaskType)) && stagerTaskStatus !== 'Completed' ? 'pointer' : '';
    switch (row.column.id) {
      case 'Days Until SLA':
        return (
          <div styleName={this.getRowStyleName(row.value, pointerStyle)}>
            {`${row.value} ${Math.abs(row.value) > 1 ? 'DAYS' : 'DAY'}`}
          </div>
        );
      case 'Loan Number':
        return (
          <div styleName={this.getRowStyleName(row.original['Days Until SLA'], pointerStyle)}>
            {this.getRowStyleName(row.original['Days Until SLA']) === 'days-until-sla-red'
              ? <img alt="alert-icon" src="/static/img/esclamation.svg" /> : null
            }
            {this.getRowStyleName(row.original['Days Until SLA']) === 'days-until-sla-gray'
              ? <img alt="alert-icon" src="/static/img/warning.svg" /> : null
            }
            {`  ${row.value}`}
          </div>
        );
      case 'Assigned To':
        return (
          <div styleName={`${pointerStyle} tableRow`}>
            {handleRowValue(row.value)}
          </div>
        );
      case 'taskCheckListTemplateName':
        return (
          <div style={{ display: 'none' }} styleName={pointerStyle}>
            {`  ${row.value}`}
          </div>
        );
      default:
        return (
          <div>
            <Tooltip title={<h2>{row.value}</h2>}>
              <div styleName={`${pointerStyle} tableRow`}>
                {row.value}
              </div>
            </Tooltip>
          </div>
        );
    }
  }

  getGroups() {
    const { user } = this.props;
    return user && user.groupList;
  }

  getLoanActivityPath() {
    const { user } = this.props;
    const groups = user && user.groupList;
    return RouteAccess.hasLoanActivityAccess(groups) ? '/loan-activity' : '/';
  }

  getCheckBox() {
    const {
      onCheckBoxClick, selectedData, activeSearchTerm, data,
    } = this.props;
    return {
      accessor: '',
      Cell: ({ original }) => {
        const isSelected = selectedData.find(o => o.TKIID === original.TKIID) || false;
        const shouldShowCheckbox = activeSearchTerm !== 'ValueOrdered' || original['Investor Name'] === 'Freddie';
        return (shouldShowCheckbox
          ? (
            <Checkbox
              checked={isSelected}
              onChange={e => onCheckBoxClick(e.target.checked, original)}
              styleName="checkbox"
            />
          ) : false
        );
      },
      Header: () => (
        ((activeSearchTerm !== 'ValueOrdered') || R.any(x => R.propOr('', 'Investor Name', x) === 'Freddie', data.tableData))
          ? <Checkbox onChange={e => this.onSelectAllOption(e.target.checked)} styleName="checkboxHeader" />
          : false
      ),
      sortable: false,
      filterable: false,
      width: 50,
    };
  }

  getColumnData(stagerTaskType, stagerTaskStatus, isManualOrder, data) {
    const { activeSearchTerm, getStagerValue } = this.props;
    const columnData = [];
    const columnObject = {};
    let columns = [];
    let columnsToBeRemoved = ['TKIID'];
    if (activeSearchTerm === 'NPVOrdered') {
      columnsToBeRemoved = [...columnsToBeRemoved, 'Assigned To', 'Milestone', 'Completion Date', 'Days Until SLA'];
    }
    if (data && data[0]) {
      columns = R.compose(
        R.map((columnName) => {
          const columnObj = {};
          columnObj.Header = (
            <div styleName="tableHeader">
              {columnName.toUpperCase()}
            </div>
          );
          const columnWidth = columnName === 'Trial Paid Dates' ? 450 : 160;
          columnObj.minWidth = columnWidth;
          columnObj.accessor = columnName;
          // columnObj.show = this.showColumns(columnName);
          columnObj.Cell = row => this.constructor.getCellContent(
            row, stagerTaskType, stagerTaskStatus,
          );
          columnObj.filterMethod = (filter, row) => row[filter.id]
          && row[filter.id].toString() === filter.value;
          const dropDownValues = R.without(['', null], R.uniq(data.map(dataUnit => dataUnit[columnName])));
          columnObj.Filter = ({ filter, onChange }) => (
            <select
              onChange={event => onChange(event.target.value)}
              styleName="filterDropDown"
              value={filter ? filter.value : 'all'}
            >
              <option value="">{}</option>
              {dropDownValues.map(value => <option value={value}>{value}</option>)}
            </select>
          );
          return columnObj;
        }),
        R.without(['', null, ...columnsToBeRemoved]),
        R.keys(),
      )(data[0]);
    }
    const npvAllowedGroups = ['beuw-mgr', 'stager-mgr', 'rpsstager-mgr', 'rshstager-mgr'];
    columnObject.columns = (isManualOrder || (activeSearchTerm === 'ValueOrdered')
    || ((getStagerValue === 'UW_STAGER') && (unStagerReject.includes(activeSearchTerm)))
    || ((getStagerValue === 'DOCGEN_STAGER') && (docGenStagerReject.includes(activeSearchTerm)))
    || ((getStagerValue === 'DOCGEN_STAGER') && ((stagerTaskStatus === 'To Order') && (stagerTaskType === 'Reclass')))
    || ((getStagerValue === 'DOCGEN_STAGER') && ((stagerTaskStatus === 'Order') && (stagerTaskType === 'Reclass')))
    || ((getStagerValue === 'DOCGEN_STAGER') && ((stagerTaskStatus === 'To Order') && (stagerTaskType === 'Current Review')))
    || ((getStagerValue === 'DOCGEN_STAGER') && ((stagerTaskStatus === 'Order') && (stagerTaskType === 'Current Review'))))
    || (activeSearchTerm === 'NPVOrdered' && npvAllowedGroups.some(field => this.getGroups().includes(field))) ? [this.getCheckBox(data),
        ...columns] : columns;
    columnData.push(columnObject);
    return columnData;
  }

  getTrPropsType = (state, rowInfo, column, instance, stagerTaskType, stagerTaskStatus) => {
    const { searchResponse } = this.props;
    const hasEscrowAccess = this.getGroups().includes('escrow')
    || this.getGroups().includes('escrow-mgr');
    if (rowInfo) {
      const { original } = rowInfo;
      const style = {};
      if (original['Loan Number'] === searchResponse) {
        style.background = '#e67300';
      }
      if (!(original['Capmod ID'] === null || original['Capmod ID'] === '') && stagerTaskType === DashboardModel.ESCROW
      && ((stagerTaskStatus === STAGER.ORDERED && original[RESEARCH_REQUESTED] === 'Yes' && hasEscrowAccess) || (stagerTaskStatus === STAGER.COMPLETED && hasEscrowAccess && original['Capmod ID'] != null))) {
        style.cursor = 'pointer';
      }
      return {
        style,
        onClick: (event) => {
          this.handleRowClick(rowInfo, event, stagerTaskType, stagerTaskStatus);
          instance.forceUpdate();
        },
      };
    }
    return {};
  }


  // as of now it is not needed
  showColumns(columnName) {
    const { data: { stagerTaskType } } = this.props;
    return columnName === 'Assigned To' ? (DashboardModel.POSTMOD_TASKNAMES.includes(stagerTaskType)) : true;
  }

  handleRowClick(rowInfo, event, stagerTaskType, stagerTaskStatus) {
    if (event.target.type === 'checkbox') {
      return;
    }
    const {
      data,
      setLoanInfoFromStager, fetchTombstoneData,
      setStagerTaskName,
      toggleAccountServiceScreen,
      getCapModHistory,
      getCapModHistoryForLoan,
      onSearchLoanWithTask,
      setBeginSearch,
    } = this.props;

    const { original } = rowInfo;

    const hasEscrowAccess = this.getGroups().includes('escrow')
    || this.getGroups().includes('escrow-mgr');

    const hasStagerAccess = this.getGroups().includes('stager')
    || this.getGroups().includes('stager-mgr');

    const delayChecklistCheck = (DashboardModel.POSTMOD_TASKNAMES.includes(data.stagerTaskType)
    || DashboardModel.UWSTAGER_TASKNAMES.includes(data.stagerTaskType)) && stagerTaskStatus !== 'Completed';

    const escrowCheck = R.equals(stagerTaskStatus, STAGER.ORDERED) && rowInfo.original
    && hasEscrowAccess && R.equals('Yes', R.propOr('', RESEARCH_REQUESTED, rowInfo.original));

    if (R.equals(data.stagerTaskType, DashboardModel.ESCROW)) {
      setLoanInfoFromStager(R.propOr(0, 'Loan Number', rowInfo.original), R.propOr(0, 'Eval ID', rowInfo.original), R.propOr(0, 'TKIID', rowInfo.original));

      const payload = {
        rowData: original,
        assignTask: true,
      };

      const taskNamePayload = { activeTab: stagerTaskStatus, activeTile: stagerTaskType };

      if (R.equals(stagerTaskStatus, STAGER.TO_ORDER) && hasStagerAccess) {
        setStagerTaskName(taskNamePayload);
        fetchTombstoneData(payload);
      }
      if (escrowCheck && !((R.propOr(0, 'Capmod ID', rowInfo.original) === null || R.propOr(0, 'Capmod ID', rowInfo.original) === ''))) {
        setStagerTaskName(taskNamePayload);
        fetchTombstoneData(payload);
      }
      if (R.equals(stagerTaskStatus, STAGER.COMPLETED) && hasStagerAccess && !((R.propOr(0, 'Capmod ID', rowInfo.original) === null || R.propOr(0, 'Capmod ID', rowInfo.original) === ''))) {
        setStagerTaskName(taskNamePayload);
        fetchTombstoneData({ ...payload, assignTask: false });
        getCapModHistoryForLoan();
        getCapModHistory(R.propOr(0, 'Capmod ID', rowInfo.original));
        toggleAccountServiceScreen(true);
      }
    } else if ((DashboardModel.POSTMOD_TASKNAMES.includes(data.stagerTaskType)
    || DashboardModel.UWSTAGER_TASKNAMES.includes(data.stagerTaskType))
    && (stagerTaskStatus !== 'Completed' || DashboardModel.ALLOW_COMPLETED_TASK.includes(data.stagerTaskType))) {
      const payload = { activeTab: stagerTaskStatus, activeTile: stagerTaskType };
      setStagerTaskName(payload);
      const searchPayload = {
        rowData: original,
        loadSearchedLoan: () => this.loadSearchLoan(),
      };
      onSearchLoanWithTask(searchPayload);
      setBeginSearch();
    }
    if (delayChecklistCheck) {
      this.redirectToPersona(stagerTaskStatus, stagerTaskType, rowInfo);
    }
  }

  redirectToPersona(stagerTaskStatus, stagerTaskType, rowInfo) {
    const {
      onSearchLoanWithTask, setStagerTaskName, setBeginSearch,
    } = this.props;
    const { original } = rowInfo;
    const payload = { activeTab: stagerTaskStatus, activeTile: stagerTaskType };
    setStagerTaskName(payload);
    const searchPayload = {
      rowData: original,
      loadSearchedLoan: () => this.loadSearchLoan(rowInfo),
    };
    onSearchLoanWithTask(searchPayload);
    setBeginSearch();
  }

  async loadSearchLoan() {
    const {
      onSelectEval, onGetGroupName, onGetChecklistHistory,
      history, searchLoanTaskResponse, setChecklistCenterPaneData, data,
    } = this.props;
    if (searchLoanTaskResponse) {
      let group = '';
      if (DashboardModel.POSTMOD_TASKNAMES.includes(searchLoanTaskResponse.taskName)) {
        group = 'POSTMOD';
        this.redirectPath = '/postmodstager';
      } else if (DashboardModel.UWSTAGER_TASKNAMES.includes(searchLoanTaskResponse.taskName)) {
        group = 'UWSTAGER';
        this.redirectPath = '/uwstager';
      } else {
        this.redirectPath = '/frontend-checklist';
        group = 'FEUW';
      }
      onGetGroupName(group);
      const payload = {
        ...searchLoanTaskResponse,
        isSearch: true,
        evalId: searchLoanTaskResponse['Eval ID'],
        loanNumber: searchLoanTaskResponse['Loan Number'],
        taskId: searchLoanTaskResponse.TKIID,
        piid: searchLoanTaskResponse.PID,
        pStatus: 'Active',
        tStatus: searchLoanTaskResponse.taskStatus,
        processName: searchLoanTaskResponse.taskName,
        taskIterationCounter: searchLoanTaskResponse.taskIterationCounter,
        assignee: searchLoanTaskResponse['Assigned To'],
      };
      onSelectEval(payload);
      onGetChecklistHistory(searchLoanTaskResponse.TKIID);
      await history.push(this.redirectPath);
      if (data && data.stagerTaskType === DashboardModel.NPV) {
        setChecklistCenterPaneData('NPVResults');
      }
    }
  }

  render() {
    const {
      data, processActionCommand, escrowData, showDialog, onChange, showConfirmationDialog,
      handleConfirmDialogBoxButtons, onAutoSave, showLoaderInPopup,
    } = this.props;
    const returnVal = data ? (
      <div styleName="stager-table-container">
        <div styleName="stager-table-height-limiter">
          <ReactTable
            ref={(reactTable) => {
              this.table = reactTable;
            }}
            className="-highlight"
            columns={this.getColumnData(data.stagerTaskType,
              data.stagerTaskStatus, data.isManualOrder, data.tableData)}
            data={data.tableData}
            defaultPageSize={100}
            defaultSorted={data.defaultSorted ? [
              {
                id: data.defaultSorted,
                asc: true,
              },
            ] : []}
            filterable
            getTdProps={(
              state, rowInfo, column, instance,
            ) => this.getTrPropsType(
              state, rowInfo, column, instance, data.stagerTaskType, data.stagerTaskStatus,
            )}
            styleName="stagerTable"
          />
          {showDialog && (
          <Dialog onClose={() => {}} open={() => {}}>
            {
              showLoaderInPopup ? (
                <div styleName="loanDetailsPopupLoader">
                  <Loader message="Please Wait" />
                </div>
              )
                : (
                  <UITaskGenerator
                    checklistItems={accountServiceTemplate.loanDetailsTemplate}
                    escrowData={escrowData[ACCOUNT_SERVICE_EVENT_TYPES.ESCROW_TO_ORDER]}
                    onChange={onChange}
                    processAction={processActionCommand}
                  />
                )
            }
          </Dialog>
          )}
          <ConfirmationDialogBox
            isOpen={showConfirmationDialog}
            message=""
            onClose={(value) => {
              handleConfirmDialogBoxButtons(value);
              if (value) {
                onAutoSave('Paused');
              }
            }}
            title="Are you sure you want to close the page?"
          />
        </div>
      </div>
    ) : null;
    return returnVal;
  }
}

const TestExports = {
  CustomReactTable,
};
CustomReactTable.defaultProps = {
  data: [],
};

const mapDispatchToProps = dispatch => ({
  onSelectEval: operations.onSelectEval(dispatch),
  onGetGroupName: operations.onGetGroupName(dispatch),
  setStagerTaskName: operations.setStagerTaskName(dispatch),
  onSearchLoanWithTask: operations.onSearchLoanWithTask(dispatch),
  setBeginSearch: operations.setBeginSearch(dispatch),
  onGetChecklistHistory: checkListOperations.fetchHistoricalChecklistData(dispatch),
  processActionCommand: checkListOperations.preProcessChecklistItems(dispatch),
  setLoanInfoFromStager: accountServiceOperations.setLoanInfoFromStager(dispatch),
  onChange: accountServiceOperations.onValueChange(dispatch),
  handleConfirmDialogBoxButtons: accountServiceOperations.handleConfirmDialogBoxButtons(dispatch),
  fetchTombstoneData: accountServiceOperations.fetchTombstoneData(dispatch),
  toggleAccountServiceScreen: accountServiceOperations.toggleAccountServiceScreen(dispatch),
  getCapModHistory: accountServiceOperations.getCapModHistoryOperation(dispatch),
  getCapModHistoryForLoan: accountServiceOperations.storeAccountServicesHistoryOperation(dispatch),
  onAutoSave: operations.onAutoSave(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
});

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  searchLoanTaskResponse: selectors.searchLoanTaskResponse(state),
  activeSearchTerm: stagerSelectors.getActiveSearchTerm(state),
  showDialog: accountServiceSelectors.showDialog(state),
  escrowData: accountServiceSelectors.getEscrowData(state),
  showConfirmationDialog: accountServiceSelectors.showConfirmationDialog(state),
  showLoaderInPopup: accountServiceSelectors.showLoaderInPopup(state),
  getStagerValue: stagerSelectors.getStagerValue(state),
});

CustomReactTable.propTypes = {
  activeSearchTerm: PropTypes.string.isRequired,
  data: PropTypes.shape(),
  escrowData: PropTypes.shape.isRequired,
  fetchTombstoneData: PropTypes.func.isRequired,
  getCapModHistory: PropTypes.func.isRequired,
  getCapModHistoryForLoan: PropTypes.func.isRequired,
  getStagerValue: PropTypes.func.isRequired,
  handleConfirmDialogBoxButtons: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAutoSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  onGetChecklistHistory: PropTypes.func.isRequired,
  onGetGroupName: PropTypes.func.isRequired,
  onSearchLoanWithTask: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSelectEval: PropTypes.func.isRequired,
  processActionCommand: PropTypes.func.isRequired,
  searchLoanTaskResponse: PropTypes.shape().isRequired,
  searchResponse: PropTypes.string.isRequired,
  selectedData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setBeginSearch: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setLoanInfoFromStager: PropTypes.func.isRequired,
  setStagerTaskName: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  showDialog: PropTypes.bool.isRequired,
  showLoaderInPopup: PropTypes.bool.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const CustomReactTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomReactTable);

export default withRouter(CustomReactTableContainer);
export { TestExports };
