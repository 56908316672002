import React, { useEffect, useState } from 'react';
import EvalTable from 'components/Eval/EvalTable';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import './NewCases.css';
import { operations as tombstoneOperations } from 'ducks/tombstone';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { selectors as loginSelectors } from 'ducks/login';
import * as R from 'ramda';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/Loader';
import {
  WaterFallSegregation, FORBEARANCE_WORKOUT_TYPE, OPEN, CASE_CREATION_AD_GROUPS,
} from '../../../constants/eval';
import { CASE_DETAILS, REPAYMENT_PLAN_CASE } from '../../../constants/loanInfoComponents';
import { operations, selectors as evalSelectors } from '../../../state/ducks/eval';
import { selectors as dashboardSelectors, operations as dashboardOperations } from '../../../state/ducks/dashboard';

const useStyles = makeStyles({
  // root: {
  //   display: 'flex',
  // },
  item: {
    '&:hover': {
      backgroundColor: '#F3F5F9',
      color: '#4E586E',
      // '& .MuiListItemIcon-root': {
      //   color: 'white',
      // },
    },
  },
  selected: {},
});
const NewCases = (props) => {
  const classes = useStyles();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedCase, setSelectedCase] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    user, setChecklistCenterPaneData, getCaseHeaderInfo, evalScreenData,
    fetchCaseTypes, createButtonStatus,
    setSelectedCaseId, addCaseToWaterfallSeq, setSelectedCaseDetail, isEditable,
    getModStatus,
    caseTypesData, getCaseData, fetchWorkoutTypes, workoutTypes,
    isworkOutTypeLoading, setRepaymentCaseId, setRuleEngineError,
    setModCalcIndicator,
  } = props;


  const groups = user && user.groupList;
  const segregationGroupLower = [];
  WaterFallSegregation.map(ele => segregationGroupLower.push(ele.toLocaleLowerCase()));
  const [data, setData] = useState([]);
  const createNewCaseAdGroups = groups.some(group => CASE_CREATION_AD_GROUPS.includes(group));

  useEffect(() => {
    fetchCaseTypes();
  }, []);

  useEffect(() => {
    if (!R.isEmpty(evalScreenData) && !R.isNil(evalScreenData.caseInfo)) {
      setData(evalScreenData.caseInfo);
    }
  }, [evalScreenData]);

  useEffect(() => {
    const { createNewCase } = props;
    if (!R.isEmpty(selectedCase) && !R.isNil(selectedCase) && workoutTypes.length === 0) {
      setAnchorEl(null);
      setIsPopupVisible(false);
      createNewCase({ workoutType: '', selectedCaseType: selectedCase });
    }
  }, [workoutTypes]);

  const handleClick = (value) => {
    setModCalcIndicator(true);
    setRuleEngineError(null);
    setSelectedCaseId(value.caseId);
    setSelectedCaseDetail(value);
    getCaseHeaderInfo(value);
    if (value.workoutType !== FORBEARANCE_WORKOUT_TYPE) {
      setChecklistCenterPaneData(CASE_DETAILS);
    } else {
      setRepaymentCaseId(value.caseId);
      setChecklistCenterPaneData(REPAYMENT_PLAN_CASE);
    }
  };


  const handleCaseTypeDropDown = (value) => {
    setSelectedCase(value);
    const caseData = R.find(R.propEq('caseType', value))(getCaseData);
    fetchWorkoutTypes(caseData.resolutionTypeId);
  };

  const handleWaterfallAddition = (value) => {
    addCaseToWaterfallSeq({ caseId: value.caseId });
  };

  const canAddtoWtfall = (caseDtl) => {
    if (caseDtl.status === OPEN) {
      if (evalScreenData.waterFallInfo) {
        const { waterFallInfo } = evalScreenData;
        if (waterFallInfo.investorWaterfallDetailsList) {
          const exists = R.any(item => item.caseId === caseDtl.caseId,
            waterFallInfo.investorWaterfallDetailsList);
          return !exists;
        } return true;
      }
      return true;
    }
    return false;
  };

  const columns = [{
    align: '',
    label: 'Case ID',
    minWidthHead: '',
    name: 'caseId',
    options: {},
    cellFormat(singleValue, value) {
      return (
        <Button
          onClick={() => handleClick(value)}
          styleName="addButton caseId-link"
          type="text"
        >
          {value[this.name]}
        </Button>
      );
    },
  },
  {
    align: '',
    label: 'Created On',
    minWidthHead: '',
    name: 'createdOn',
    options: {},
  },
  {
    align: '',
    label: 'Parent ID',
    minWidthHead: '',
    name: 'parentId',
    options: {},
  },
  {
    align: '',
    label: 'Case Type',
    minWidthHead: '',
    name: 'caseType',
    options: {},
  },
  {
    align: '',
    label: 'Investor Case Type',
    minWidthHead: '',
    name: 'investorCaseType',
    options: {},
  },
  {
    align: '',
    label: 'Status',
    minWidthHead: '',
    name: 'status',
    options: {},
  },
  {
    align: '',
    label: 'Status Date',
    minWidthHead: '',
    name: 'statusDate',
    options: {},
  },
  {
    align: '',
    label: 'Sub Status',
    minWidthHead: '',
    name: 'subStatus',
    options: {},
  },
  {
    align: '',
    label: 'Agent Name',
    minWidthHead: '',
    name: 'agentName',
    options: {},
  },
  {
    align: '',
    label: 'Reason',
    minWidthHead: '',
    name: 'reason',
    options: {},
  },
  {
    align: '',
    label: '',
    minWidthHead: '',
    name: '',
    options: {},
    iconCheck: 'Yes',
    cellFormat(value) {
      return (
        <>
          {canAddtoWtfall(value) && (
          <Button
            disabled={!segregationGroupLower.some(l => groups.includes(l)) || !isEditable}
            onClick={() => handleWaterfallAddition(value)}
            styleName="addButton"
            type="button"
          >
ADD TO WATERFALL
          </Button>
          )}
        </>
      );
    },
  },
  ];

  const popupHandle = (event) => {
    setAnchorEl(event.currentTarget);
    if (!isPopupVisible) {
      setSelectedCase('');
    }
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopupVisible(false);
  };
  const handleCaseCreation = (item) => {
    const { createNewCase } = props;
    createNewCase({ workoutType: item, selectedCaseType: selectedCase });
    setIsPopupVisible(false);
  };

  return (
    <div styleName="white-bg">
      <div styleName="table-header">
        <h2>Case(s)</h2>
        <div styleName="popupHolder">
          <Button
            color="primary"
            disabled={!createNewCaseAdGroups || createButtonStatus || !getModStatus}
            onClick={popupHandle}
            startIcon={<AddIcon />}
          >
                      Create New Case
          </Button>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handleClose}
            open={isPopupVisible}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div>
              { !selectedCase
                ? (
                  <>
                    {caseTypesData.length === 0 ? (<Typography style={{ padding: '5px' }}> No Case Types Available</Typography>)
                      : (
                        <List aria-label="secondary mailbox folders" component="nav" dense>
                          {caseTypesData.map(item => (
                            <ListItem
                              button
                              className={classes.item}
                              onClick={() => { handleCaseTypeDropDown(item); }}
                            >
                              <ListItemText primary={item} />
                            </ListItem>
                          ))}
                        </List>
                      )
                    }
                  </>
                )
                : workoutTypes && workoutTypes.length > 0 && (
                  <>
                    <div styleName="header">
                      <div>
                        {' '}
                        <h3>Select Workout Type</h3>
                        <span>
for
                    &apos;
                          {selectedCase}
                    &apos;
                        </span>
                        {' '}

                      </div>
                      <Button onClick={() => setSelectedCase('')}>
                        {' '}
                        <b>&lt; Back </b>
                      </Button>
                    </div>
                    <div>
                      {isworkOutTypeLoading && (
                      <div style={{ padding: '5px' }}>
                        <Loader message="Fetching workout types..." size={20} />
                      </div>
                      ) }
                      {!isworkOutTypeLoading && (
                        <>
                          { workoutTypes.length === 0 ? (<Typography style={{ padding: '5px' }}> No Workout Types Available</Typography>)
                            : (
                              <List component="nav" dense>
                                {workoutTypes.map(item => (
                                  <ListItem
                                    button
                                    className={classes.item}
                                    onClick={() => {
                                      handleCaseCreation(item);
                                    }}
                                  >
                                    <ListItemText primary={item} />
                                  </ListItem>
                                ))}
                              </List>
                            )}
                        </>
                      )
                      }

                      {/* {workoutTypes.map(item => (
                        <p
                          aria-hidden="true"
                          onClick={() => {
                            setType(item);
                            handleCaseCreation(item);
                          }}
                        >
                          {item}
                        </p>
                      ))} */}
                      {' '}
                    </div>
                    {' '}

                  </>
                ) }
              <div />
            </div>
          </Popover>
        </div>
      </div>
      <div styleName="case-table-container">
        <EvalTable columns={columns} data={data} />
      </div>
    </div>

  );
};

NewCases.defaultProps = {
  isEditable: true,
  isworkOutTypeLoading: false,
};

NewCases.propTypes = {
  addCaseToWaterfallSeq: PropTypes.func.isRequired,
  caseTypesData: PropTypes.arrayOf(PropTypes.String).isRequired,
  createButtonStatus: PropTypes.bool.isRequired,
  createNewCase: PropTypes.func.isRequired,
  evalScreenData: PropTypes.shape().isRequired,
  fetchCaseTypes: PropTypes.func.isRequired,
  fetchWorkoutTypes: PropTypes.func.isRequired,
  getCaseData: PropTypes.shape().isRequired,
  getCaseHeaderInfo: PropTypes.func.isRequired,
  getModStatus: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  isworkOutTypeLoading: PropTypes.bool,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setModCalcIndicator: PropTypes.func.isRequired,
  setRepaymentCaseId: PropTypes.func.isRequired,
  setRuleEngineError: PropTypes.func.isRequired,
  setSelectedCaseDetail: PropTypes.func.isRequired,
  setSelectedCaseId: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
  workoutTypes: PropTypes.arrayOf(PropTypes.String).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  caseTypesData: evalSelectors.getCaseTypes(state),
  getCaseData: evalSelectors.getCaseData(state),
  getPreLockCaseValidation: evalSelectors.getPreLockCaseValidation,
  workoutTypes: evalSelectors.getWorkoutTypes(state),
  createButtonStatus: evalSelectors.getCreateButtonStatus(state),
  getModStatus: dashboardSelectors.getModStatus(state),
  isworkOutTypeLoading: evalSelectors.isworkOutTypeLoading(state),
});


const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  getCaseHeaderInfo: operations.getCaseHeaderInfo(dispatch),
  fetchCaseTypes: operations.fetchCaseTypesOperation(dispatch),
  fetchWorkoutTypes: operations.fetchWorkoutTypeDataOperation(dispatch),
  setSelectedCaseId: operations.setSelectedCaseIdOpn(dispatch),
  addCaseToWaterfallSeq: operations.addCaseToWaterfallSeq(dispatch),
  setSelectedCaseDetail: operations.setSelectedCaseDetailOpn(dispatch),
  createNewCase: operations.createNewCaseOperation(dispatch),
  setRepaymentCaseId: dashboardOperations.setRepaymentCaseIdOpn(dispatch),
  setRuleEngineError: operations.setRuleEngineErrorOperation(dispatch),
  setModCalcIndicator: operations.setModCalcIndicatorOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCases);
