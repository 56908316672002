import * as R from 'ramda';
import {
  SET_PAST_DUE_PAYMENT_DETAILS,
  SET_MONTHLY_PAYMENT_DETAILS,
  UPDATE_MONTHLY_PAYMENT_DETAILS,
  SET_REPAYMENT_EVAL_ERROR_MSG,
  ENABLE_CALC,
  SET_PAYMENT_CHANGES_DETAILS,
  SHOW_LOADER, HIDE_LOADER,
  SET_REJECT_REASONS,
  SET_IS_CASE_REJECTED,
} from './types';

const intialState = {
  monthlyPayment: {
    fees: 0,
    advances: 0,
    pendingPayment: 0,
  },
  isCaseRejected: false,
};

const reducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_PAST_DUE_PAYMENT_DETAILS: {
      const pastDuePayments = action.payload;
      return {
        ...state,
        pastDuePayments,
      };
    }

    case UPDATE_MONTHLY_PAYMENT_DETAILS: {
      const { key, value } = action.payload;
      const updatedObj = {
        ...state.monthlyPayment,
      };

      const isObj = R.isNil(R.pathOr(null, [key, 'columnVal'], updatedObj));
      let { fees, advances } = updatedObj;
      if (!isObj) {
        updatedObj[key].columnVal = value;
      } else {
        updatedObj[key] = value;
      }
      if (key === 'fees') {
        fees = value;
      } else if (key === 'advances') {
        advances = value;
      }
      return {
        ...state,
        monthlyPayment: updatedObj,
        fees,
        advances,
      };
    }

    case SET_MONTHLY_PAYMENT_DETAILS: {
      const monthlyPayment = action.payload;
      return {
        ...state,
        monthlyPayment,
      };
    }

    case SET_REPAYMENT_EVAL_ERROR_MSG: {
      const errorMessage = action.payload;
      return {
        ...state,
        errorMessage,
      };
    }

    case ENABLE_CALC: {
      const value = action.payload;
      return {
        ...state,
        enableCalc: value,
      };
    }

    case SET_PAYMENT_CHANGES_DETAILS: {
      const paymentChanges = action.payload;
      return {
        ...state,
        paymentChanges,
      };
    }

    case SHOW_LOADER: {
      return {
        ...state,
        inProgress: true,
      };
    }

    case HIDE_LOADER: {
      return {
        ...state,
        inProgress: false,
      };
    }

    case SET_REJECT_REASONS: {
      const rejectReasons = action.payload;
      return {
        ...state,
        rejectReasons,
      };
    }

    case SET_IS_CASE_REJECTED: {
      const isCaseRejected = action.payload;
      return {
        ...state,
        isCaseRejected,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
