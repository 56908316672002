import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, Box, Card, Grid, Typography, Chip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import { operations, selectors } from 'ducks/eval';
import * as R from 'ramda';
import PreModInformation from './PreModInformation';
import BookingStyles from './BookingStyles';
import CapitalizedData from './CapitalizedData';
import PostModInformation from './PostModInformation';
import StepRates from './StepRates';

const Booking = ({
  bookingData, inProgress, fetchBookingTags, fetchBookingTabData, caseId,
}) => {
  const classes = BookingStyles({ bookingData });

  useEffect(() => {
    fetchBookingTags();
    fetchBookingTabData({ caseId });
  }, []);

  return (
    <>
      { inProgress ? (
        <div className={classes.bookingLoader}>
          <Loader message="Please wait, while fetching Booking data" size={40} />
        </div>
      )
        : (
          <>
            <Box className={classes.statusBox}>
              <Chip
                avatar={<Avatar alt="success" className={classes.avatar} src={bookingData.bookingAutomatedTag === 'Yes' ? '/static/img/tick_green.svg' : '/static/img/cross_red.svg'} />}
                className={classes.bookingChip}
                label="BOOKING AUTOMATED"
              />
              {bookingData.investorSettledTag === 'Yes' && (
              <Chip
                className={classes.nonBookingChip}
                label="INVESTOR SETTLED"
              />
              )}
              {!R.isEmpty(bookingData.vendorTag) && bookingData.vendorTag !== 'No' && (
              <Chip
                className={classes.nonBookingChip}
                label={bookingData.vendorTag}
              />
              )}
              {bookingData.corelogictag === 'Yes' && (
              <Chip
                className={classes.nonBookingChip}
                label="CORELOGIC"
              />
              )}
            </Box>
            <Box className={classes.mainBox}>
              <Grid className={classes.mainGrid} container>
                <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
                  <Typography className={classes.header}>Pre-mod Information</Typography>
                  <Card className={classes.preModGrid}>
                    <PreModInformation />
                  </Card>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
                  <Typography className={classes.header}>Capitalized Data</Typography>
                  <Card className={classes.preModGrid}>
                    <CapitalizedData />
                  </Card>
                </Grid>
                <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.header}>Post-mod Information</Typography>
                      <Card className={classes.postModGrid}>
                        <PostModInformation />
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card className={classes.stepRatesGrid}>
                        <StepRates />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </>
        )
    }

    </>
  );
};

Booking.propTypes = {
  bookingData: PropTypes.shape({
    bookingAutomatedTag: PropTypes.string,
    corelogictag: PropTypes.string,
    investorSettledTag: PropTypes.string,
    vendorTag: PropTypes.string,
  }).isRequired,
  caseId: PropTypes.number.isRequired,
  fetchBookingTabData: PropTypes.func,
  fetchBookingTags: PropTypes.func,
  inProgress: PropTypes.bool.isRequired,
};

Booking.defaultProps = {
  fetchBookingTags: () => {},
  fetchBookingTabData: () => {},
};

const mapStateToProps = state => ({
  bookingData: selectors.bookingData(state),
  inProgress: selectors.inProgress(state),
  caseId: selectors.getSelectedCaseId(state),
});

const mapDispatchToProps = dispatch => ({
  fetchBookingTags: operations.fetchBookingTags(dispatch),
  fetchBookingTabData: operations.fetchBookingTabDataOperation(dispatch),
});

const BookingContainer = connect(mapStateToProps, mapDispatchToProps)(Booking);

export default BookingContainer;
