import React from 'react';
import PropTypes from 'prop-types';
import './AccountServices.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AccountServicesHistory from 'containers/AccountServices/AccountServicesHistory';
import moment from 'moment-timezone';
import ConfirmationDialogBox from 'components/Tasks/OptionalTask/ConfirmationDialogBox';
import { APPROVED, REJECTED } from 'constants/status';
import AnalysisBreakDownPopover from './AnalysisBreakDownPopover';

function ModInfoAccountServiceScreen(props) {
  const {
    children, analysisBreakdownData, isAnalysisScreenOpen, setOpenAnalysisPopup,
    closeDialog, processAction,
    toggleAccountServiceScreen,
    historyData,
    groupName, hasValueChange, showConfirmationDialog, handleConfirmDialogBoxButtons,
    updateDisbursement,
    setChecklistCenterPaneData,
    selectedCapModId, clearInfo, storeAnalysisBreakdownData,
  } = props;

  const selectedCapModData = historyData.find(history => history.capModId
    === selectedCapModId) || {};

  const enabled = ['BEUW', 'DOCGEN'].includes(groupName) ? (selectedCapModData.escrowStatus === APPROVED && hasValueChange) : false;

  const closeAccountServiceScreenForRejectedEscrow = () => {
    if (enabled) {
      closeDialog();
      return;
    }
    setChecklistCenterPaneData('Checklist');
    toggleAccountServiceScreen(false);
    clearInfo();
  };

  const hideUpdateButton = selectedCapModData.escrowStatus === REJECTED ? 'hide-update-button' : 'update-button-disabled';

  return (
    <Grid alignItems="center" container direction="column" styleName="whiteBackground">
      <Grid alignItems="center" container direction="row" item justifyContent="space-evenly">
        <Grid id="title" item styleName="displayCenter" xs={8}>
          <div style={{ display: 'inline-flex' }}>
            <Typography styleName="titleTop">
                  Account Services
            </Typography>
            <AccountServicesHistory />
            <Typography styleName="history-title">
              <span>CapMod ID:  </span>
              <span>{selectedCapModId}</span>
            </Typography>
            <Typography styleName="history-title">
              <span>Completed Date:  </span>
              <span>
                {
                  selectedCapModData.completedDate
                    && moment(selectedCapModData.completedDate).format('DD MMM YYYY')
                }
              </span>
            </Typography>
            <Typography styleName="history-title">
              <span>Completed By:  </span>
              <span>{selectedCapModData.completedBy}</span>
            </Typography>
            <Typography styleName="history-title">
              <span>Escrow Status:  </span>
              <span>{selectedCapModData.escrowStatus}</span>
            </Typography>
          </div>

        </Grid>
        <Grid alignItems="center" container direction="row" id="status" item style={{ justifyContent: 'flex-end' }} xs={4}>
          <Grid id="viewAnalysisButton" styleName="screenHeader">
            <Button
              onClick={() => {
                storeAnalysisBreakdownData(analysisBreakdownData);
                setOpenAnalysisPopup();
              }}
              styleName={selectedCapModData.escrowStatus === REJECTED ? 'analysisBreakdown' : 'view-analysis-button'}
            >
                                VIEW ANALYSIS BREAKDOWN
            </Button>
          </Grid>
          <Grid id="updateButton" styleName="screenHeader">
            <Button
              disabled={!enabled}
              onClick={() => { updateDisbursement(); }}
              styleName={enabled ? 'approve-button' : hideUpdateButton}
              variant="contained"
            >
                               UPDATE
            </Button>
          </Grid>
          <Grid id="close" item styleName="screenHeader">
            <Button onClick={() => { closeAccountServiceScreenForRejectedEscrow(); }}>
              <CloseIcon />

            </Button>
          </Grid>
        </Grid>
      </Grid>
      { children }
      <AnalysisBreakDownPopover
        closeDialog={setOpenAnalysisPopup}
        data={analysisBreakdownData}
        isOpen={isAnalysisScreenOpen}
        processAction={processAction}
      />
      <ConfirmationDialogBox
        isOpen={showConfirmationDialog}
        message=""
        onClose={(value) => {
          handleConfirmDialogBoxButtons(value);
          if (value) {
            setChecklistCenterPaneData('Checklist');
            toggleAccountServiceScreen(false);
            clearInfo();
          }
        }}
        title="Are you sure you want to close the page?"
      />
    </Grid>
  );
}

ModInfoAccountServiceScreen.propTypes = {
  analysisBreakdownData: PropTypes.shape.isRequired,
  children: PropTypes.node.isRequired,
  clearInfo: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  handleConfirmDialogBoxButtons: PropTypes.func.isRequired,
  hasValueChange: PropTypes.bool.isRequired,
  historyData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isAnalysisScreenOpen: PropTypes.bool.isRequired,
  processAction: PropTypes.func.isRequired,
  selectedCapModId: PropTypes.number.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setOpenAnalysisPopup: PropTypes.func.isRequired,
  showConfirmationDialog: PropTypes.bool.isRequired,
  storeAnalysisBreakdownData: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  updateDisbursement: PropTypes.func.isRequired,
};

export default ModInfoAccountServiceScreen;
