import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './SMDUWidget.css';
import {
  Button, Typography, Grid,
} from '@material-ui/core/index';
import {
  operations as smduOperations,
  selectors as smduSelectors,
} from 'ducks/smdu';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import WidgetLoader from 'components/Widgets/WidgetLoader';
import SweetAlertBox from 'components/SweetAlertBox';
import AddPriorWorkout from './AddPriorWorkout';
import { CustomButton } from '../../containers/Dashboard/FhlmcResolve/FHLMCDataInsight';
import History from '../../containers/SMDU/History';
import CaseManagement from '../../containers/SMDU/CaseManagement';
import Darts from '../../containers/SMDU/Darts';
import Paging from '../../containers/SMDU/Pagination';

const requestTypes = [
  { requestType: 'Pre Qualify', displayText: 'Pre Qualify' },
  { requestType: 'Trial Case', displayText: 'Trial Case' },
  { requestType: 'Mod Case', displayText: 'Mod Case' },
  { requestType: 'Run IDI', displayText: 'Run IDI' },
];

class SMDUWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: '',
      currentPage: 1,
      itemsPerPage: 3,
      value: '1',
      isOpen: true,
      isPriorWorkoutVisible: false,
    };
    this.renderFnmaRequestTypes = this.renderFnmaRequestTypes.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    const {
      handleSMDUHistory,
      fetchTransactionData,
      fetchSMDUPriorWorkoutData,
    } = this.props;
    handleSMDUHistory();
    fetchTransactionData();
    fetchSMDUPriorWorkoutData();
  }

  handleBackPriorWorkout = arr => this.setState({ isPriorWorkoutVisible: arr });

  handleAddPriorWorkout = () => {
    this.setState({ isPriorWorkoutVisible: true });
  }

  submitToFnma = () => {
    const { requestType } = this.state;
    if (requestType !== null) {
      const { submitToFNMA } = this.props;
      submitToFNMA(requestType);
      this.setState({ requestType: '' });
    }
  }

  handleClose = () => {
    const { closePopUp } = this.props;
    closePopUp();
  }

  handleChange = (event, newValue) => {
    this.setState(prevState => ({ ...prevState, value: newValue }));
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  }

  historyDartsData() {
    const { formattedDartsData } = this.props;
    formattedDartsData();
  }

  CaseManagementData() {
    const { formattedCaseManagementData } = this.props;
    formattedCaseManagementData();
  }

  renderFnmaRequestTypes = () => {
    const { requestType } = this.state;
    return (
      <>
        <div styleName="request-type-container">
          <div>
            <div styleName="requestCategoryDropdown">
              <span>
                {'Select Request Type'}
              </span>
            </div>
          </div>
          <div styleName="requestTypeSection">
            {requestTypes && requestTypes.map((item) => {
              const {
                canRequestModStructuring, canRequestPreQualification,
                canRequestTrialStructuring, canRunIDI,
              } = this.props;
              return (
                <Button
                  key={item.requestType}
                  disabled={(item.displayText === 'Trial Case' && !canRequestTrialStructuring) || (item.displayText === 'Mod Case' && !canRequestModStructuring)
                  || (item.displayText === 'Pre Qualify' && !canRequestPreQualification) || (item.displayText === 'Run IDI' && !canRunIDI)
                }
                  onClick={() => this.setState({ requestType: item.displayText })}
                  styleName={requestType === item.displayText ? 'btn-tabs-click' : 'btn-tabs'}
                  variant="outlined"
                >
                  {item.displayText}
                </Button>
              );
            })}
          </div>
          <div>
            <Grid item>
              <Button
                color="primary"
                disabled={R.isEmpty(requestType)}
                onClick={this.submitToFnma}
                styleName="submit-fnma"
                variant="contained"
              >
                SUBMIT TO FNMA
              </Button>
            </Grid>
          </div>
        </div>
      </>
    );
  }

  render() {
    const
      {
        currentPage, itemsPerPage, value, isPriorWorkoutVisible,
      } = this.state;
    const {
      loading, isPopUpEnabled, formattedData, transactionData, formattedDartsData,
      formattedCaseManagementData, SMDUPriorWorkoutDropdownOptions, getPopUpMessage, popUpType,
    } = this.props;
    const formattedDataArray = Array.isArray(formattedData) ? formattedData : [];
    const sortedFormattedData = formattedDataArray.slice()
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedFormattedData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(sortedFormattedData.length / itemsPerPage);
    const dartHistorys = Array.isArray(formattedDartsData) ? formattedDartsData : [];
    const caseManagements = Array.isArray(formattedCaseManagementData)
      ? formattedCaseManagementData : formattedCaseManagementData;

    let renderComponent = null;
    renderComponent = (
      <SweetAlertBox
        message={getPopUpMessage}
        onConfirm={() => this.handleClose()}
        show={isPopUpEnabled}
        type={popUpType}
      />
    );


    if (loading) {
      return (
        <>
          <WidgetLoader />
          {renderComponent}
        </>
      );
    }

    if (loading) {
      return (
        <>
          <WidgetLoader />
          {renderComponent}
        </>
      );
    }

    return (
      <>
        {isPriorWorkoutVisible ? (
          <AddPriorWorkout
            getPopUpMessage={getPopUpMessage}
            handleBackPriorWorkout={this.handleBackPriorWorkout}
            loading={loading}
            SMDUPriorWorkoutDropdownOptions={SMDUPriorWorkoutDropdownOptions}
          />
        ) : (
          <>
            <div styleName="smdu-details-parent">
              <section style={{ display: 'flex' }}>
                <div styleName="d-flex">
                  <Typography styleName="title" variant="h2">SMDU Operations</Typography>
                </div>
                <CustomButton
                  color="primary"
                  extraStyle="widgetDwnld"
                  onClick={this.handleAddPriorWorkout}
                  title="+ ADD PRIOR WORKOUT"
                  variant="text"
                />
              </section>
              {this.renderFnmaRequestTypes()}
              <div>
                <TabContext value={value}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    font: 'lato',
                    marginLeft: '-4rem',

                  }}
                  >
                    <TabList
                      onChange={this.handleChange}
                      sx={{
                        padding: 0,
                      }}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: '#F3F5F9', width: '0%', marginLeft: '10px', // Set the indicator color to white
                        },
                      }}
                    >
                      <Tab label="History" sx={{ textTransform: 'none', minWidth: 'auto' }} value="1" />
                      <Tab label="Case Management" sx={{ textTransform: 'none', minWidth: 'auto' }} value="2" />
                    </TabList>
                  </div>
                  <div style={{
                    borderRadius: '1rem', background: '#F3F5F9',
                  }}
                  >
                    <div style={{
                      display: 'flex', justifyContent: 'flex-start', font: 'lato', borderRadius: '5rem', paddingBottom: '1rem',
                    }}
                    />
                    <TabPanel style={{ padding: '0px', background: '#F3F5F9' }} value="1">
                      <div className="history_container" style={{ background: '#F3F5F9' }}>
                        {currentItems.map(item => (
                          <div key={item.user + item.date}>
                            <History
                              data={item}
                              transactionData={transactionData}
                            />

                          </div>
                        ))}
                        <Darts dartHistorys={dartHistorys} />
                      </div>
                    </TabPanel>

                    <TabPanel style={{ padding: '0px', background: '#F3F5F9' }} value="2">
                      <div className="history_container" style={{ background: '#F3F5F9' }} />
                      <CaseManagement caseManagements={caseManagements} />
                    </TabPanel>
                  </div>
                </TabContext>

              </div>
              <div styleName="pagination-controls">
                <Paging
                  onPageChange={this.handlePageChange}
                  selectedPage={currentPage}
                  styleProps={{ size: 'small' }}
                  totalPages={totalPages}
                />
              </div>
            </div>
            {renderComponent}
          </>
        )}
      </>
    );
  }
}

SMDUWidget.propTypes = {
  canRequestModStructuring: PropTypes.bool.isRequired,
  canRequestPreQualification: PropTypes.bool.isRequired,
  canRequestTrialStructuring: PropTypes.bool.isRequired,
  canRunIDI: PropTypes.bool.isRequired,
  closePopUp: PropTypes.func.isRequired,
  fetchSMDUPriorWorkoutData: PropTypes.func.isRequired,
  fetchTransactionData: PropTypes.func.isRequired,
  formattedCaseManagementData: PropTypes.arrayOf(PropTypes.shape({
    errorsData: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
      Message: PropTypes.string.isRequired,
      MessageID: PropTypes.string.isRequired,
      Result: PropTypes.string.isRequired,
    }))).isRequired,
    reportsData: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string.isRequired,
      messageId: PropTypes.string.isRequired,
      mesult: PropTypes.string.isRequired,
    }))).isRequired,
    specificMessage: PropTypes.string.isRequired,
  })).isRequired,
  formattedDartsData: PropTypes.arrayOf(PropTypes.shape({
    reportedWorkoutCampaignName: PropTypes.string.isRequired,
    reportedWorkoutCaseStatus: PropTypes.string.isRequired,
    reportedWorkoutPaymentDueDates: PropTypes.string.isRequired,
    reportedWorkoutServicerName: PropTypes.string.isRequired,
    reportedWorkoutType: PropTypes.string.isRequired,
    requestType: PropTypes.string.isRequired,
  })).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    requestType: PropTypes.string.isRequired,
    validationData: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })).isRequired,
    workoutDetails: PropTypes.arrayOf(PropTypes.shape({
      categories: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        Message: PropTypes.string.isRequired,
        MessageID: PropTypes.string.isRequired,
        Result: PropTypes.string.isRequired,
      }))).isRequired,
      WorkoutTypeCode: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,
  getPopUpMessage: PropTypes.string.isRequired,
  handleSMDUHistory: PropTypes.func.isRequired,
  isPopUpEnabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  popUpType: PropTypes.string.isRequired,
  SMDUPriorWorkoutDropdownOptions: PropTypes.shape({
    activityTypes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    failCancelReasons: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    statusTypes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    subsequentDelinquencySeverities: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
    workoutTypes: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
  }).isRequired,
  submitToFNMA: PropTypes.func.isRequired,
  transactionData: PropTypes.arrayOf(PropTypes.shape({
    operationType: PropTypes.string.isRequired,
    requestReceiptTimeStamp: PropTypes.string.isRequired,
    requestXml: PropTypes.string.isRequired,
    responseXml: PropTypes.string.isRequired,
  })).isRequired,
};

const mapStateToProps = state => ({
  isModCaseEnabled: smduSelectors.hasEligibleTrial,
  formattedData: state.smdu.smduHistoryData,
  formattedDartsData: state.smdu.smduHistoryDartsData,
  transactionData: state.smdu.smduTransactionData,
  canRunIDI: smduSelectors.canRunIDI(state),
  canRequestModStructuring: smduSelectors.canRequestModStructuring(state),
  canRequestPreQualification: smduSelectors.canRequestPreQualification(state),
  canRequestTrialStructuring: smduSelectors.canRequestTrialStructuring(state),
  loading: smduSelectors.isLoading(state),
  formattedCaseManagementData: state.smdu.smduCaseManagementData,
  SMDUPriorWorkoutDropdownOptions: smduSelectors.getSMDUPriorWorkoutDropdownOptions(state),
  isPopUpEnabled: smduSelectors.isPopUpEnabled(state),
  getPopUpMessage: smduSelectors.getPopUpMessage(state),
  popUpType: state.smdu.popUpType,
});

const mapDispatchToProps = dispatch => ({
  handleSMDUHistory: smduOperations.handleSMDUHistory(dispatch),
  fetchTransactionData: smduOperations.fetchTransactionData(dispatch),
  submitToFNMA: smduOperations.submitToFNMAOperation(dispatch),
  fetchSMDUPriorWorkoutData: smduOperations.fetchSMDUPriorWorkoutDataOperation(dispatch),
  closePopUp: smduOperations.closePopUpOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SMDUWidget);
