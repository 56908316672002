import {
  fetchCalcLogsDataAction, fetchEligibilityDataAction, fetchUniversalActionDataAction,
  saveEligibilityDataAction,
  fetchRejectReasonsAction, saveFulfillmentChecklistAction,
  setRejectReasonPopupStatusAction, fetchFulfillmentChecklistAction,
  saveRejectReasonAction, fetchTrackPaymentDataAction, updateTrackPaymentDatesAction,
  deleteTrackPaymentAction, submitCollateralAcceptance, fetchBookingTagsAction,
  saveCertainMortgageLoanChecklistAction, calculateCaseRules, lockCaseRules, saveCaseDetails,
  getCaseHeaderInfoAction, fetchCertainMorgageLoanChecklistAction,
  fetchEvalDetailsAction, saveAcceptanceFlagAction, fetchCaseTypesAction,
  fetchWorkoutTypeDataAction, fetcEvalScreenInfoAction, saveDisasterDecAction,
  setSelectedCaseIdAction, fetchLockOverrideAction, saveLockCase, toggleLockCase,
  addCaseToWaterfallSeqAction, setSelectedCaseDetailAction,
  setEvalScreenIconAction, createNewModAction, createNewEvalCaseAction,
  fetchBookingTabDataAction,
  moveCaseSeqAction,
  removeWaterfallAction,
  checkIsLetterGeneratedAction,
  updateLetterGenerationStatusAction,
  fetchStatesAction,
  toggleDisasterPopupAction,
  fetchCountysAction,
  fetchDisasterDtlsAction,
  fetchCaseNpvDetailsAction,
  setDisasterDtlsAction,
  setCountysAction,
  fetchFinancialDropdownAction, saveFinancialDataAction,
  updateHardShipIdAction, fetchCaseUniversalDataAction,
  createNewCaseAction, setRuleEngineErrorAction,
  SaveOverriddenRuleAction, setModCalcIndicatorAction,
} from './actions';

const fetchUniversalActionData = dispatch => (payload) => {
  dispatch(fetchUniversalActionDataAction(payload));
};

const fetchEligibilityData = dispatch => (payload) => {
  dispatch(fetchEligibilityDataAction(payload));
};

const fetchCalcLogsData = dispatch => (payload) => {
  dispatch(fetchCalcLogsDataAction(payload));
};

const saveEligibilityData = dispatch => (payload) => {
  dispatch(saveEligibilityDataAction(payload));
};

const getCaseHeaderInfo = dispatch => (payload) => {
  dispatch(getCaseHeaderInfoAction(payload));
};

const fetchRejectReasonsOperation = dispatch => () => {
  dispatch(fetchRejectReasonsAction());
};

const setRejectreasonPopupStatusOperation = dispatch => (payload) => {
  dispatch(setRejectReasonPopupStatusAction(payload));
};

const saveRejectReasonOperation = dispatch => (payload) => {
  dispatch(saveRejectReasonAction(payload));
};

const fetchTrackPaymentData = dispatch => (payload) => {
  dispatch(fetchTrackPaymentDataAction(payload));
};

const deleteTrackPaymentOperation = dispatch => (payload) => {
  dispatch(deleteTrackPaymentAction(payload));
};

const updateTrackPaymentsDates = dispatch => (payload) => {
  dispatch(updateTrackPaymentDatesAction(payload));
};

const submitCollateralAcceptanceOpn = dispatch => (payload) => {
  dispatch(submitCollateralAcceptance(payload));
};

const fetchFulfillmentChecklistOperation = dispatch => (payload) => {
  dispatch(fetchFulfillmentChecklistAction(payload));
};

const saveFulfillmentChecklistOperation = dispatch => (payload) => {
  dispatch(saveFulfillmentChecklistAction(payload));
};

const fetchBookingTags = dispatch => (payload) => {
  dispatch(fetchBookingTagsAction(payload));
};

const calculateCaseRulesOpn = dispatch => (payload) => {
  dispatch(calculateCaseRules(payload));
};

const lockCaseRulesOperation = dispatch => (payload) => {
  dispatch(lockCaseRules(payload));
};

const toggleLockCaseOperation = dispatch => (payload) => {
  dispatch(toggleLockCase(payload));
};

const saveLockCaseOperation = dispatch => (payload) => {
  dispatch(saveLockCase(payload));
};

const saveCaseDetailsOpn = dispatch => (payload) => {
  dispatch(saveCaseDetails(payload));
};

const fetchCertainMorgageLoanChecklistOperation = dispatch => (payload) => {
  dispatch(fetchCertainMorgageLoanChecklistAction(payload));
};

const saveCertainMortgageLoanChecklistOperation = dispatch => (mortgageData, errorDisplay) => {
  dispatch(saveCertainMortgageLoanChecklistAction(mortgageData, errorDisplay));
};

const fetchEvalDetailsOperation = dispatch => () => {
  dispatch(fetchEvalDetailsAction());
};

const saveAcceptanceFlagOperation = dispatch => (payload) => {
  dispatch(saveAcceptanceFlagAction(payload));
};

const fetchCaseTypesOperation = dispatch => () => {
  dispatch(fetchCaseTypesAction());
};

const fetchWorkoutTypeDataOperation = dispatch => (payload) => {
  dispatch(fetchWorkoutTypeDataAction(payload));
};

const fetcEvalScreenInfoOperation = dispatch => (payload) => {
  dispatch(fetcEvalScreenInfoAction(payload));
};

const saveDisasterDecOperation = dispatch => (payload) => {
  dispatch(saveDisasterDecAction(payload));
};

const setSelectedCaseIdOpn = dispatch => (payload) => {
  dispatch(setSelectedCaseIdAction(payload));
};

const addCaseToWaterfallSeq = dispatch => (payload) => {
  dispatch(addCaseToWaterfallSeqAction(payload));
};

const createNewModOperation = dispatch => (payload) => {
  dispatch(createNewModAction(payload));
};
const setSelectedCaseDetailOpn = dispatch => (payload) => {
  dispatch(setSelectedCaseDetailAction(payload));
};

const setEvalScreenIconOpn = dispatch => (payload) => {
  dispatch(setEvalScreenIconAction(payload));
};

const fetchLockOverride = dispatch => (payload) => {
  dispatch(fetchLockOverrideAction(payload));
};

const createNewEvalCaseOperation = dispatch => (payload) => {
  dispatch(createNewEvalCaseAction(payload));
};

const fetchBookingTabDataOperation = dispatch => (payload) => {
  dispatch(fetchBookingTabDataAction(payload));
};
const checkIsLetterGeneratedOperation = dispatch => (payload) => {
  dispatch(checkIsLetterGeneratedAction(payload));
};

const updateLetterGenerationStatusOperation = dispatch => (payload) => {
  dispatch(updateLetterGenerationStatusAction(payload));
};

const moveCaseSeqOperation = dispatch => (payload) => {
  dispatch(moveCaseSeqAction(payload));
};

const removeWaterfallOperation = dispatch => (payload) => {
  dispatch(removeWaterfallAction(payload));
};

const fetchStatesOperation = dispatch => () => {
  dispatch(fetchStatesAction());
};

const toggleDisasterPopupOperation = dispatch => (payload) => {
  dispatch(toggleDisasterPopupAction(payload));
};

const fetchCountysOperation = dispatch => (payload) => {
  dispatch(fetchCountysAction(payload));
};

const fetchDisasterDtlsOperation = dispatch => (payload) => {
  dispatch(fetchDisasterDtlsAction(payload));
};

const setDisasterDtlsOperation = dispatch => (payload) => {
  dispatch(setDisasterDtlsAction(payload));
};

const setCountysOperation = dispatch => (payload) => {
  dispatch(setCountysAction(payload));
};

const fetchCaseNpvDetailsOperation = dispatch => (payload) => {
  dispatch(fetchCaseNpvDetailsAction(payload));
};

const fetchFinancialDropdownOperation = dispatch => (payload) => {
  dispatch(fetchFinancialDropdownAction(payload));
};

const saveFinancialDataOperation = dispatch => (payload) => {
  dispatch(saveFinancialDataAction(payload));
};

const updateHardshipIdOperation = dispatch => (payload) => {
  dispatch(updateHardShipIdAction(payload));
};

const fetchCaseUniversalDataOperation = dispatch => () => {
  dispatch(fetchCaseUniversalDataAction());
};

const createNewCaseOperation = dispatch => (payload) => {
  dispatch(createNewCaseAction(payload));
};

const setRuleEngineErrorOperation = dispatch => (payload) => {
  dispatch(setRuleEngineErrorAction(payload));
};

const saveOverriddenRuleOperation = dispatch => (payload) => {
  dispatch(SaveOverriddenRuleAction(payload));
};

const setModCalcIndicatorOperation = dispatch => (payload) => {
  dispatch(setModCalcIndicatorAction(payload));
};

const operations = {
  addCaseToWaterfallSeq,
  fetchUniversalActionData,
  fetchEligibilityData,
  fetchCalcLogsData,
  saveEligibilityData,
  getCaseHeaderInfo,
  fetchRejectReasonsOperation,
  setRejectreasonPopupStatusOperation,
  saveRejectReasonOperation,
  fetchTrackPaymentData,
  updateTrackPaymentsDates,
  deleteTrackPaymentOperation,
  submitCollateralAcceptanceOpn,
  fetchFulfillmentChecklistOperation,
  saveFulfillmentChecklistOperation,
  fetchBookingTags,
  calculateCaseRulesOpn,
  lockCaseRulesOperation,
  toggleLockCaseOperation,
  saveLockCaseOperation,
  saveCaseDetailsOpn,
  fetchCertainMorgageLoanChecklistOperation,
  saveCertainMortgageLoanChecklistOperation,
  fetchEvalDetailsOperation,
  saveAcceptanceFlagOperation,
  fetchCaseTypesOperation,
  fetchWorkoutTypeDataOperation,
  fetcEvalScreenInfoOperation,
  saveDisasterDecOperation,
  setSelectedCaseIdOpn,
  createNewModOperation,
  setSelectedCaseDetailOpn,
  setEvalScreenIconOpn,
  fetchLockOverride,
  createNewEvalCaseOperation,
  fetchBookingTabDataOperation,
  moveCaseSeqOperation,
  removeWaterfallOperation,
  checkIsLetterGeneratedOperation,
  updateLetterGenerationStatusOperation,
  fetchStatesOperation,
  toggleDisasterPopupOperation,
  fetchCountysOperation,
  fetchDisasterDtlsOperation,
  setDisasterDtlsOperation,
  fetchCaseNpvDetailsOperation,
  setCountysOperation,
  fetchFinancialDropdownOperation,
  saveFinancialDataOperation,
  updateHardshipIdOperation,
  fetchCaseUniversalDataOperation,
  createNewCaseOperation,
  setRuleEngineErrorOperation,
  saveOverriddenRuleOperation,
  setModCalcIndicatorOperation,
};

export default operations;
