import React from 'react';
import PropTypes from 'prop-types';
import './AccountServices.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import AccountServicesHistory from 'containers/AccountServices/AccountServicesHistory';
import moment from 'moment-timezone';
import { STAGER } from 'constants/common';
import AnalysisBreakDownPopover from './AnalysisBreakDownPopover';

function CapModHistoryScreen(props) {
  const {
    children, analysisBreakdownData, isAnalysisScreenOpen, setOpenAnalysisPopup,
    selectedCapModData, toggleHistoryView, processAction, stagerTaskName,
    searchLoanTaskResponse, getCapModHistoryOperation, toggleAccountServiceScreen,
    clearHistoryInfo, capModId, storeAnalysisBreakdownData, escrowData,
  } = props;
  return (
    <Grid alignItems="center" container direction="column" styleName="whiteBackground">
      <Grid alignItems="center" container direction="row" item justifyContent="space-evenly">
        <Grid id="title" item styleName="displayCenter" xs={8}>
          <div style={{ display: 'inline-flex' }}>
            <Typography styleName="titleTop">
                  Account Services
            </Typography>
            <AccountServicesHistory />
            <Typography styleName="history-title">
              <span>CapMod ID:  </span>
              <span>{selectedCapModData.capModId}</span>
            </Typography>
            <Typography styleName="history-title">
              <span>Completed Date:  </span>
              <span>
                {
                  selectedCapModData.completedDate
                    && moment(selectedCapModData.completedDate).format('DD MMM YYYY')
                }
              </span>
            </Typography>
            <Typography styleName="history-title">
              <span>Completed By:  </span>
              <span>{selectedCapModData.completedBy}</span>
            </Typography>
            <Typography styleName="history-title">
              <span>Escrow Status:  </span>
              <span>{selectedCapModData.escrowStatus}</span>
            </Typography>
          </div>

        </Grid>
        <Grid alignItems="center" container direction="row" id="status" item style={{ justifyContent: 'flex-end' }} xs={4}>
          <Grid id="viewAnalysisButton" styleName="screenHeader">
            <Button
              onClick={() => {
                storeAnalysisBreakdownData(escrowData.analysisBreakdown);
                setOpenAnalysisPopup();
              }}
              styleName="analysisBreakdown"
            >
                                VIEW ANALYSIS BREAKDOWN
            </Button>
          </Grid>
          <Grid id="close" item styleName="screenHeader">
            <Button onClick={() => {
              if (stagerTaskName && stagerTaskName.activeTile === 'Escrow' && stagerTaskName.activeTab === STAGER.COMPLETED) {
                if (capModId === searchLoanTaskResponse['Capmod ID']) {
                  toggleHistoryView(false);
                  toggleAccountServiceScreen(false);
                  clearHistoryInfo();
                  return;
                }
                getCapModHistoryOperation(searchLoanTaskResponse['Capmod ID']);
                return;
              }
              toggleHistoryView(false);
              clearHistoryInfo();
            }}
            >
              <CloseIcon />

            </Button>
          </Grid>
        </Grid>
      </Grid>
      { children }
      <AnalysisBreakDownPopover
        closeDialog={setOpenAnalysisPopup}
        data={analysisBreakdownData}
        isOpen={isAnalysisScreenOpen}
        processAction={processAction}
      />
    </Grid>
  );
}

CapModHistoryScreen.defaultProps = {
  stagerTaskName: {},
};

CapModHistoryScreen.propTypes = {
  analysisBreakdownData: PropTypes.shape.isRequired,
  capModId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  clearHistoryInfo: PropTypes.func.isRequired,
  escrowData: PropTypes.shape().isRequired,
  getCapModHistoryOperation: PropTypes.func.isRequired,
  isAnalysisScreenOpen: PropTypes.bool.isRequired,
  processAction: PropTypes.func.isRequired,
  searchLoanTaskResponse: PropTypes.shape.isRequired,
  selectedCapModData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setOpenAnalysisPopup: PropTypes.func.isRequired,
  stagerTaskName: PropTypes.shape,
  storeAnalysisBreakdownData: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  toggleHistoryView: PropTypes.func.isRequired,
};

export default CapModHistoryScreen;
