import * as R from 'ramda';
import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_LOAN_EVAL,
  STORE_RESOLUTION_CHOICE_TYPE,
  STORE_ESCROW_DATA,
  STORE_ACC_EVENT_TYPE,
  STORE_ACC_INIT_VALUES,
  HANDLE_CONFIRM_DIALOG_ACTIONS,
  CLOSE_POPUPS,
  STORE_ANALYSIS_BREAKDOWN_DATA,
  STORE_HISTORY_DATA,
  ACCOUNT_SERVICE_HISTORY_VIEW,
  STORE_CAPMOD_HISTORY_DATA,
  STORE_CAPMOD_ID,
  STORE_CAPMOD_ID_HISTORY,
  TOGGLE_ANALYSIS_BREAKDOWN,
  SET_REQUEST_SUBMITTED,
  SET_EVENT_TYPE,
  DISPLAY_ACCOUNT_SERVICE_SCREEN,
  IS_VALUE_CHANGED,
  RESET_VALUE_CHANGED,
  IS_REJECT_BTN_CLICKED,
  CLEAR_INFO,
  CLEAR_HISTORY_INFO,
  STORE_LOAN_NUMBER,
  SHOW_LOADER,
  SET_PROMPT_ERROR,
  SET_IS_APPROVED,
  IS_REJECT_SUCCESSFUL,
  IS_NEW_ADHOC_RQST,
  SHOW_LOADER_IN_POPUP,
} from './types';

const defaultState = {
  isApproved: false,
  showConfirmationDialog: false,
  isAnalysisScreenOpen: false,
  isRequestSubmitted: false,
  isAccountServiceScreenVisible: false,
  isRejectBtnClicked: false,
  accInitValues: {},
  escrowData: {},
  showLoader: true,
  showLoaderInPopup: false,
  hasValueChange: false,
  promptError: {},
  isRejectSuccessful: false,
  isNewAdhocRqst: false,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET_VALUE_CHANGED: {
      return {
        ...state,
        hasValueChange: false,
      };
    }
    case OPEN_DIALOG: {
      return {
        ...state,
        showDialog: true,
      };
    }
    case CLOSE_DIALOG: {
      const {
        accInitValues, escrowData, eventType, isRequestSubmitted, isNewAdhocRqst,
      } = state;
      const hasValueChange = R.reduce((acc, key) => acc || accInitValues[eventType][key]
      !== escrowData[eventType][key], false, Object.keys(accInitValues[eventType]));
      const showConfirmationDialog = isRequestSubmitted && !isNewAdhocRqst ? false
        : (hasValueChange || !isRequestSubmitted || isNewAdhocRqst);
      const stateProps = {};
      if (showConfirmationDialog) {
        stateProps.showConfirmationDialog = showConfirmationDialog;
      } else if (eventType === 'escrowToOrder') {
        stateProps.showDialog = false;
        stateProps.eventType = 'accountServices';
        stateProps.promptError = {};
        stateProps.isRequestSubmitted = false;
      } else {
        stateProps.isAccountServiceScreenVisible = false;
      }
      return {
        ...state,
        ...stateProps,
      };
    }
    case IS_VALUE_CHANGED: {
      const hasValueChange = action.payload;
      return {
        ...state,
        hasValueChange,
      };
    }
    case SET_LOAN_EVAL: {
      const { loanId, evalId, taskId } = action.payload;
      return {
        ...state,
        loanId,
        evalId,
        taskId,
      };
    }
    case STORE_RESOLUTION_CHOICE_TYPE: {
      return {
        ...state,
        resolutionChoiceType: action.payload,
      };
    }
    case STORE_ESCROW_DATA: {
      return {
        ...state,
        escrowData: action.payload,
      };
    }
    case STORE_ACC_EVENT_TYPE: {
      return {
        ...state,
        eventType: action.payload,
      };
    }
    case STORE_ACC_INIT_VALUES: {
      return {
        ...state,
        accInitValues: action.payload,
      };
    }
    case HANDLE_CONFIRM_DIALOG_ACTIONS: {
      const isConfirmed = action.payload;
      const payload = {};
      payload.showConfirmationDialog = false;
      if (isConfirmed) {
        payload.showDialog = false;
        payload.hasValueChange = false;
      }
      return {
        ...state,
        ...payload,
      };
    }
    case STORE_ANALYSIS_BREAKDOWN_DATA: {
      const analysisBreakdownData = action.payload;
      return {
        ...state,
        analysisBreakdownData,
      };
    }
    case CLOSE_POPUPS: {
      return {
        ...state,
        showConfirmationDialog: false,
        showDialog: false,
      };
    }
    case STORE_HISTORY_DATA: {
      return {
        ...state,
        historyData: action.payload,
      };
    }
    case ACCOUNT_SERVICE_HISTORY_VIEW: {
      const isHistoryView = action.payload;
      return {
        ...state,
        isHistoryView,
      };
    }
    case STORE_CAPMOD_HISTORY_DATA: {
      const capModHistory = action.payload;
      return {
        ...state,
        capModHistory,
      };
    }
    case STORE_CAPMOD_ID: {
      const capModId = action.payload;
      return {
        ...state,
        capModId,
      };
    }
    case STORE_CAPMOD_ID_HISTORY: {
      const capModIdHistory = action.payload;
      return {
        ...state,
        capModIdHistory,
      };
    }
    case STORE_LOAN_NUMBER: {
      const loanId = action.payload;
      return {
        ...state,
        loanId,
      };
    }
    case TOGGLE_ANALYSIS_BREAKDOWN: {
      const { isAnalysisScreenOpen } = state;
      return {
        ...state,
        isAnalysisScreenOpen: !isAnalysisScreenOpen,
      };
    }
    case SET_REQUEST_SUBMITTED: {
      const isRequestSubmitted = action.payload;
      return {
        ...state,
        isRequestSubmitted,
      };
    }
    case SET_EVENT_TYPE: {
      const eventType = action.payload;
      return {
        ...state,
        eventType,
      };
    }
    case DISPLAY_ACCOUNT_SERVICE_SCREEN: {
      const isAccountServiceScreenVisible = action.payload;
      return {
        ...state,
        isAccountServiceScreenVisible,
      };
    }
    case IS_REJECT_BTN_CLICKED: {
      const isRejectBtnClicked = action.payload;
      return {
        ...state,
        isRejectBtnClicked,
      };
    }
    case CLEAR_INFO: {
      return {
        ...defaultState,
      };
    }
    case CLEAR_HISTORY_INFO: {
      return {
        ...state,
        analysisBreakdownData: null,
        capModHistory: null,
        isHistoryView: false,
      };
    }
    case SHOW_LOADER: {
      const showLoader = action.payload;
      return {
        ...state,
        showLoader,
      };
    }
    case SHOW_LOADER_IN_POPUP: {
      const showLoaderInPopup = action.payload;
      return {
        ...state,
        showLoaderInPopup,
      };
    }
    case SET_IS_APPROVED: {
      const isApproved = action.payload;
      return {
        ...state,
        isApproved,
      };
    }
    case SET_PROMPT_ERROR: {
      const promptError = action.payload;
      return {
        ...state,
        promptError,
      };
    }
    case IS_REJECT_SUCCESSFUL: {
      const isRejectSuccessful = action.payload;
      return {
        ...state,
        isRejectSuccessful,
      };
    }
    case IS_NEW_ADHOC_RQST: {
      const isNewAdhocRqst = action.payload;
      return {
        ...state,
        isNewAdhocRqst,
      };
    }
    default:
      return state;
  }
};

export default reducer;
