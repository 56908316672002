/* eslint-disable no-param-reassign */
import { checklistGridColumnSize } from 'constants/common';
import * as R from 'ramda';
import moment from 'moment-timezone';

const getChecklistGridName = (key, type) => R.pathOr(R.pathOr('', [key, 'default'], checklistGridColumnSize), [key, type], checklistGridColumnSize);
const getCSTDateTime = dateTime => (R.isNil(dateTime) ? 'N/A' : moment.utc(dateTime).tz('America/Chicago').format('MM/DD/YYYY'));
const getFirstDayOfNextMonth = (pattern) => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  const effectiveDate = moment(firstDay).format(pattern || 'MM-DD-YYYY');
  return effectiveDate;
};

const modifyTaskTreeWithVisibility = (item, taskCodes, isHistoryView, isEscrowApproved) => {
  item.forEach((val) => {
    const id = R.prop('id', val);
    if (taskCodes.includes(id)) {
      val.visibility = true;
      if (id === 'REJECT_REASON' && isEscrowApproved !== undefined && isEscrowApproved) {
        val.visibility = false;
      }
      if (!isHistoryView) {
        val.disabled = false;
        if (id === 'REJECT_REASON') {
          val.taskBlueprint.additionalInfo.placeholder = 'Enter reject reason and Click \'Reject\' Button';
          val.value = null;
        }
      }
    }
    if (!R.isEmpty(val.subTasks)) {
      modifyTaskTreeWithVisibility(val.subTasks, taskCodes, isHistoryView, isEscrowApproved);
    }
  });
  return item;
};

const modifyTaskTreeWithEditableFields = (item, visibleTasks, isEscrowApproved) => {
  item.forEach((val) => {
    const id = R.prop('id', val);
    if (visibleTasks.includes(id)) {
      if (id === 'NET_DISB') {
        return;
      }
      val.disabled = false;
    }
    if (id === 'REJECT_REASON' && isEscrowApproved !== undefined && !isEscrowApproved) {
      val.visibility = true;
    }
    if (!R.isEmpty(val.subTasks)) {
      modifyTaskTreeWithEditableFields(val.subTasks, visibleTasks, isEscrowApproved);
    }
  });
  return item;
};

const modifyTaskTreeWithValue = (item, taskCode, props) => {
  item.forEach((val) => {
    const id = R.prop('id', val);
    if (id === taskCode) {
      val.taskBlueprint.additionalInfo.styleName = props.styleName;
      val.value = props.value;
    }
    if (!R.isEmpty(val.subTasks)) {
      modifyTaskTreeWithValue(val.subTasks, taskCode, props);
    }
  });
  return item;
};

const utils = {
  modifyTaskTreeWithValue,
  getChecklistGridName,
  getCSTDateTime,
  getFirstDayOfNextMonth,
  modifyTaskTreeWithEditableFields,
  modifyTaskTreeWithVisibility,
};
export default utils;
