import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import styles from './History.css';

const MessageCategory = ({ category, messages }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleCategory = () => setIsExpanded(!isExpanded);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleCategory();
    }
  };

  return (
    <div className={styles.message_category}>
      <div
        aria-controls={`messages-${category}`}
        aria-expanded={isExpanded}
        className={styles.accordion}
        onClick={toggleCategory}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {category}
        <div className={styles.accordion_arrow}>
          <img
            alt="toggle"
            height="7"
            src="/static/img/downArrow.svg"
            style={{ width: '14px', marginLeft: '5px' }}
          />
        </div>
      </div>
      {isExpanded && (
        <>
          <hr className={styles.separator} />
          <div className={styles.messages} id={`messages-${category}`}>
            {messages.map(({ MessageID, Message, Result }, index) => (
              <div key={MessageID} className={styles.message}>
                <div className={styles.message_container}>
                  <div className={styles.message_content}>
                    <strong>{MessageID}</strong>
                    {' '}
-
                    {Message}
                  </div>
                  <div className={Result === '' ? styles.pass_message : styles.fail_message}>
                    {Result === '' ? 'Passed' : 'Failed'}
                  </div>
                </div>
                {index !== messages.length - 1 && <Divider width="100%" />}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

MessageCategory.propTypes = {
  category: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      Message: PropTypes.string.isRequired,
      MessageID: PropTypes.string.isRequired,
      Result: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MessageCategory;
