
import {
  fetchGridDetails,
  fetchMatchedDataAction,
  setSupportAdminActiveTabAction,
  filterResultsAction,
  unRejectRequestAction,
  fetchSubstatusRequestOption, submitStatusOption,
  submitBulkStatusOption,
  submitEvalDisasterIdRequestAction,
  fetchCaseTypesAction,
  submitForceCreateCaseAction,
  submitForceCreateEvalCaseAction,
  fetchBulkActionReasonCodes, executeAction,
  setIdType,
  fetchBusinessAdminGridData,
  setBAselectedTabAction,
  fetchBusinessAdminUploadDataHistoryAction,
  applyBAFilterAction,
  fetchMatchedBADataAction,
  submitModSupportRequest,
  submitUploadSheetDataAction,
  getModInvestorsAction,
  setInvestorNameAction,
} from './actions';

const fetchSBHistoryGridData = dispatch => (pageNumber, pageSize, idType) => {
  dispatch(fetchGridDetails(pageNumber, pageSize, idType));
};

const fetchMatchedData = dispatch => (value) => {
  dispatch(fetchMatchedDataAction(value));
};

const setSupportAdminActiveTab = dispatch => (value) => {
  dispatch(setSupportAdminActiveTabAction(value));
};

const filterResults = dispatch => (value, pageNumber, pageSize) => {
  dispatch(filterResultsAction(value, pageNumber, pageSize));
};

const unRejectRequest = dispatch => (value) => {
  dispatch(unRejectRequestAction(value));
};

const fetchSubStatusRequestOptionOperation = dispatch => (payload) => {
  dispatch(fetchSubstatusRequestOption(payload));
};

const submitStatusOperation = dispatch => (payload) => {
  dispatch(submitStatusOption(payload));
};

const submitEvalDisasterIdRequest = dispatch => (payload) => {
  dispatch(submitEvalDisasterIdRequestAction(payload));
};

const submitBulkStatusOperation = dispatch => (payload) => {
  dispatch(submitBulkStatusOption(payload));
};

const fetchCaseTypes = dispatch => () => {
  dispatch(fetchCaseTypesAction());
};

const submitForceCreateCaseOperation = dispatch => (payload) => {
  dispatch(submitForceCreateCaseAction(payload));
};

const submitForceCreateEvalCaseOperation = dispatch => (payload) => {
  dispatch(submitForceCreateEvalCaseAction(payload));
};

const fetchBulkActionReasonCodesOperation = dispatch => () => {
  dispatch(fetchBulkActionReasonCodes());
};

const executeActionOperation = dispatch => (payload) => {
  dispatch(executeAction(payload));
};

const setIdTypeOperation = dispatch => (payload) => {
  dispatch(setIdType(payload));
};

const fetchBusinessAdminHistoryData = dispatch => (page, pageSize) => {
  dispatch(fetchBusinessAdminGridData(page, pageSize));
};

const fetchBusinessAdminUploadDataHistory = dispatch => (page, pageSize) => {
  dispatch(fetchBusinessAdminUploadDataHistoryAction(page, pageSize));
};

const setBASelectedTab = dispatch => (value) => {
  dispatch(setBAselectedTabAction(value));
};

const applyBAFilter = dispatch => (value, pageNumber, pageSize) => {
  dispatch(applyBAFilterAction(value, pageNumber, pageSize));
};

const fetchMatchedBAData = dispatch => (value) => {
  dispatch(fetchMatchedBADataAction(value));
};

const submitModSupportRequestData = dispatch => (payload) => {
  dispatch(submitModSupportRequest(payload));
};

const submitUploadSheetData = dispatch => (actionType, sheetData) => {
  dispatch(submitUploadSheetDataAction(actionType, sheetData));
};

const getModInvestorsOperation = dispatch => () => {
  dispatch(getModInvestorsAction());
};

const setInvestorName = dispatch => (value) => {
  dispatch(setInvestorNameAction(value));
};

const operations = {
  submitForceCreateEvalCaseOperation,
  submitForceCreateCaseOperation,
  fetchCaseTypes,
  submitEvalDisasterIdRequest,
  fetchSBHistoryGridData,
  fetchSubStatusRequestOptionOperation,
  submitStatusOperation,
  submitBulkStatusOperation,
  setSupportAdminActiveTab,
  fetchMatchedData,
  filterResults,
  unRejectRequest,
  fetchBulkActionReasonCodesOperation,
  executeActionOperation,
  setIdTypeOperation,
  setInvestorName,
  fetchBusinessAdminHistoryData,
  setBASelectedTab,
  fetchBusinessAdminUploadDataHistory,
  applyBAFilter,
  fetchMatchedBAData,
  submitModSupportRequestData,
  submitUploadSheetData,
  getModInvestorsOperation,
};


export default operations;
