import React from 'react';
import PropTypes from 'prop-types';
import './AccountServices.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AccountServicesHistory from 'containers/AccountServices/AccountServicesHistory';
import { messages } from 'constants/common';
import AnalysisBreakDownPopover from './AnalysisBreakDownPopover';

function ApprovedAccountServiceScreen(props) {
  const {
    children, analysisBreakdownData, isAnalysisScreenOpen, setOpenAnalysisPopup, processAction,
    toggleAccountServiceScreen, unAssignLoan, storeAnalysisBreakdownData, clearInfo,
    isActionFromLoanSearch, setChecklistCenterPaneData,
  } = props;
  return (
    <Grid alignItems="center" container direction="column" styleName="whiteBackground">
      <Grid alignItems="center" container direction="row" item justifyContent="space-evenly">
        <Grid id="title" item styleName="displayCenter" xs={2}>
          <div style={{ display: 'inline-flex' }}>
            <Typography styleName="titleTop">
              Account Services
            </Typography>
            <AccountServicesHistory />
          </div>

        </Grid>
        <Grid alignItems="center" container direction="row" id="status" item style={{ justifyContent: 'flex-end' }} xs={10}>
          <Grid item styleName="screenHeader">
            <div styleName="displayInlineCenter">
              <DoneIcon styleName="done" />
              <div styleName="approve">{messages.APPROVED}</div>
            </div>
          </Grid>
          <Grid id="viewAnalysisButton" styleName="screenHeader">
            <Button
              onClick={() => {
                storeAnalysisBreakdownData(analysisBreakdownData);
                setOpenAnalysisPopup();
              }}
              styleName="analysisBreakdown"
            >
                            VIEW ANALYSIS BREAKDOWN
            </Button>
          </Grid>
          <Grid id="close" item styleName="screenHeader">
            <Button onClick={() => {
              if (isActionFromLoanSearch) {
                clearInfo();
                setChecklistCenterPaneData('');
              } else {
                toggleAccountServiceScreen(false);
                clearInfo();
                unAssignLoan();
              }
            }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      { children }
      <AnalysisBreakDownPopover
        closeDialog={setOpenAnalysisPopup}
        data={analysisBreakdownData}
        isOpen={isAnalysisScreenOpen}
        processAction={processAction}
      />
    </Grid>
  );
}

ApprovedAccountServiceScreen.propTypes = {
  analysisBreakdownData: PropTypes.shape.isRequired,
  children: PropTypes.node.isRequired,
  clearInfo: PropTypes.func.isRequired,
  isActionFromLoanSearch: PropTypes.bool.isRequired,
  isAnalysisScreenOpen: PropTypes.bool.isRequired,
  processAction: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setOpenAnalysisPopup: PropTypes.func.isRequired,
  storeAnalysisBreakdownData: PropTypes.func.isRequired,
  toggleAccountServiceScreen: PropTypes.func.isRequired,
  unAssignLoan: PropTypes.func.isRequired,
};

export default ApprovedAccountServiceScreen;
