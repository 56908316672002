import * as R from 'ramda';

const getRequestType = state => R.pathOr([], ['smdu', 'fnmaRequest'], state);
const getSmduResponseData = state => R.pathOr([], ['smdu', 'smduResponseData'], state);
const isLoading = state => state.smdu.loading;
const canRunIDI = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRunIDI'], state);
const canRequestModStructuring = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRequestModStructuring'], state);
const canRequestPreQualification = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRequestPreQualification'], state);
const canRequestTrialStructuring = state => R.pathOr(false, ['smdu', 'smduResponseData', 'canRequestTrialStructuring'], state);
const getFormattedData = state => R.pathOr([], ['smdu', 'smduHistoryData'], state);
const getFormattedDartsData = state => R.pathOr([], ['smdu', 'smduHistoryDartsData'], state);
const getSmduCaseManagementData = state => R.pathOr([], ['smdu', 'smduCaseManagementData'], state);
const getSMDUPriorWorkoutDropdownOptions = state => R.pathOr([], ['smdu', 'SMDUPriorWorkoutDropdownOptions'], state);
const getReportToInvestor = state => R.pathOr([], ['smdu', 'reportToInvestor'], state);
const isPopUpEnabled = state => state.smdu.isPopUpEnabled;
const getPopUpMessage = state => R.pathOr('', ['smdu', 'popUpMessage'], state);

const selectors = {
  getRequestType,
  getSmduResponseData,
  isLoading,
  getFormattedData,
  getFormattedDartsData,
  canRunIDI,
  canRequestModStructuring,
  canRequestPreQualification,
  canRequestTrialStructuring,
  getSmduCaseManagementData,
  getSMDUPriorWorkoutDropdownOptions,
  getReportToInvestor,
  isPopUpEnabled,
  getPopUpMessage,
};

export default selectors;
