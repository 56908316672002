import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import DatePickerStyles from './DatePickerStyles';

const DatePicker = ({
  format, handleChange, id, label, override, value, isViewOnly,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);
  const classes = DatePickerStyles(override);
  const onDateChange = (date) => {
    setSelectedDate(date);
    handleChange(id, date);
  };
  if (!override) {
    return (
      <FormControl className={classes.root}>
        <InputLabel className={classes.labelName} htmlFor={label}>{label}</InputLabel>
        <TextField
          className={classes.TextFieldDiv}
          disabled={isViewOnly}
          id={id}
          InputProps={{
            disableUnderline: true,
          }}
          value={value}
        />
      </FormControl>
    );
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        className={classes.DatePickerRoot}
        disabled={isViewOnly}
        format={format}
        id={id}
        InputProps={{
          disableUnderline: true,
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        margin="normal"
        onChange={onDateChange}
        value={selectedDate}
        variant="inline"
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  format: PropTypes.string,
  handleChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  isViewOnly: PropTypes.bool,
  label: PropTypes.string,
  override: PropTypes.bool,
  value: PropTypes.string,
};

DatePicker.defaultProps = {
  format: 'DD MMMM YYYY',
  handleChange: () => {},
  label: '',
  override: false,
  value: '22/1/2023',
  isViewOnly: false,
};

export default DatePicker;
