import React from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TableFooter,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import './EvalTable.css';


function EvalTable(props) {
  const {
    columns, data, footerData, height,
  } = props;

  const processData = () => data && data.map((item) => {
    let newObject = {};
    columns.forEach(
      (obj) => {
        let cell;
        if (obj.cellFormat && obj.iconCheck) {
          cell = obj.cellFormat(item);
        } else if (obj.cellFormat && obj.editable && obj.dateField) {
          cell = obj.cellFormat(item);
        } else if (obj.cellFormat && obj.deleteIcon) {
          cell = obj.cellFormat(item[obj.name1]);
        } else if (obj.cellFormat && obj.sort) {
          cell = obj.cellFormat(item.seq);
        } else if (obj.cellFormat && obj.dateFormat) {
          cell = obj.cellFormat(item[obj.name]);
        } else if (obj.cellFormat) {
          cell = obj.cellFormat(item[obj.name], item, item[obj.name2]);
        } else {
          cell = item[obj.name];
        }
        newObject = R.assoc(
          obj.name,
          cell,
          newObject,
        );
        return newObject;
      },
    );
    return newObject;
  });
  return (
    <>
      <TableContainer style={{ height }}>
        <Table aria-label="simple table" stickyHeader styleName="table-container">
          <TableHead>
            <TableRow>
              {columns.map(
                (item, i) => item && (
                  <TableCell
                    key={item.name ? item.name : i}
                    align={item.align}
                    id={item.name}
                    style={{
                      minWidth: item.minWidthHead ? item.minWidthHead : 100,
                    }}
                  >
                    {item.label}
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {processData().map(row => (
              <TableRow>
                {Object.entries(row).map(([key, value]) => (
                  <TableCell
                    align="left"
                    id={`${key}_${value}`}
                  >
                    { value || ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          {Object.keys(footerData).length > 0 && (
            <TableFooter>
              <TableRow>
                {Object.entries(footerData).map(([key, value]) => (
                  <TableCell key={`${key}_${value}`} align="left">
                    {value || ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

EvalTable.defaultProps = {
  footerData: {},
  height: '',

};

EvalTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.string,
    label: PropTypes.string,
    minWidthHead: PropTypes.number,
    name: PropTypes.string,
    options: PropTypes.object,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  footerData: PropTypes.shape({}),
  height: PropTypes.string,
};

export default EvalTable;
