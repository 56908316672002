import util from 'ducks/tasks-and-checklist/utils';

const loanDetailsTemplate = [{
  additionalInfo: {
    hasTitle: true,
    styleName: 'we-title',
    actionIcon: 'close',
    iconPosition: 'right',
    actions: {
      postProcess: 'app/accountService/CLOSE_DIALOG',
    },
  },
  disabled: false,
  subTasks: [
    {
      disabled: false,
      order: 1,
      subTasks: [
        {
          disabled: true,
          order: 0,
          subTasks: [],
          taskBlueprint: {
            name: 'Loan No',
            description: 'Loan No',
            type: 'text',
            additionalInfo: {
              styleName: 'acc-esc',
              hasTitle: true,
              textAlign: 'left',
            },
          },
          id: 'LOAN_NUMBER',
          visibility: true,
        },
        {
          disabled: true,
          order: 1,
          subTasks: [],
          taskBlueprint: {
            name: 'Quote Type',
            description: 'Quote Type',
            type: 'text',
            additionalInfo: {
              styleName: 'acc-esc',
              hasTitle: true,
              textAlign: 'left',
            },
          },
          id: 'QUOTE_TYPE',
          visibility: true,
        },
        {
          disabled: false,
          order: 2,
          subTasks: [],
          taskBlueprint: {
            disabled: false,
            name: 'Effective Date',
            description: 'Effective Date',
            type: 'date',
            additionalInfo: {
              hasTitle: true,
              styleName: 'escrow',
              formatPattern: 'MM/DD/YYYY',
              fromDate: util.getFirstDayOfNextMonth('MM/DD/YYYY'),
            },
          },
          id: 'EFFECTIVE_DATE',
          visibility: true,
        },
        {
          disabled: false,
          order: 3,
          source: 'additionalInfo',
          subTasks: [],
          taskBlueprint: {
            disabled: false,
            name: 'Override Spread',
            description: 'Override Spread',
            type: 'dropdown',
            additionalInfo: {
              hasTitle: true,
              styleName: 'spread',
              options: [
                { displayName: '12', value: 12 },
                { displayName: '24', value: 24 },
                { displayName: '60', value: 60 },
              ],
            },
          },
          id: 'SPREAD',
          visibility: true,
        },
      ],
      taskBlueprint: {
        disabled: false,
        name: 'Loan Details',
        description: 'Loan Details',
        type: 'task-section',
        additionalInfo: {
          customType: 'paper',
          hasTitle: false,
          heightMultiplier: 2,
          operations: {
            preProcess: 'GATHER_ESCROW_DATA',
          },
        },
      },
      id: 'LOAN_DETAILS',
      visibility: true,
    },
    {
      id: 'SUBMIT_SECT',
      title: 'submit section',
      taskCode: 'SUBMIT_SECT',
      order: 2,
      taskBlueprint: {
        disabled: false,
        isVisible: true,
        type: 'task-section',
        additionalInfo: {
          styleName: 'loanInfoToOrder',
          hasTitle: false,
        },
      },
      subTasks: [{
        disabled: false,
        order: 0,
        subTasks: [],
        taskBlueprint: {
          disabled: false,
          name: 'SUBMIT',
          description: 'SUBMIT',
          type: 'button',
          additionalInfo: {
            variant: 'contained',
            nonPrintable: true,
            styleName: 'submit',
            actions: {
              postProcess: 'app/accountService/ORDER_ESCROW',
            },
          },
        },
        id: 'SUBMIT',
        visibility: true,
      },
      {
        disabled: false,
        order: 1,
        subTasks: [],
        taskBlueprint: {
          disabled: false,
          name: 'ERROR_MSG',
          description: 'ERROR_MSG',
          type: 'text',
          additionalInfo: {
            nonPrintable: true,
            styleName: 'pp-text',
            customType: 'read-only',
            valuePosition: 'left',
          },
        },
        id: 'ERROR_MSG',
        visibility: true,
      },
      ],
    },
  ],
  isVisible: true,
  id: 'ESCROW_TO_ORDER',
  title: 'Loan Details',
  type: 'task-section',
}];

const escrowOrderingTemplate = [{
  id: 'ACC_SERV_GRID',
  title: 'Account Services Grid',
  additionalInfo: {
    customType: 'grid',
    styleName: 'accountServices',
    labels: ['Current Payoff', 'Taxes and Insurances'],
    hasTitle: false,
    columnSize: [4, 8],
    labelSize: [4, 8],
    columns: 2,
  },
  type: 'task-section',
  taskCode: 'acc_serv_grid',
  subTasks: [
    {
      disabled: false,
      order: 1,
      subTasks: [{
        disabled: true,
        order: 0,
        subTasks: [],
        taskBlueprint: {
          name: 'Monthly Principal and Interests',
          description: 'Monthly Principal and Interests',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'MON_PAI',
        visibility: true,
      },
      {
        disabled: true,
        order: 1,
        subTasks: [],
        taskBlueprint: {
          name: 'Current Interest Dues',
          description: 'Current Interest Dues',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'CURR_INT_DUE',
        visibility: true,
      },
      {
        disabled: true,
        order: 2,
        subTasks: [],
        taskBlueprint: {
          name: 'Unpaid Legal Fees',
          description: 'Unpaid Legal Fees',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'UNP_LEG_FEE',
        visibility: true,
      },
      {
        disabled: true,
        order: 3,
        subTasks: [],
        taskBlueprint: {
          name: 'Current Corporate Advances',
          description: 'Current Corporate Advances',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'CUR_COR_ADV',
        visibility: true,
      },
      {
        disabled: true,
        order: 4,
        subTasks: [],
        taskBlueprint: {
          name: 'Escrow Advances',
          description: 'Escrow Advances',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'ESC_ADV',
        visibility: true,
      },
      {
        disabled: true,
        order: 5,
        subTasks: [],
        taskBlueprint: {
          name: 'All Other Fees and Expenses',
          description: 'All Other Fees and Expenses',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'AOF_EXP',
        visibility: true,
      },
      {
        disabled: true,
        order: 6,
        subTasks: [],
        taskBlueprint: {
          name: 'Principal',
          description: 'Principal',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'CP_PRIN',
        visibility: true,
      },
      {
        disabled: true,
        order: 7,
        subTasks: [],
        taskBlueprint: {
          name: 'Net Disbursement',
          description: 'Net Disbursement',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'text-with-icon',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            iconType: 'refresh',
            iconDisabled: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'NET_DISB',
        visibility: true,
      },
      {
        disabled: true,
        order: 8,
        subTasks: [],
        taskBlueprint: {
          disabled: false,
          name: 'Refresh Date',
          description: 'Refresh Date',
          type: 'date',
          additionalInfo: {
            hasTitle: true,
            styleName: 'accountServicesRefDate',
            formatPattern: 'DD MMM YYYY h:mm:ss A',
            showIcon: false,
          },
        },
        id: 'REF_DATE',
        visibility: true,
      },
      {
        disabled: true,
        order: 9,
        subTasks: [],
        taskBlueprint: {
          name: 'Conversion Disbursements',
          description: 'Conversion Disbursements',
          type: 'text',
          additionalInfo: {
            styleName: 'se-nom-as',
            customType: 'currency',
            format: 'currency',
            additionalElements: ['adornment'],
            adornment: '$',
            position: 'start',
            hasTitle: true,
            textAlign: 'left',
            source: 'accountService',
          },
        },
        id: 'CONV_DISB',
        visibility: true,
      },
      {
        disabled: true,
        order: 10,
        subTasks: [],
        taskBlueprint: {
          name: 'Anticipated Mod Effective Date',
          description: 'Anticipated Mod Effective Date',
          type: 'date',
          additionalInfo: {
            hasTitle: true,
            formatPattern: 'MM/DD/YYYY',
            styleName: 'accountServices',
          },
        },
        id: 'ANT_MOD_EFFD',
        visibility: true,
      },
      {
        disabled: true,
        order: 11,
        subTasks: [],
        taskBlueprint: {
          name: 'REJECT REASON',
          description: 'REJECT REASON',
          type: 'text',
          additionalInfo: {
            customType: 'multi-line',
            styleName: 'commentBox-as',
            rows: 6,
            columns: 75,
            hasTitle: true,
            maxLength: 200,
          },
        },
        id: 'REJECT_REASON',
        visibility: false,
      },
      ],
      taskBlueprint: {
        disabled: false,
        name: 'Current Payoff',
        description: 'Current Payoff',
        type: 'task-section',
        additionalInfo: {
          styleName: 'border-as',
          hasTitle: false,
          operations: {
            preProcess: 'GATHER_ESCROW_DATA',
          },
        },
      },
      id: 'CURRENT_PAYOFF',
      visibility: true,
    },
    {
      id: 'TAX_INS_GRID',
      title: 'Taxes and Insurances grid',
      taskBlueprint: {
        additionalInfo: {
          styleName: 'accountServicesLeftGrid',
          customType: 'grid',
          labels: ['Initial Escrow Calculation', 'Request Information'],
          hasTitle: false,
          columnSize: [6, 6],
          labelSize: [6, 6],
          columns: 2,
        },
        type: 'task-section',
        taskCode: 'tax_ins_grid',
      },
      failureReason: null,
      disabled: false,
      visibility: true,
      subTasks: [
        {
          disabled: false,
          order: 0,
          subTasks: [
            {
              disabled: true,
              order: 0,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Month Start',
                description: 'Month Start',
                type: 'date',
                additionalInfo: {
                  hasTitle: true,
                  formatPattern: 'DD MMM YYYY',
                  styleName: 'accountServices',
                },
              },
              id: 'MON_SRT_DATE',
              visibility: true,
            },
            {
              disabled: true,
              order: 1,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Month End',
                description: 'Month End',
                type: 'date',
                additionalInfo: {
                  hasTitle: true,
                  formatPattern: 'DD MMM YYYY',
                  styleName: 'accountServices',
                },
              },
              id: 'MON_END_DATE',
              visibility: true,
            },
            {
              disabled: true,
              order: 2,
              subTasks: [],
              taskBlueprint: {
                name: 'Taxes',
                description: 'Taxes',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-as',
                  customType: 'currency',
                  format: 'currency',
                  additionalElements: ['adornment'],
                  adornment: '$',
                  position: 'start',
                  hasTitle: true,
                  textAlign: 'left',
                  source: 'accountService',
                },
              },
              id: 'TAXES',
              visibility: true,
            },
            {
              disabled: true,
              order: 3,
              subTasks: [],
              taskBlueprint: {
                name: 'Hazard Insurance',
                description: 'Hazard Insurance',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-as',
                  customType: 'currency',
                  format: 'currency',
                  additionalElements: ['adornment'],
                  adornment: '$',
                  position: 'start',
                  hasTitle: true,
                  textAlign: 'left',
                  source: 'accountService',
                },
              },
              id: 'HAZ_INS',
              visibility: true,
            },
            {
              disabled: true,
              order: 4,
              subTasks: [],
              taskBlueprint: {
                name: 'Mortgage Insurance',
                description: 'Mortgage Insurance',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-as',
                  customType: 'currency',
                  format: 'currency',
                  additionalElements: ['adornment'],
                  adornment: '$',
                  position: 'start',
                  hasTitle: true,
                  textAlign: 'left',
                  source: 'accountService',
                },
              },
              id: 'MORT_INS',
              visibility: true,
            },
            {
              disabled: true,
              order: 5,
              subTasks: [],
              taskBlueprint: {
                name: 'Shortage',
                description: 'Shortage',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-as',
                  customType: 'currency',
                  format: 'currency',
                  additionalElements: ['adornment'],
                  adornment: '$',
                  position: 'start',
                  hasTitle: true,
                  textAlign: 'left',
                  source: 'accountService',
                },
              },
              id: 'SHORTAGE',
              visibility: true,
            },
            {
              disabled: true,
              order: 6,
              subTasks: [],
              taskBlueprint: {
                name: 'Total',
                description: 'Total',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-as',
                  customType: 'currency',
                  format: 'currency',
                  additionalElements: ['adornment'],
                  adornment: '$',
                  position: 'start',
                  hasTitle: true,
                  textAlign: 'left',
                  source: 'accountService',
                },
              },
              id: 'TOTAL',
              visibility: true,
            },
            {
              taskBlueprint: {
                additionalInfo: {
                  hasTitle: true,
                  styleName: 'escrowInfo',
                },
                name: 'Escrow Information',
                description: 'Escrow Information',
                title: '',
                type: 'task-section',
              },
              order: 7,
              disabled: false,
              subTasks: [
                {
                  disabled: false,
                  order: 0,
                  subTasks: [
                    {
                      disabled: true,
                      order: 0,
                      subTasks: [],
                      taskBlueprint: {
                        disabled: true,
                        name: 'Force Escrow',
                        description: 'Force Escrow',
                        type: 'dropdown',
                        additionalInfo: {
                          hasTitle: true,
                          styleName: 'months-as',
                          options: [
                            { displayName: 'Yes', value: 'Yes' },
                            { displayName: 'No', value: 'No' },
                          ],
                        },
                      },
                      value: 'No',
                      id: 'FORCE_ESC',
                      visibility: true,
                    },
                    {
                      disabled: true,
                      order: 1,
                      subTasks: [],
                      taskBlueprint: {
                        disabled: true,
                        name: 'Escrow',
                        description: 'Escrow',
                        type: 'dropdown',
                        additionalInfo: {
                          hasTitle: true,
                          styleName: 'months-as',
                          options: [
                            { displayName: 'Yes', value: 'Yes' },
                            { displayName: 'No', value: 'No' },
                          ],
                        },
                      },
                      value: 'Yes',
                      id: 'ESCROW',
                      visibility: true,
                    },
                    {
                      disabled: true,
                      order: 2,
                      subTasks: [],
                      taskBlueprint: {
                        name: 'Total Escrow Shortage',
                        description: 'Total Escrow Shortage',
                        type: 'text',
                        additionalInfo: {
                          styleName: 'se-nom-as',
                          customType: 'currency',
                          format: 'currency',
                          additionalElements: ['adornment'],
                          adornment: '$',
                          position: 'start',
                          hasTitle: true,
                          textAlign: 'left',
                          source: 'accountService',
                        },
                      },
                      id: 'TOTAL_ESC_SHORT',
                      visibility: true,
                    },
                    {
                      disabled: true,
                      order: 3,
                      subTasks: [],
                      taskBlueprint: {
                        name: 'Monthly Shortage Amount',
                        description: 'Monthly Shortage Amount',
                        type: 'text',
                        additionalInfo: {
                          styleName: 'se-nom-as',
                          customType: 'currency',
                          format: 'currency',
                          additionalElements: ['adornment'],
                          adornment: '$',
                          position: 'start',
                          hasTitle: true,
                          textAlign: 'left',
                          source: 'accountService',
                        },
                      },
                      id: 'MONTH_SHORT_AMT',
                      visibility: true,
                    },
                    {
                      disabled: true,
                      order: 4,
                      subTasks: [],
                      taskBlueprint: {
                        name: 'Estimated Escrow Disbursement',
                        description: 'Estimated Escrow Disbursement',
                        type: 'text',
                        additionalInfo: {
                          styleName: 'se-nom-as',
                          customType: 'currency',
                          format: 'currency',
                          additionalElements: ['adornment'],
                          adornment: '$',
                          position: 'start',
                          hasTitle: true,
                          textAlign: 'left',
                          source: 'accountService',
                        },
                      },
                      id: 'EST_ESC_DISC',
                      visibility: true,
                    },
                    {
                      disabled: true,
                      order: 5,
                      subTasks: [],
                      taskBlueprint: {
                        disabled: false,
                        name: 'Mod Effective Date',
                        description: 'Mod Effective Date',
                        type: 'date',
                        additionalInfo: {
                          hasTitle: true,
                          formatPattern: 'DD MMM YYYY',
                          styleName: 'accountServices',
                        },
                      },
                      id: 'MOD_EFF_DATE',
                      visibility: true,
                    },
                  ],
                  taskBlueprint: {
                    disabled: false,
                    name: 'Escrow Information',
                    description: 'Escrow Information',
                    type: 'task-section',
                    additionalInfo: {
                      hasTitle: false,
                      operations: {
                        preProcess: 'GATHER_ESCROW_DATA',
                      },
                    },
                  },
                  id: 'ESC_INFO_SEC',
                  visibility: true,
                },
              ],
              isVisible: true,
              id: 'ESCROW_INFO_SECTION',
            },
          ],
          taskBlueprint: {
            disabled: false,
            name: 'Initial Escrow Calculation',
            description: 'Initial Escrow Calculation',
            type: 'task-section',
            additionalInfo: {
              hasTitle: false,
              operations: {
                preProcess: 'GATHER_ESCROW_DATA',
              },
            },
          },
          id: 'INI_ESC_CALC_SEC',
          visibility: true,
        },
        {
          disabled: false,
          order: 1,
          subTasks: [
            {
              disabled: true,
              order: 0,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Request Type',
                description: 'Request Type',
                type: 'checkbox',
                additionalInfo: {
                  hasTitle: true,
                },
                options: [
                  {
                    displayName: 'Delinquent Taxes',
                    value: 'Delinquent Taxes',
                    hint: '',
                  },
                  {
                    displayName: 'Escrow',
                    value: 'Escrow',
                    hint: '',
                  },
                  {
                    displayName: 'Legal Fees',
                    value: 'Legal Fees',
                    hint: '',
                  },
                  {
                    displayName: 'All',
                    value: 'All',
                    hint: '',
                  },
                ],
              },
              id: 'REQ_TYPE_CB',
              visibility: true,
            },
            {
              disabled: true,
              order: 1,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Request Date',
                description: 'Request Date',
                type: 'date',
                additionalInfo: {
                  hasTitle: true,
                  formatPattern: 'DD MMM YYYY',
                  styleName: 'accountServices',
                },
              },
              id: 'REQ_DATE',
              visibility: true,
            },
            {
              disabled: true,
              order: 2,
              subTasks: [],
              taskBlueprint: {
                name: 'Requested By',
                description: 'Requested By',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-text-as',
                  hasTitle: true,
                  textAlign: 'left',
                },
              },
              id: 'REQ_BY',
              visibility: true,
            },
            {
              disabled: true,
              order: 3,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Completed Date',
                description: 'Completed Date',
                type: 'date',
                additionalInfo: {
                  hasTitle: true,
                  formatPattern: 'DD MMM YYYY',
                  styleName: 'accountServices',
                },
              },
              id: 'COMPL_DATE',
              visibility: true,
            },
            {
              disabled: true,
              order: 4,
              subTasks: [],
              taskBlueprint: {
                name: 'Completed By',
                description: 'Completed By',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-text-as',
                  hasTitle: true,
                  textAlign: 'left',
                },
              },
              id: 'COMPL_BY',
              visibility: true,
            },
            {
              disabled: true,
              order: 5,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Delinquent on property taxes ?',
                description: 'Delinquent on property taxes ?',
                type: 'radio',
                additionalInfo: {
                  hasTitle: true,
                  styleName: 'accountServices',
                },
                options: [
                  {
                    displayName: 'Yes',
                    value: 'Yes',
                    hint: '',
                  },
                  {
                    displayName: 'No',
                    value: 'No',
                    hint: '',
                  },
                ],
              },
              id: 'DEL_ON_PROP_TAX',
              visibility: true,
            },
            {
              disabled: true,
              order: 6,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'If Yes, Estimated how much',
                description: 'If Yes, Estimated how much',
                type: 'text',
                additionalInfo: {
                  styleName: 'se-nom-as',
                  hasTitle: true,
                  textAlign: 'left',
                },
              },
              id: 'EST_PROP_TAX',
              visibility: true,
            },
            {
              disabled: true,
              order: 7,
              subTasks: [],
              taskBlueprint: {
                disabled: false,
                name: 'Request Spread Months',
                description: 'Request Spread Months',
                type: 'dropdown',
                additionalInfo: {
                  hasTitle: true,
                  styleName: 'months-spr-as',
                  options: [
                    { displayName: '12', value: 12 },
                    { displayName: '24', value: 24 },
                    { displayName: '60', value: 60 },
                  ],
                },
              },
              id: 'REQ_SPR_MON',
              visibility: true,
            },
          ],
          taskBlueprint: {
            disabled: false,
            name: 'Request Information',
            description: 'Request Information',
            type: 'task-section',
            operations: {
              preProcess: 'GATHER_ESCROW_DATA',
            },
            additionalInfo: {
              hasTitle: false,
              operations: {
                preProcess: 'GATHER_ESCROW_DATA',
              },
            },
          },
          id: 'REQ_INFO_SEC',
          visibility: true,
        },
      ],
    },
  ],
  failureReason: null,
  disabled: false,
  visibility: true,
}];

const analysisBreakdownTemplate = [{
  additionalInfo: {
    hasTitle: true,
    styleName: 'ana-brk-title',
    actionIcon: 'close',
    iconPosition: 'right',
    horizontalRule: true,
    actions: {
      postProcess: 'app/accountServices/TOGGLE_ANALYSIS_BREAKDOWN',
    },
  },
  disabled: false,
  subTasks: [
    {
      disabled: false,
      id: 'ANA_BRK_GRID',
      order: 0,
      subTasks: [
        {
          disabled: false,
          order: 1,
          subTasks: [{
            disabled: true,
            order: 0,
            subTasks: [],
            taskBlueprint: {
              name: 'Projected Low Point - Amount',
              description: 'Projected Low Point - Amount',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'PLP_AMT',
            visibility: true,
          },
          {
            disabled: true,
            order: 1,
            subTasks: [],
            taskBlueprint: {
              name: 'Projected Low Point - Date',
              description: 'Projected Low Point - Date',
              type: 'text',
              additionalInfo: {
                hasTitle: true,
                styleName: 'ana-brk',
                textAlign: 'left',
              },
            },
            id: 'EA_CURR_INT_DUE',
            visibility: true,
          },
          {
            disabled: true,
            order: 2,
            subTasks: [],
            taskBlueprint: {
              name: 'Monthly Taxes',
              description: 'Monthly Taxes',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'MON_TAX',
            visibility: true,
          },
          {
            disabled: true,
            order: 3,
            subTasks: [],
            taskBlueprint: {
              name: 'Monthly Insurance',
              description: 'Monthly Insurance',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'MON_INS',
            visibility: true,
          },
          {
            disabled: true,
            order: 4,
            subTasks: [],
            taskBlueprint: {
              name: 'Monthly MI',
              description: 'Monthly MI',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'MON_MI',
            visibility: true,
          },
          {
            disabled: true,
            order: 5,
            subTasks: [],
            taskBlueprint: {
              name: 'Projected Shortage',
              description: 'Projected Shortage',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'PRO_SHR',
            visibility: true,
          },
          {
            disabled: true,
            order: 6,
            subTasks: [],
            taskBlueprint: {
              name: 'Projected Spread Months',
              description: 'Projected Spread Months',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                hasTitle: true,
                textAlign: 'left',
              },
            },
            id: 'PRO_SPR_MON',
            visibility: true,
          },
          {
            disabled: true,
            order: 7,
            subTasks: [],
            taskBlueprint: {
              name: 'Projected Spread Amount',
              description: 'Projected Spread Amount',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'PRO_SPR_AMT',
            visibility: true,
          },
          {
            disabled: true,
            order: 8,
            subTasks: [],
            taskBlueprint: {
              disabled: false,
              name: 'Total Monthly Escrow Payment',
              description: 'Total Monthly Escrow Payment',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'TOT_ESC_PAY',
            visibility: true,
          },
          {
            disabled: true,
            order: 9,
            subTasks: [],
            taskBlueprint: {
              name: 'Disbursement',
              description: 'Disbursement',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'ESC_DISB',
            visibility: true,
          },
          {
            disabled: true,
            order: 10,
            subTasks: [],
            taskBlueprint: {
              disabled: false,
              name: 'Escrow Balance',
              description: 'Escrow Balance',
              type: 'text',
              additionalInfo: {
                styleName: 'ana-brk',
                customType: 'currency',
                format: 'currency',
                additionalElements: ['adornment'],
                adornment: '$',
                position: 'start',
                hasTitle: true,
                textAlign: 'left',
                source: 'accountService',
              },
            },
            id: 'ESC_BAL',
            visibility: true,
          },
          ],
          taskBlueprint: {
            disabled: false,
            name: 'Current Payoff',
            description: 'Current Payoff',
            type: 'task-section',
            additionalInfo: {
              hasTitle: false,
            },
          },
          id: 'CURR_PAY',
          visibility: true,
        },
        {
          disabled: false,
          order: 1,
          subTasks: [
          ],
          taskBlueprint: {
            disabled: false,
            name: 'Divider',
            description: 'Divider',
            type: 'divider',
            additionalInfo: {
              hasTitle: false,
            },
          },
          id: 'DIVI',
          visibility: true,
        },
        {
          disabled: false,
          order: 1,
          subTasks: [
            {
              disabled: true,
              order: 0,
              subTasks: [],
              taskBlueprint: {
                name: 'Disbursement Table Data',
                description: 'Disbursement Table Data',
                type: 'table',
                additionalInfo: {
                  styleName: 'ana-brk-table',
                  columnsHeaders: [
                    {
                      name: 'month',
                      label: 'Month',
                      align: 'left',
                    },
                    {
                      name: 'tax',
                      label: 'Tax',
                      align: 'left',
                    },
                    {
                      name: 'insurance',
                      label: 'Insurance',
                      align: 'left',
                    },
                    {
                      name: 'mi',
                      label: 'MI',
                      align: 'left',
                    },
                  ],
                  hasTitle: false,
                },
              },
              id: 'DIS_TBL',
              visibility: true,
            },
          ],
          taskBlueprint: {
            disabled: false,
            name: 'Future Disbursements',
            description: 'Future Disbursements',
            type: 'task-section',
            additionalInfo: {
              hasTitle: false,
            },
          },
          id: 'FUT_DIS',
          visibility: true,
        },
      ],
      taskBlueprint: {
        description: 'Analysis Breakdown',
        name: 'Analysis Breakdown',
        type: 'task-section',
        additionalInfo: {
          styleName: 'currPayOff',
          customType: 'grid',
          labels: ['Current Payoff', '', 'Future Disbursements'],
          hasTitle: false,
          columnSize: [5, 1, 6],
          labelSize: [5, 1, 6],
          columns: 2,
          operations: {
            preProcess: 'GATHER_ANALYSIS_BREAKDOWN_DATA',
          },
        },
      },
      visibility: true,
    }],
  isVisible: true,
  id: 'ESC_ANA_BRK',
  title: 'Escrow Analysis Breakdown',
  type: 'task-section',
}];

const accountServiceTemplate = {
  loanDetailsTemplate,
  escrowOrderingTemplate,
  analysisBreakdownTemplate,
};

export default accountServiceTemplate;
