
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import * as moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const LightTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[0],
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const BULK_STATUS = 'Bulk Status';
export const BULK_ACTION = 'Bulk Action';
export const FORCE_CREATE_CASE = 'Force Create Case';
export const FORCE_CREATE_EVAL_CASE = 'Force Create Eval Case';
export const EVAL_DISASTER_ID_REQUEST = 'Eval-DisasterId Request';
export const UNREJECT_REQUEST = 'UnReject Request';
export const SUB_STATUS_REQUEST = 'Substatus Request';
export const DEFAULT_ROWS_PER_PAGE = 50;
export const caseOptions = ['Standard Eval', 'Pre-Approved', 'State Eval Case', 'Disaster Case'];
export const varifyOption = ['standard-eval', 'disaster-case', 'pandemic-eval', 'state-eval-case'];
export const varifyName = ['evaluationRules', 'caseRules'];

export const historyColumns = {
  [BULK_STATUS]: type => [
    {
      name: 'createdDate',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'serviceNowTicket',
      label: 'ServiceNow Ticket',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'businessKeyVal',
      label: type === 'case' ? 'Case ID' : 'Eval ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requestorId',
      label: 'Requestor ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'fromStatus',
      label: 'From Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'toStatus',
      label: 'To Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: 'comments',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
    },
    {
      name: 'failReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
  ],
  [UNREJECT_REQUEST]: type => [
    {
      name: 'createdDate',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'serviceNowTicket',
      label: 'ServiceNow Ticket',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'businessKeyVal',
      label: type === 'case' ? 'Case ID' : 'Eval ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requestorId',
      label: 'Requestor ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: 'comments',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
    },
    {
      name: 'failReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
  ],
  [SUB_STATUS_REQUEST]: type => [
    {
      name: 'createdDate',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'serviceNowTicket',
      label: 'ServiceNow Ticket',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'businessKeyVal',
      label: type === 'case' ? 'Case ID' : 'Eval ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requestorId',
      label: 'Requestor ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'toSubStatus',
      label: 'To Substatus',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: 'comments',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
    },
    {
      name: 'failReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
  ],
  [EVAL_DISASTER_ID_REQUEST]: () => [
    {
      name: 'createdDate',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'serviceNowTicket',
      label: 'ServiceNow Ticket',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'businessKeyVal',
      label: 'Eval ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requestorId',
      label: 'Requestor ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'disasterId',
      label: 'Disaster ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: 'comments',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
    },
    {
      name: 'failReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
  ],
  [BULK_ACTION]: (type, isActionSelected) => {
    const defaultColumns = [
      {
        name: 'createdDate',
        label: 'Date',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
      },
      {
        name: 'serviceNowTicket',
        label: 'ServiceNow Ticket',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'bulkAction',
        label: 'Action',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'requestorId',
        label: 'Requestor ID',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'status',
        label: 'Status',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
      },
      {
        name: 'comments',
        label: 'Comments',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (
          // eslint-disable-next-line react/jsx-filename-extension
          <LightTooltip arrow placement="bottom-start" title={value}>
            <Typography variant="h4">{value}</Typography>
          </LightTooltip>
        ),
      },
      {
        name: 'failReason',
        label: 'Reason',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (
          // eslint-disable-next-line react/jsx-filename-extension
          <LightTooltip arrow placement="bottom-start" title={value}>
            <Typography variant="h4">{value}</Typography>
          </LightTooltip>
        ),
      },
    ];
    if (isActionSelected) {
      const IdColumn = {
        name: 'businessKeyVal',
        // eslint-disable-next-line no-nested-ternary
        label: type === 'case' ? 'Case ID' : type === 'eval' ? 'Eval ID' : 'Loan ID',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      };
      const reasonCodeColumn = {
        name: 'reasonCode',
        label: 'Reason Code',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      };
      defaultColumns.splice(3, 0, IdColumn, reasonCodeColumn);
    }
    return defaultColumns;
  },
  [FORCE_CREATE_EVAL_CASE]: () => [
    {
      name: 'createdDate',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'serviceNowTicket',
      label: 'ServiceNow Ticket',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'businessKeyVal',
      label: 'Loan ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requestorId',
      label: 'Requestor ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'evalType',
      label: 'Eval Type',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'evaluationType',
      label: 'Approval Type',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'rejectionRule',
      label: 'Rejection Rules',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: 'comments',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
    {
      name: 'status',
      label: 'Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
    },
    {
      name: 'failReason',
      label: 'Reason',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
  ],
  [FORCE_CREATE_CASE]: (type, isActionSelected) => {
    const defaultColumns = [
      {
        name: 'createdDate',
        label: 'Date',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
      },
      {
        name: 'serviceNowTicket',
        label: 'ServiceNow Ticket',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'requestorId',
        label: 'Requestor ID',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'caseType',
        label: 'Case Type',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'comments',
        label: 'comments',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (
          // eslint-disable-next-line react/jsx-filename-extension
          <LightTooltip arrow placement="bottom-start" title={value}>
            <Typography variant="h4">{value}</Typography>
          </LightTooltip>
        ),
      },
      {
        name: 'status',
        label: 'Status',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (<span style={{ color: value === 1 ? '#13b28d' : '#e70f37' }}>{(value === 1 ? 'Success' : 'Unsuccessful')}</span>),
      },
      {
        name: 'failReason',
        label: 'Reason',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
        cellFormat: value => (
          // eslint-disable-next-line react/jsx-filename-extension
          <LightTooltip arrow placement="bottom-start" title={value}>
            <Typography variant="h4">{value}</Typography>
          </LightTooltip>
        ),
      },
    ];
    if (isActionSelected) {
      const IdColumn = {
        name: 'businessKeyVal',
        label: type === 'loan' ? 'Loan ID' : 'Eval ID',
        align: 'left',
        options: {
          filter: false,
          sort: false,
        },
      };
      defaultColumns.splice(3, 0, IdColumn);
    }
    return defaultColumns;
  },
  default: [
    {
      name: 'createdDate',
      label: 'Date',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'bulkAction',
      label: 'Action',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'serviceNowTicket',
      label: 'ServiceNow Ticket',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'requestorId',
      label: 'Request ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'comments',
      label: 'Comments',
      align: 'left',
      options: {
        filter: false,
        sort: false,
      },
      cellFormat: value => (
        // eslint-disable-next-line react/jsx-filename-extension
        <LightTooltip arrow placement="bottom-start" title={value}>
          <Typography variant="h4">{value}</Typography>
        </LightTooltip>
      ),
    },
  ],
};


export const enumActionTypeMap = Object.freeze({
  [BULK_STATUS]: 'BULK_STATUS',
  [BULK_ACTION]: 'BULK_ACTION',
  [FORCE_CREATE_CASE]: 'FORCE_CREATE_CASE',
  [FORCE_CREATE_EVAL_CASE]: 'FORCE_CREATE_EVAL_CASE',
  [EVAL_DISASTER_ID_REQUEST]: 'EVAL_DISASTER_ID_REQUEST',
  [UNREJECT_REQUEST]: 'UNREJECT_REQUEST',
  [SUB_STATUS_REQUEST]: 'SUB_STATUS_REQUEST',
});


export const enumIdTypeMap = Object.freeze({
  case: 'CASE',
  eval: 'EVAL',
  loan: 'LOAN',
  '': 'EMPTY',
});

export const setDefaultIdType = Object.freeze({
  [BULK_STATUS]: 'case',
  [BULK_ACTION]: '',
  [FORCE_CREATE_CASE]: '',
  [FORCE_CREATE_EVAL_CASE]: 'loan',
  [EVAL_DISASTER_ID_REQUEST]: 'eval',
  [UNREJECT_REQUEST]: 'case',
  [SUB_STATUS_REQUEST]: 'case',
});
