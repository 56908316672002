/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import './RejectReasonPopup.css';
import { connect } from 'react-redux';
import { operations as repaymentEvalOperations, selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import * as R from 'ramda';
import { REASON_CODE_VALID_INPUT } from '../../../constants/eval';

const RejectReasonPopup = (props) => {
  const {
    isOpen, handleClose, rejectReasons,
    fetchRejectReasons,
    rejectCaseRulesOperation,
  } = props;

  const [selectedOption, setSelectedOption] = useState('');
  const [reasonIdInput, setReasonIdInput] = useState('');

  useEffect(() => {
    fetchRejectReasons({ resolutionTypeId: 1 });
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const handleRejectOk = () => {
    rejectCaseRulesOperation({ caseId: 1, rejectId: selectedOption });
    handleClose();
  };

  const handleKeyDown = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      setReasonIdInput('');
    } else if (key === 'Backspace') {
      if (reasonIdInput.length > 0) {
        setReasonIdInput(reasonIdInput.slice(0, -1));
      }
    } else if (key in REASON_CODE_VALID_INPUT) {
      setReasonIdInput(reasonIdInput + key);
    }
  };

  const filterOptionsByPrefix = () => {
    if (reasonIdInput === '') {
      return rejectReasons;
    }

    return rejectReasons.filter(reason => R.pathOr('', ['reasonId'], reason).toString().startsWith(reasonIdInput));
  };


  return (
    <Grid>
      <Dialog open={isOpen}>
        <DialogTitle>
          {' '}
          <Grid container item styleName="reject-reason-pop-grid-title">
            <Grid>
              <Typography styleName="reject-reason-pop-grid-title-name">
                Reject Reason
              </Typography>
            </Grid>
            <Grid>
              <IconButton onClick={handleClose} styleName="reject-reason-close-icon ">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ overflow: 'hidden' }}>
            <Grid item styleName="reason-label" xs={3}>
              <div>
                Reason
              </div>
            </Grid>
            <Grid item style={{ overflow: 'hidden' }} xs={9}>
              <div onKeyDown={handleKeyDown}>
                <Select
                  fullWidth
                  input={<OutlinedInput name="selectedRejectedReason" />}
                  label="Select an option"
                  onChange={handleOptionChange}
                  styleName="reject-reason-dropDown"
                  value={selectedOption}
                >
                  {rejectReasons && filterOptionsByPrefix().map(({ reason, reasonId }) => (
                    <MenuItem key={reasonId} value={reasonId}>{`${reasonId}_${reason}`}</MenuItem>
                  ))
                  }
                </Select>
              </div>

            </Grid>
          </Grid>
          {selectedOption ? (
            <>
              <Divider style={{ marginBottom: '8px' }} />
              <div styleName="reject-reason-bottom">
                <Button color="primary" onClick={handleRejectOk} styleName="reject-reason-button" variant="outlined">
                  OK
                </Button>
              </div>
            </>

          ) : ''}
        </DialogContent>
      </Dialog>


    </Grid>
  );
};
RejectReasonPopup.defaultProps = {
  handleClose: () => { },
};

RejectReasonPopup.propTypes = {
  fetchRejectReasons: PropTypes.func.isRequired,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  rejectCaseRulesOperation: PropTypes.func.isRequired,
  rejectReasons: PropTypes.shape().isRequired,

};

const mapStateToProps = state => ({
  rejectReasons: repaymentEvalSelectors.getRejectReasons(state),
});

const mapDispatchToProps = dispatch => ({
  fetchRejectReasons: repaymentEvalOperations.fetchResolutionRejectReasonData(dispatch),
  rejectCaseRulesOperation: repaymentEvalOperations.rejectCaseRulesOperation(dispatch),
});

const RejectReasonPopUp = connect(mapStateToProps, mapDispatchToProps)(RejectReasonPopup);

export default RejectReasonPopUp;
