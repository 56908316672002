import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/tombstone';
import PropTypes from 'prop-types';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { operations as accountServiceOperations } from 'ducks/accountService';
import './ViewIcon.css';
import { ACCOUNT_SERVICE_EVENT_TYPES } from 'constants/common';
import { ACCOUNT_SERVICE } from 'constants/loanInfoComponents';
import CFPBTableContent from '../CFPBTableContent';


class ViewIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  handleCenterPaneView = () => {
    const {
      getCFPBTableData, loanNumber,
    } = this.props;
    this.setState({
      isDialogOpen: true,
    });
    getCFPBTableData(loanNumber);
  }

  handleCapModClick = () => {
    const {
      gatherEscrowDataOperation,
      getHistoryItems,
      value,
      storeCapModId,
      setChecklistCenterPaneData,
      storeLoanNumber,
      loanNumber,
    } = this.props;
    storeCapModId(value);
    storeLoanNumber(loanNumber);
    getHistoryItems();
    gatherEscrowDataOperation(ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
    setChecklistCenterPaneData(ACCOUNT_SERVICE);
  }

  handleCloseDialog = (isClosed) => {
    this.setState({
      isDialogOpen: isClosed,
    });
  }

  getStyles = () => {
    const { loanInfoComponent, group } = this.props;
    if (loanInfoComponent === 'Delinquency Start Date') {
      const isMilestoneActivityPage = group === 'MA';
      return isMilestoneActivityPage ? 'icon-view-disabled' : 'icon-view';
    }
    if (loanInfoComponent === 'Cap Mod') {
      return 'icon-view';
    }
    return 'default';
  }

  render() {
    const { isDialogOpen } = this.state;
    const { loanInfoComponent } = this.props;
    return (
      <>
        <IconButton onClick={loanInfoComponent === 'Delinquency Start Date' ? this.handleCenterPaneView : this.handleCapModClick} size="small" styleName={`${this.getStyles()}`}>
          <VisibilityIcon />
        </IconButton>
        {isDialogOpen && (
          <CFPBTableContent
            handleClose={this.handleCloseDialog}
            show={isDialogOpen}
          />
        )
        }
      </>
    );
  }
}

ViewIcon.defaultProps = {
  getCFPBTableData: () => { },
  value: null,
};

ViewIcon.propTypes = {
  gatherEscrowDataOperation: PropTypes.func.isRequired,
  getCFPBTableData: PropTypes.func,
  getHistoryItems: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  loanInfoComponent: PropTypes.string.isRequired,
  loanNumber: PropTypes.number.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  storeCapModId: PropTypes.func.isRequired,
  storeLoanNumber: PropTypes.func.isRequired,
  value: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  getCFPBTableData: operations.getCFPBTableDataOperation(dispatch),
  gatherEscrowDataOperation: accountServiceOperations.gatherEscrowDataOperation(dispatch),
  getHistoryItems: accountServiceOperations.storeAccountServicesHistoryOperation(dispatch),
  storeCapModId: accountServiceOperations.storeCapModId(dispatch),
  storeLoanNumber: accountServiceOperations.storeLoanNumber(dispatch),
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
});

const mapStateToProps = state => ({
  loanNumber: dashboardSelectors.loanNumber(state),
  rfdTableData: selectors.getCFPBTableData(state),
  group: dashboardSelectors.groupName(state),
  tombstoneData: selectors.getTombstoneData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewIcon);
