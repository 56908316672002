/* eslint-disable consistent-return */
import React, { useState, useCallback } from 'react';
import * as PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import { useDropzone } from 'react-dropzone';
import './DropZone.css';
import { IconButton, Button } from '@material-ui/core';
import * as XLSX from 'xlsx';

function DropZone(props) {
  const { handleClose, handleUploadedFiles } = props;
  const [myFiles, setMyFiles] = useState([]);
  const [isFileValid, setIsFileValid] = useState([]);
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setIsFileValid(fileRejections);
    setMyFiles([...myFiles, ...acceptedFiles]);
  }, [myFiles]);
  // eslint-disable-next-line no-unused-vars
  let globalWb = '';

  const fileValidator = (file) => {
    if (file.size > 1000000) {
      return {
        code: 'size-too-large',
        message: 'File is larger than 10MB',
      };
    }
    return null;
  };

  const {
    getRootProps, getInputProps, open, isDragActive, isDragReject,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    validator: fileValidator,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  function toJson(workbook) {
    const result = {};
    workbook.SheetNames.forEach((sheetName) => {
      const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName],
        { header: 1, blankrows: false });
      if (roa.length) result[sheetName] = roa;
    });
    return JSON.stringify(result, 2, 2);
  }

  const processWb = async (wb) => {
    globalWb = wb;
    let output = '';
    output = toJson(wb);
    if (output) {
      output = JSON.parse(output);
      return output;
    }
  };

  const formatFile = async (file) => {
    const reader = new window.FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (e) => {
        const excelFile = e.target.result;
        const workbook = XLSX.read(excelFile, {
          type: 'binary',
        });
        const processedData = processWb(workbook).then(data => data);
        if (processedData) {
          resolve(processedData);
        }
        reader.onerror = reject;
      };
      reader.readAsBinaryString(file);
    });
  };

  const processFile = async (file) => {
    try {
      const parsedData = formatFile(file).then(data => data);
      return parsedData;
    } catch (err) {
      return 'Error';
    }
  };

  const handleUpload = () => {
    const promises = myFiles.map(e => processFile(e));

    Promise.all(promises)
      .then((results) => {
        handleUploadedFiles(results);
        handleClose(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemove = (file) => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };
  const files = myFiles.map(file => (
    <li key={file.path}>
      {file.path}
      <IconButton onClick={() => handleRemove(file)}>
        <CloseIcon />
      </IconButton>
    </li>
  ));

  return (
    <div styleName="dropzone-container">
      <div styleName="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <img alt="Upload" src="/static/img/uploadIcon.png" style={{ width: '40px' }} />
        {isDragReject && (<p>Please upload files with .xlsx format</p>)}
        {!isDragActive && (<p>Drag and drop .xlsx to upload</p>)}
        {isFileValid.length > 0 && (
          <p styleName="error-msg">{isFileValid[0].errors[0].message}</p>
        )}
      </div>
      <div styleName="divider"><span>Or</span></div>
      <Button color="primary" onClick={open} size="large" styleName="browser-btn" type="button" variant="contained">
        BROWSE
      </Button>
      {files.length > 0 && (
        <aside styleName="list-container">
          <ul>{files}</ul>
        </aside>
      )}
      <div styleName="btn-container">
        <Button color="primary" disabled={!myFiles.length} onClick={handleUpload} size="large" type="button" variant="contained">
          UPLOAD
        </Button>
        <Button color="primary" onClick={() => handleClose(false)} size="large" type="button" variant="outlined">
          CANCEL
        </Button>
      </div>
    </div>
  );
}

DropZone.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleUploadedFiles: PropTypes.func.isRequired,
};

export default DropZone;
