import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';

import styles from './ServicerHistory.css';

const ServicerHistory = ({ reportsData = { reportsData: [] } }) => {
  // const {
  //   dartsdata,
  // } = dartsdatas;
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => setIsExpanded(!isExpanded);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
    }
  };
  const reportsDatas = reportsData || [];

  return (
    <>
      <div
        aria-expanded={isExpanded}
        className={styles.accordion}
        onClick={toggleAccordion}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >

        <div className={styles.accordion_content}>
          <div className={styles.accordion_title}>
           Servicer Guidance
          </div>
          <div>
            <img
              alt="toggle"
              height="7"
              src="/static/img/downArrow.svg"
              style={{ width: '14px' }}
            />
          </div>
        </div>
      </div>
      {isExpanded && (
      // <ServicerHistoryType dartsdata={dartsdata} />
      <TableContainer component={Paper} style={{ display: 'flex', width: '90%', marginLeft: '37px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeader}>ID</TableCell>
              <TableCell className={styles.tableHeader}>Message</TableCell>
              <TableCell className={styles.tableHeader}>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsDatas.map(({ messageId, message, result }) => (
              <TableRow>
                <TableCell className={styles.tableBody}>{messageId}</TableCell>
                <TableCell className={styles.tableBody}>{message}</TableCell>
                <TableCell className={result === '' ? styles.pass_message : styles.fail_message}>{result === '' ? 'Passed' : 'Failed'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
    </>
  );
};

ServicerHistory.propTypes = {
  reportsData: PropTypes.shape({
    Message: PropTypes.string.isRequired,
    MessageID: PropTypes.string.isRequired,
    Result: PropTypes.string.isRequired,
  }).isRequired,
};


export default ServicerHistory;
