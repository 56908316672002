/* eslint-disable import/prefer-default-export */
import {
  all, put, takeEvery, select, call,
} from 'redux-saga/effects';
import * as Api from 'lib/Api';
import * as R from 'ramda';
import { SET_RESULT_OPERATION } from 'ducks/dashboard/types';
import { ERROR } from 'constants/common';
import { selectors as repaymentEvalSelectors } from 'ducks/repaymentEval';
import { selectors as evalSelector } from 'ducks/eval';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as loginSelectors } from 'ducks/login';
import { VALID_LOAN_CHNG_TYPES } from 'constants/eval';
import { getCaseAprvStatus, getChangeEffects } from 'lib/CustomFunctions/Repayment/processPaymentData';
import { LOSS_MITIGATION_MGR } from '../../../constants/Groups';
import { SET_SNACK_BAR_VALUES } from '../notifications/types';
import {
  SHOW_LOADER, HIDE_LOADER,
  UPDATE_REPAY_TRACKPAYMENT_DATES,
  SET_PAST_DUE_PAYMENT_DETAILS,
  FETCH_MONTHLY_PAYMENT_DETAILS,
  SET_MONTHLY_PAYMENT_DETAILS,
  RULE_CHECK_SAGA,
  SET_REPAYMENT_EVAL_ERROR_MSG,
  LOCK_CALC,
  ENABLE_CALC,
  FETCH_PAYMENT_CHANGES_DETAILS,
  SET_PAYMENT_CHANGES_DETAILS,
  SET_LOCK_FLAG,
  SEND_FORAPPROVAL_RULES,
  SEND_FORAPPROVAL,
  REJECT_CASE_RULES,
  FETCH_REJECT_REASONS,
  SET_REJECT_REASONS,
  SET_IS_CASE_REJECTED,
  CALCULATE_REPAYMENT_DATA,
} from './types';
import { DateFormatter } from '../../../lib/DateUtils';


function* updateRepayTrackPaymentDates(action) {
  console.log(action);
  yield put({ type: SHOW_LOADER });
  try {
    // TODO -make api call to update trackpayment dates
    const response = {};
    if (response && response.errors) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Trackpayments',
          status: 'Failed to update Track payment dates',
        },
      });
    } else {
      // TODO -set trackpayments data from response
      console.log(response);
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Trackpayments',
        status: 'Failed to update Track payment dates',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* ruleCheck() {
  try {
    const errorResponse = 'Loan does not meet eligibility requirements due to scheduled sale date';
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: !R.isEmpty(errorResponse) ? errorResponse : '',
    });
    yield put({
      type: ENABLE_CALC,
      payload: !R.isEmpty(errorResponse),
    });
  } catch (e) {
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: 'Something went wrong while making a rule check',
    });
  }
}

function* calculateRepayment(action) {
  const actionData = R.pathOr(null, ['payload'], action);
  try {
    const monthlyPaymentDetails = yield select(repaymentEvalSelectors.getMonthlyPaymentDetails);
    const universalFieldValues = [];
    const caseSpecificFieldValues = [];
    if (actionData) {
      Object.keys(actionData).forEach((key) => {
        if (monthlyPaymentDetails[key]) {
          monthlyPaymentDetails[key].columnVal = actionData[key];
          universalFieldValues.push(monthlyPaymentDetails[key]);
        }
      });
    }
    if (monthlyPaymentDetails) {
      if (monthlyPaymentDetails.fees) caseSpecificFieldValues.push(monthlyPaymentDetails.fees);
      if (monthlyPaymentDetails.advances) {
        caseSpecificFieldValues.push(monthlyPaymentDetails.advances);
      }
      if (monthlyPaymentDetails.pendingPayment) {
        caseSpecificFieldValues.push(monthlyPaymentDetails.pendingPayment);
      }
      const contractData = yield select(repaymentEvalSelectors.universalActionResp);

      const loanId = yield select(dashboardSelectors.loanNumber);
      const brandId = yield select(dashboardSelectors.brand);

      const calcRequest = {
        ...contractData,
        universalFieldValues,
        caseSpecificFieldValues,
        loanId,
        loanIdString: String(loanId),
        brandId,
      };


      const calcResponse = yield call(Api.callPost, '/api/universal-calc/api/UniversalCalc/calculate', calcRequest);

      if (calcResponse && calcResponse.isSuccessful === true) {
        const snackBar = {};
        snackBar.message = 'Repayment Calculation is Successfull';
        snackBar.type = 'success';
        snackBar.open = true;
        yield put({
          type: SET_SNACK_BAR_VALUES,
          payload: snackBar,
        });
      } else {
        yield put({
          type: SET_REPAYMENT_EVAL_ERROR_MSG,
          payload: 'Failure in Updating Repayment Calculation',
        });
      }
    } else {
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: 'Failure in Repayment Calculation',
      });
    }
  } catch (e) {
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: 'Failure in Repayment Calculation',
    });
  }
}


function* lockRepaymentCalculation(action) {
  const actionData = R.pathOr(null, ['payload'], action);
  try {
    yield put({ type: SET_LOCK_FLAG, payload: 1 });

    const monthlyPaymentDetails = yield select(repaymentEvalSelectors.getMonthlyPaymentDetails);
    const universalFieldValues = [];
    if (actionData) {
      Object.keys(actionData).forEach((key) => {
        if (monthlyPaymentDetails[key]) {
          monthlyPaymentDetails[key].columnVal = actionData[key];
          universalFieldValues.push(monthlyPaymentDetails[key]);
        }
      });
    }

    if (monthlyPaymentDetails) {
      const caseSpecificFieldValues = [
        monthlyPaymentDetails.fees,
        monthlyPaymentDetails.advances,
        monthlyPaymentDetails.pendingPayment,
      ];

      const contractData = yield select(repaymentEvalSelectors.universalActionResp);

      const loanId = yield select(dashboardSelectors.loanNumber);
      const brandId = yield select(dashboardSelectors.brand);

      const lockRequest = {
        ...contractData,
        universalFieldValues,
        caseSpecificFieldValues,
        loanId,
        loanIdString: String(loanId),
        brandId,
      };

      const errorMessage = yield select(repaymentEvalSelectors.getRuleCheckErrorMessage);

      if (!R.isEmpty(errorMessage)) {
        const snackBar = {};
        snackBar.message = 'Cannot Lock calculation due to Rule Check Error';
        snackBar.type = 'error';
        snackBar.open = true;
        yield put({
          type: SET_SNACK_BAR_VALUES,
          payload: snackBar,
        });
        return;
      }

      const lockResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/lock', lockRequest);

      if (lockResponse && lockResponse.isSuccessful === true) {
        const user = yield select(loginSelectors.getUser);
        const email = R.path(['userDetails', 'email'], user);

        const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
        const caseId = R.pathOr(null, ['resolutionId'], caseHeaderInfoData);
        const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
        const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);

        let updateEvalCaseStsPayload;

        if (evalId === null) { // Standalone case
          updateEvalCaseStsPayload = {
            loanId,
            userId: email,
            evalId,
            evalStatus: null,
            evalSubStatus: null,
            caseId,
            caseStatus: 'Locked',
            caseSubStatus: null,
          };
        } else if (evalType === 'Disaster') { // Disaster Repay
          updateEvalCaseStsPayload = {
            loanId,
            userId: email,
            evalId,
            evalStatus: 'Active',
            evalSubStatus: 'Active Forbearance',
            caseId,
            caseStatus: 'Locked',
            caseSubStatus: null,
          };
        }

        const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', updateEvalCaseStsPayload);

        if (updateStsresponse && updateStsresponse.isSuccessful === true) {
          const snackBar = {};
          snackBar.message = 'Repayment Lock Calculation is Successfull';
          snackBar.type = 'success';
          snackBar.open = true;
          yield put({
            type: SET_SNACK_BAR_VALUES,
            payload: snackBar,
          });
        } else {
          yield put({
            type: SET_REPAYMENT_EVAL_ERROR_MSG,
            payload: 'Failure in Updating Eval-Case status',
          });
        }
      } else {
        yield put({ type: SET_LOCK_FLAG, payload: 0 });
        yield put({
          type: SET_REPAYMENT_EVAL_ERROR_MSG,
          payload: 'Failure in Locking calculation',
        });
      }
    }
  } catch (e) {
    yield put({ type: SET_LOCK_FLAG, payload: 0 });
    yield put({
      type: SET_REPAYMENT_EVAL_ERROR_MSG,
      payload: 'Failure in Locking calculation',
    });
  }
}

const mapMonthlyPaymentData = (actionData) => {
  if (actionData) {
    const contractData = R.propOr(null, 'contract', actionData);
    if (contractData) {
      const {
        universalFieldValues, caseSpecificFieldValues,
        ...universalActionResp
      } = contractData;

      const data = {
        fees: R.find(R.propEq('columnName', 'originalFees'))(caseSpecificFieldValues),
        advances: R.find(R.propEq('columnName', 'originalAdvance'))(caseSpecificFieldValues),
        pastDuePayments: R.find(R.propEq('columnName', 'totalContractual'))(caseSpecificFieldValues),
        suspense: R.find(R.propEq('columnName', 'suspenseBalance'))(caseSpecificFieldValues),
        pendingPayment: R.find(R.propEq('columnName', 'pendingPayment'))(caseSpecificFieldValues),
        repaymentTotalAmount: R.find(R.propEq('columnName', 'totalRepayment'))(caseSpecificFieldValues),
        duration: R.find(R.propEq('columnName', 'planDuration'))(universalFieldValues),
        repaymentMonthlyAmount: R.find(R.propEq('columnName', 'monthlyRepaymentAmount'))(universalFieldValues),
        startDate: R.find(R.propEq('columnName', 'caseStartDate'))(universalFieldValues),
        endDate: R.find(R.propEq('columnName', 'lastPlanDueDate'))(universalFieldValues),
        delegationIndicator: R.find(R.propEq('columnName', 'LockedDelegateType'))(universalFieldValues),
        nonDelApprovalDate: R.find(R.propEq('columnName', 'nondelapprovaldate'))(universalFieldValues),
        universalActionResp,
      };

      return data;
    }
  }
  return null;
};

function* fetchMonthlyPaymentDetails() {
  try {
    const caseDetails = yield select(evalSelector.caseHeaderInfoData);
    const caseId = R.pathOr(null, ['resolutionId'], caseDetails);
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    if (response) {
      yield put({
        type: SET_REPAYMENT_EVAL_ERROR_MSG,
        payload: '',
      });
      let mappedData = mapMonthlyPaymentData(response);

      const totalDebt = parseFloat(R.pathOr(0, ['fees', 'columnVal'], mappedData)) + parseFloat(R.pathOr(0, ['advances', 'columnVal'], mappedData)) + parseFloat(R.pathOr(0, ['pastDuePayments', 'columnVal'], mappedData));
      const fundInHouse = totalDebt - parseFloat(R.pathOr(0, ['suspense', 'columnVal'], mappedData));
      const planStart = R.pathOr('', ['startDate', 'columnVal'], mappedData);
      const planEnd = R.pathOr('', ['endDate', 'columnVal'], mappedData);

      let pastDuePayments = [];
      let repaymentMonthlyAmounts = [];
      const cmodCaseDetailsResponse = yield call(Api.callGet, `/api/dataservice/repayment/fetchRepayCaseDetails/${caseId}`);

      if (cmodCaseDetailsResponse) {
        pastDuePayments = R.pathOr([], ['pastDuePayment'], cmodCaseDetailsResponse);

        pastDuePayments = pastDuePayments.map(item => ({
          description: `Past Due Payment ${item.seq}`,
          date: DateFormatter(item.paymentDate, 'DD MMM YYYY'),
          contractualAmount: item.contractualAmount,
        }));

        yield put({
          type: SET_PAST_DUE_PAYMENT_DETAILS,
          payload: pastDuePayments,
        });

        repaymentMonthlyAmounts = R.pathOr([], ['repaymentTotalAmount'], cmodCaseDetailsResponse);

        repaymentMonthlyAmounts = repaymentMonthlyAmounts.map(item => ({
          description: `Repayment Plan ${item.seq}`,
          date: DateFormatter(item.paymentDate, 'DD MMM YYYY'),
          contractualPortion: R.pathOr(0, ['contractualAmount'], item),
          delinqunecyPortion: R.pathOr(0, ['delqPartAmt'], item),
          monthlyRepaymentAmount: R.pathOr(0, ['paymentAmount'], item),
        }));
      }

      mappedData = {
        ...mappedData,
        totalDebt,
        fundInHouse,
        planStart: DateFormatter(planStart, 'DD MMM YYYY'),
        planEnd: DateFormatter(planEnd, 'DD MMM YYYY'),
        repaymentMonthlyAmounts,
      };

      yield put({
        type: SET_MONTHLY_PAYMENT_DETAILS,
        payload: mappedData,
      });
      yield put({
        type: ENABLE_CALC,
        payload: false,
      });
    }
  } catch (e) {
    // console.error('Something went wrong while fetching the monthly payment details', e);
    yield put({
      type: SET_MONTHLY_PAYMENT_DETAILS,
      payload: {},
    });
  }
}

function* fetchPaymentChangeDetails(action) {
  yield put({ type: SHOW_LOADER });
  const loanNbr = yield select(dashboardSelectors.loanNumber);
  const brandNm = yield select(dashboardSelectors.brand);

  try {
    const caseId = R.pathOr(null, ['payload'], action);
    const loanNumber = loanNbr.toString();
    const brandArray = [brandNm];
    const lsamsReq = {
      loanNumber,
      brandArray,
    };
    const lsamsRes = yield call(Api.callPost, '/api/utility/FetchLoanChanges', lsamsReq);
    if (Array.isArray(lsamsRes)) {
      const snackBar = {};
      snackBar.message = 'Exception occured while fetching lsams loan changes';
      snackBar.type = 'error';
      snackBar.open = true;
      yield put({
        type: SET_SNACK_BAR_VALUES,
        payload: snackBar,
      });
    } else if (typeof lsamsRes === 'object') {
      const isSuccess = R.pathOr(false, ['getLoanChangesResult', 'success'], lsamsRes);
      if (isSuccess) {
        const loanChngs = R.pathOr([], ['getLoanChangesResult', 'loanChangeResultSet', 'loanChange'], lsamsRes);
        let validLoanChngs = [];
        validLoanChngs = loanChngs.filter(rec => VALID_LOAN_CHNG_TYPES
          .includes(rec.changeFlag));
        const months = [];
        let paymentChngs = [];
        if (validLoanChngs.length > 0) {
          paymentChngs = validLoanChngs.map((rec) => {
            const newRec = {
              ...rec,
              changeMonth: rec.changeDate.substr(0, 7),
            };
            months.push(newRec.changeMonth);
            return newRec;
          });
          const oltpReqMnths = [...new Set(months)];
          const oltpReq = { caseId, months: oltpReqMnths };
          const oltpRes = yield call(Api.callPost, '/api/dataservice/repayment/fetchCaseContractAmts', oltpReq);
          if (oltpRes && oltpRes.success) {
            let finalPaymentChnges = [];
            const monthlyAmts = oltpRes.monthlyContractData || [];
            finalPaymentChnges = paymentChngs.reduce((acc, rec) => {
              const newRec = { ...rec };
              let status = '';
              status = getCaseAprvStatus(rec.changeDate, oltpRes.caseDt,
                oltpRes.caseApprvDt, oltpRes.caseClosdDt);
              newRec.status = status;
              if (newRec.status !== 'rej') {
                const contractData = monthlyAmts.find(
                  data => rec.changeMonth === data.paymentMnth,
                );
                newRec.newTotalAmt = contractData ? contractData.contractAmt : null;
                acc.push(newRec);
              }
              return acc;
            }, []);
            const sortedChanges = finalPaymentChnges.sort((a, b) => {
              const compareFirst = a.changeFlag.localeCompare(b.changeFlag);
              if (compareFirst !== 0) return compareFirst;
              return a.changeDate.localeCompare(b.changeDate);
            });
            const changesWithEffect = getChangeEffects(sortedChanges);
            const finalChangesWithEffects = changesWithEffect.sort(
              (a, b) => a.changeDate.localeCompare(b.changeDate),
            );
            yield put({
              type: SET_PAYMENT_CHANGES_DETAILS,
              payload: finalChangesWithEffects,
            });
          } else {
            const snackBar = {};
            snackBar.message = 'Failed to fetch loan payment changes';
            snackBar.type = 'error';
            snackBar.open = true;
            yield put({
              type: SET_SNACK_BAR_VALUES,
              payload: snackBar,
            });
          }
        } else {
          yield put({
            type: SET_PAYMENT_CHANGES_DETAILS,
            payload: [],
          });
        }
      } else {
        const snackBar = {};
        snackBar.message = 'Exception occured while fetching lsams loan changes';
        snackBar.type = 'error';
        snackBar.open = true;
        yield put({
          type: SET_SNACK_BAR_VALUES,
          payload: snackBar,
        });
      }
    }
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to fetch payment changes data';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
    yield put({
      type: SET_PAYMENT_CHANGES_DETAILS,
      payload: [],
    });
  }
  yield put({ type: HIDE_LOADER });
}

const fetchValidationMessage = function* fetchValidationMessage(validationMsg) {
  const errors = validationMsg.validationResult.messages;
  let errorMessages = '';
  if (errors.length > 0) {
    if (errors.length === 1) {
      errorMessages = errors[0].detailedMessage ? errors[0].detailedMessage : errors[0].message;
    } else {
      errorMessages = errors.map((error, index) => `${index + 1}. ${error.detailedMessage ? error.detailedMessage : error.message}`).join('\n');
    }

    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'sendforapproval',
        status: errorMessages,
      },
    });
  }
};

function* sendForApprovalRule(action) {
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  try {
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    const contractData = R.propOr(null, 'contract', response);
    if (response && response.isSuccessful && contractData) {
      const brand = yield select(dashboardSelectors.brand);
      const isInterestRateChanged = R.pathOr(null, ['isInterestRateChanged'], contractData);
      const approvalHistory = R.pathOr(null, ['approvalHistory'], contractData);
      const approvalPayload = {
        resolutionId: caseId,
        isInterestRateChanged,
        approvalHistory,
        selectedGroup: 'TEST', // TO DO
      };

      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };
      const ruleResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateSendForApprovalRules', approvalPayload, headers);
      if (ruleResponse && ruleResponse.validationResult
        && ruleResponse.validationResult.isSuccessful) {
        const approvalActionRequest = R.assoc('sentForApproval', 1, contractData);
        const approvalActionResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/sendforapproval', approvalActionRequest);
        if (approvalActionResponse && approvalActionResponse.isSuccessful) {
          const loanId = yield select(dashboardSelectors.loanNumber);
          const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
          const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
          const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);
          let evalCaseStatusPayload;

          if (evalId === null) { // Standalone case
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              caseId,
              caseStatus: 'Sent For Approval',
              caseSubStatus: null,
            };
          } else if (evalType === 'Disaster' || evalType === 'Pandemic') { // Disaster || PandemicRepay
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              evalStatus: 'Active',
              evalSubStatus: 'Active Forbearance',
              caseId,
              caseStatus: 'Sent For Approval',
              caseSubStatus: null,
            };
          }

          const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);

          if (updateStsresponse && updateStsresponse.isSuccessful) {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: 'Success',
                saga: 'sendforapproval',
                status: 'Repayment case Successful for send for approval',
              },
            });
          } else {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: ERROR,
                saga: 'sendforapproval',
                status: 'Failed to Update Eval-Case status for send for approval case',
              },
            });
          }
        } else if (approvalActionResponse && !approvalActionResponse.isSuccessful) {
          yield call(fetchValidationMessage,
            { validationResult: { messages: ruleResponse.messages } });
        }
      } else if (ruleResponse && ruleResponse.validationResult
        && !ruleResponse.validationResult.isSuccessful) {
        yield call(fetchValidationMessage, ruleResponse);
      }
    } else if (response && !response.isSuccessful) {
      yield call(fetchValidationMessage,
        { validationResult: { messages: response.messages } });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'sendforapproval',
        status: 'Failed to update send for approval',
      },
    });
  }
}

function* sendForApproval(action) {
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  try {
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    const contractData = R.propOr(null, 'contract', response);

    const ruleResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/approve', contractData);
    if (ruleResponse && ruleResponse.validationResult
      && ruleResponse.validationResult.isSuccessful) {
      const { resolutionId, isInterestRateChanged, approvalHistory } = contractData;
      const validateApproveCaseRequest = {
        resolutionId,
        isInterestRateChanged,
        approvalHistory,
      };
      const validateApproveCaseResponse = yield call(Api.callPost, '/api/rulesengine/RulesEngine/ValidateApproveCaseRules', validateApproveCaseRequest);
      if (validateApproveCaseResponse && validateApproveCaseResponse.isSuccessful) {
        const user = yield select(loginSelectors.getUser);
        const email = R.path(['userDetails', 'email'], user);
        const loanId = yield select(dashboardSelectors.loanNumber);
        const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
        const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
        const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);
        let evalCaseStatusPayload;

        if (evalId === null) { // Standalone case
          evalCaseStatusPayload = {
            loanId,
            userId: email,
            evalId,
            caseId,
            caseStatus: 'Approved',
            caseSubStatus: null,
          };
        } else if (evalType === 'Disaster' || evalType === 'Pandemic') { // Disaster || PandemicRepay
          evalCaseStatusPayload = {
            loanId,
            userId: email,
            evalId,
            evalStatus: 'Active',
            evalSubStatus: 'Active Forbearance',
            caseId,
            caseStatus: 'Approved',
            caseSubStatus: null,
          };
        }

        const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);

        if (updateStsresponse && updateStsresponse.isSuccessful) {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Success',
              saga: 'Approved',
              status: 'Repayment case Successful for send for approval',
            },
          });
        } else {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: ERROR,
              saga: 'Approved',
              status: 'Failed to Update Eval-Case status for send for approval case',
            },
          });
        }
      } else if (validateApproveCaseResponse && !validateApproveCaseResponse.isSuccessful) {
        yield call(fetchValidationMessage,
          { validationResult: { messages: ruleResponse.messages } });
      }
    } else if (ruleResponse && ruleResponse.validationResult
      && !ruleResponse.validationResult.isSuccessful) {
      yield call(fetchValidationMessage, ruleResponse);
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Approved',
        status: 'Failed to update send for approval',
      },
    });
  }
}

function* getRejectReasons() {
  try {
    const resolutionTypeId = 1;
    const response = yield call(Api.callGet, `/api/dataservice/eval/resolutionRejectReason/${resolutionTypeId}`);
    if (response) {
      yield put({
        type: SET_REJECT_REASONS,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: SET_REJECT_REASONS,
      payload: {
        level: ERROR,
        saga: 'Rejectreason',
        status: 'Service down while fetching reject reason',
      },
    });
  }
}

function* rejectCaseRules(action) {
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  const rejectId = R.pathOr(null, ['payload', 'rejectId'], action);
  const loanId = yield select(dashboardSelectors.loanNumber);
  const brandId = yield select(dashboardSelectors.brand);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  // const userId = yield call(Api.callGet, `/api/tkams/eval/getUserId/${email}`);
  const userId = email;
  const caseHeaderInfo = yield select(evalSelector.caseHeaderInfoData);
  const caseType = R.pathOr(null, ['caseType'], caseHeaderInfo);
  let approvalLevel;
  user.userGroupList.forEach((group) => {
    if (group.includes(LOSS_MITIGATION_MGR)) {
      approvalLevel = 'Manager';
    } else if (group.includes('-vp')) {
      approvalLevel = 'VP';
    } else if (group.includes('-avp')) {
      approvalLevel = 'AVP';
    } else {
      approvalLevel = 'Agent';
    }
  });
  try {
    yield put({ type: SET_IS_CASE_REJECTED, payload: true });
    const response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    const contractData = R.propOr(null, 'contract', response);
    if (response && response.isSuccessful && contractData) {
      const brand = yield select(dashboardSelectors.brand);
      // const isInterestRateChanged = R.pathOr(null, ['isInterestRateChanged'], contractData);
      // const approvalHistory = R.pathOr(null, ['approvalHistory'], contractData);
      const approvalHistory = {
        CaseTableId: null,
        CaseId: caseId,
        CaseType: 'Repayment Plan',
        UId: email,
        Lvl: null,
        ApprovalType: null,
        ActionDate: null,
        CategoryId: null,
        Comment: '124_No Ownership Interest',
        SubComment: null,
        ReSubmit: null,
        ReasonCode: 124,
        SubReasonCode: null,
        ReasonType: null,
        IsManuallyCreated: null,
        SourceId: null,
      };
      const rejectCaseRulesPayload = {
        resolutionId: caseId,
        isInterestRateChanged: false,
        approvalHistory,
        selectedGroup: 'Repayment Plan',
      };

      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };
      const ruleResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateRejectCaseRules', rejectCaseRulesPayload, headers);
      if (ruleResponse && ruleResponse.validationResult
        && ruleResponse.validationResult.isSuccessful) {
        const hampRejectCaseWithReasonReq = {
          caseId,
          dispositionComment: null,
          rejReasonId: rejectId,
          rejSubReasonId: null,
          aprvLvl: approvalLevel,
          userName: email,
          userId,
          caseType,
          loanNumber: loanId,
          brandName: brandId,
        };

        const dispositionResponse = yield call(Api.callPost, '/api/disposition//hampRejectCaseWithReason', hampRejectCaseWithReasonReq);
        const { result } = dispositionResponse;
        if (dispositionResponse && result === 'Success') {
          const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
          const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
          const evalType = R.pathOr(null, ['evalType'], caseHeaderInfoData);
          let evalCaseStatusPayload;

          if (evalId === null) { // Standalone case
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              caseId,
              caseStatus: 'Rejected',
              caseSubStatus: null,
            };
          } else if (evalType === 'Disaster' || evalType === 'Pandemic') { // Disaster || PandemicRepay
            evalCaseStatusPayload = {
              loanId,
              userId: email,
              evalId,
              evalStatus: 'Active',
              evalSubStatus: 'Sent for Reject',
              caseId,
              caseStatus: 'Rejected',
              caseSubStatus: null,
            };
          }

          const updateStsresponse = yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);

          if (updateStsresponse && updateStsresponse.isSuccessful) {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: 'Success',
                saga: 'rejectCaseRules',
                status: 'Reject case rules are updated successfully!',
              },
            });
          } else {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: ERROR,
                saga: 'rejectCaseRules',
                status: 'Failed to Update Eval-Case status for reject case',
              },
            });
            yield put({ type: SET_IS_CASE_REJECTED, payload: false });
          }
        } else if (dispositionResponse && result === 'Failed') {
          yield call(fetchValidationMessage,
            { validationResult: { messages: ruleResponse.messages } });
          yield put({ type: SET_IS_CASE_REJECTED, payload: false });
        }
      } else if (ruleResponse && ruleResponse.validationResult
        && !ruleResponse.validationResult.isSuccessful) {
        yield call(fetchValidationMessage, ruleResponse);
        yield put({ type: SET_IS_CASE_REJECTED, payload: false });
      }
    } else if (response && !response.isSuccessful) {
      yield call(fetchValidationMessage,
        { validationResult: { messages: response.messages } });
      yield put({ type: SET_IS_CASE_REJECTED, payload: false });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'rejectCaseRules',
        status: 'Failed to update reject case rules',
      },
    });
    yield put({ type: SET_IS_CASE_REJECTED, payload: false });
  }
}

function* watchUpdateRepayTrackPaymentDates() {
  yield takeEvery(UPDATE_REPAY_TRACKPAYMENT_DATES, updateRepayTrackPaymentDates);
}

function* watchFetchMonthlyPaymentDetails() {
  yield takeEvery(FETCH_MONTHLY_PAYMENT_DETAILS, fetchMonthlyPaymentDetails);
}

function* watchRuleCheck() {
  yield takeEvery(RULE_CHECK_SAGA, ruleCheck);
}

function* watchLockCalculation() {
  yield takeEvery(LOCK_CALC, lockRepaymentCalculation);
}

function* watchPaymentChangeDetails() {
  yield takeEvery(FETCH_PAYMENT_CHANGES_DETAILS, fetchPaymentChangeDetails);
}

function* watchSendForApproval() {
  yield takeEvery(SEND_FORAPPROVAL_RULES, sendForApprovalRule);
}

function* watchSendApproval() {
  yield takeEvery(SEND_FORAPPROVAL, sendForApproval);
}

function* watchResolutinRejectReason() {
  yield takeEvery(FETCH_REJECT_REASONS, getRejectReasons);
}

function* watchRejectCaseRules() {
  yield takeEvery(REJECT_CASE_RULES, rejectCaseRules);
}

function* watchcalculateRepayment() {
  yield takeEvery(CALCULATE_REPAYMENT_DATA, calculateRepayment);
}


export const combinedSaga = function* combinedSaga() {
  yield all([
    watchLockCalculation(),
    watchRuleCheck(),
    watchUpdateRepayTrackPaymentDates(),
    watchFetchMonthlyPaymentDetails(),
    watchPaymentChangeDetails(),
    watchSendForApproval(),
    watchSendApproval(),
    watchRejectCaseRules(),
    watchResolutinRejectReason(),
    watchcalculateRepayment(),
  ]);
};
