import React, { useState, useEffect } from 'react';
import {
  Button, RadioGroup, Radio, FormControlLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import moment from 'moment-timezone';
import { selectors as loginSelectors } from 'ducks/login';
import EvalTable from '../../../components/Eval/EvalTable/EvalTable';
import { operations, selectors } from '../../../state/ducks/eval';
import './FulfillmentChecklist.css';
import Popup from '../../../components/Popup';
import InputField from '../../../components/InputField';
import SweetAlertBox from '../../../components/SweetAlertBox';

const FulfillmentChecklist = (props) => {
  const {
    fulfillmentChecklist, inProgress, saveFulfillmentChecklist,
    saveFulfillmentList, user, isfflmtEditable,
    fetchFulfillmentChecklist, caseHeaderInfo,

  } = props;

  const [isViewOnly, setViewOnly] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [compData, setCompData] = useState({
    isOpen: false,
    submitPopup: false,
  });
  const [isChange, setIsChange] = useState(false);

  const disableInstructionAndComments = () => {
    const requiredGroupList = ['docsin', 'docsin-mgr', 'postmodstager'];
    const isInclude = currentValue => user.groupList.includes(currentValue);
    setViewOnly(!requiredGroupList.some(isInclude));
  };
  useEffect(() => { disableInstructionAndComments(); }, []);

  useEffect(() => {
    if (caseHeaderInfo) fetchFulfillmentChecklist(caseHeaderInfo);
  }, [caseHeaderInfo]);

  const newObj = (arr) => {
    if (!R.isEmpty(arr)) {
      const data = arr.map(obj => ({
        ...obj, qstnId: obj.qid, // Adding qstnId for backend Integration
      }));
      return data;
    }
    return [];
  };

  useEffect(() => {
    setTableData(newObj(fulfillmentChecklist));
  }, [fulfillmentChecklist]);

  const handleClear = () => {
    setCompData(prev => ({ ...prev, isOpen: true }));
  };

  const handleSave = () => {
    setIsChange(false);
    saveFulfillmentChecklist(tableData);
    setCompData(prev => ({ ...prev, submitPopup: true }));
  };

  const handleClose = () => {
    setCompData(prev => ({ ...prev, isOpen: false, submitPopup: false }));
  };

  const handleConfirm = () => {
    setCompData(prev => ({ ...prev, isOpen: false }));
    setIsChange(false);
    setTableData(newObj(fulfillmentChecklist));
  };

  const handleChange = (event, id) => {
    const index = tableData.findIndex(data => data.qid === id);
    const { name, value } = event.target;
    const data = [...tableData];
    data[index][name] = name === 'response' ? +value : value;
    setTableData([...data]);
    const dateFilter = R.filter(
      x => x.typeFlag === true && x.typeFlagDate === true,
    )(data);
    const validateDate = dateFilter.map(arr => arr.response === 0
      && arr.responseDate !== null).some(Boolean);
    const validateNoDate = dateFilter.map(arr => arr.response > 0).every(Boolean);
    const validateRadio = R.map(arr => !R.isNil(arr.response),
      R.filter(x => x.typeFlag === true)(data)).every(Boolean);
    setIsChange(validateRadio && (validateDate || validateNoDate));
  };
  const FULFILLMENT_TABLE_COLUMNS = [
    {
      name: 'question',
      label: 'Questions',
      align: 'left',
    },
    {
      name: 'response',
      label: 'Instructions',
      align: 'left',
      cellFormat: (name, value) => {
        if (value.typeFlag) {
          return (
            <RadioGroup defaultValue="top" disabled={!isfflmtEditable} name="response" onChange={event => handleChange(event, value.qid)} row value={value.response}>
              <FormControlLabel control={<Radio color="primary" />} disabled={!isfflmtEditable || isViewOnly} label="Yes" value={0} />
              <FormControlLabel control={<Radio color="primary" />} disabled={!isfflmtEditable || isViewOnly} label="No" value={1} />
              <FormControlLabel control={<Radio color="primary" />} disabled={!isfflmtEditable || isViewOnly} label="NA" value={2} />
            </RadioGroup>
          );
        }
        return null;
      },
    },
    {
      name: 'hidden1',
      label: 'hidden1',
      align: 'left',
      cellFormat: (name, value) => {
        if (value.typeFlagDate) {
          const dateValue = !R.isNil(value.responseDate) ? moment(value.responseDate).format('YYYY-MM-DD') : '';
          return (
            <InputField
              handleChange={event => handleChange(event, value.qid)}
              isDisabled={!isfflmtEditable || isViewOnly}
              name="responseDate"
              size="small"
              type="date"
              value={dateValue}
              variant="filled"
            />
          );
        }
        return null;
      },
    },
    {
      name: 'hidden2',
      label: 'hidden2',
      align: 'left',
      cellFormat: (name, value) => {
        if (value.typeFlagComments) {
          return (
            <InputField
              handleChange={event => handleChange(event, value.qid)}
              inputProps={{ maxLength: 1000 }}
              isDisabled={!isfflmtEditable || isViewOnly}
              name="responseComment"
              placeholder="Add Comment"
              size="small"
              type="text"
              value={value.responseComment != null ? value.responseComment : ''}
              variant="filled"
            />
          );
        }
        return null;
      },
    },
  ];
  return (
    <div style={{ maxHeight: '60vh', overflow: 'auto' }} styleName="grey-bg fulfilment-container">
      <div styleName="btn-container">
        <Button color="primary" disabled={!isChange} onClick={handleClear} style={{ borderWidth: '2px' }} variant="outlined">
          Clear
        </Button>
        <Button color="primary" disabled={!isChange} onClick={handleSave} variant="contained">
          Save
        </Button>
        <Popup
          cancelButtonText="No"
          confirmButtonText="Yes"
          message="Are you sure you want to clear the unsaved changes?"
          onCancel={handleClose}
          onConfirm={handleConfirm}
          show={compData.isOpen}
          showCancelButton
          showConfirmButton
        />
      </div>
      {inProgress
        ? <Loader size={60} />
        : (
          <>
            {!R.isEmpty(tableData) ? (
              <EvalTable
                columns={FULFILLMENT_TABLE_COLUMNS}
                data={tableData || []}
              />
            ) : (
              <h4 styleName="no-data">Checklist Not Available</h4>
            )
            }
          </>
        )}
      <SweetAlertBox
        message={inProgress ? 'Please wait...' : saveFulfillmentList.statusMessage}
        onConfirm={handleClose}
        show={compData.submitPopup}
        type={inProgress ? 'Info' : saveFulfillmentList.type}
      />
    </div>
  );
};

FulfillmentChecklist.defaultProps = {
  inProgress: true,
  saveFulfillmentChecklist: () => { },
  fetchFulfillmentChecklist: () => { },
  caseHeaderInfo: {},
};

FulfillmentChecklist.propTypes = {
  caseHeaderInfo: PropTypes.shape(
    {
      caseType: PropTypes.string.isRequired,
      resolutionId: PropTypes.number.isRequired,
    },
  ),
  fetchFulfillmentChecklist: PropTypes.func,
  fulfillmentChecklist: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  inProgress: PropTypes.bool,
  isfflmtEditable: PropTypes.bool.isRequired,
  saveFulfillmentChecklist: PropTypes.func,
  saveFulfillmentList: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  saveFulfillmentChecklist: operations.saveFulfillmentChecklistOperation(dispatch),
  fetchFulfillmentChecklist: operations.fetchFulfillmentChecklistOperation(dispatch),
});

const mapStateToProps = state => ({
  caseHeaderInfo: selectors.caseHeaderInfoData(state),
  inProgress: selectors.inProgress(state),
  saveFulfillmentList: selectors.saveFulfillmentList(state),
  user: loginSelectors.getUser(state),
  fulfillmentChecklist: selectors.fulfillmentChecklist(state),
});

const TestHooks = {
  FulfillmentChecklist,
};

export { TestHooks };

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentChecklist);
