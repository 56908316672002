/* eslint-disable import/prefer-default-export */
import {
  all, call, put, takeEvery, select,
} from 'redux-saga/effects';
import * as Api from 'lib/Api';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { getDaysDiffFromCurrentDate } from 'lib/DateUtils';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as evalSelector } from 'ducks/eval';
import {
  SET_RESULT_OPERATION, SET_EVAL_ID, SET_REPAYMENT_CASE_ID,
} from 'ducks/dashboard/types';
import { ERROR, FAILED, SUCCESS } from 'constants/common';
import { selectors as loginSelectors } from 'ducks/login';
import { SET_CHECKLIST_CENTERPANE } from 'ducks/tombstone/types';
import {
  SHOW_LOADER, HIDE_LOADER, SET_SNACK_BAR_VALUES, FETCH_UNIVERSAL_ACTION_DATA,
  SET_UNIVERSAL_ACTION_DATA,
  SET_ELIGIBILITY_DATA,
  SET_CASEHEADERINFO_DATA,
  FETCH_ELIGIBILITY_DATA,
  FETCH_CALC_LOG_DATA,
  SET_CALC_LOG_DATA,
  SAVE_ELIGIBILITY_DATA,
  GET_CASEHEADERINFO_DATA,
  FETCH_REJECT_REASONS,
  SET_REJECT_REASONS,
  SAVE_REJECT_REASON,
  FETCH_TRACKPAYMENT_DATA,
  SET_TRACKPAYMENT_DATA,
  UPDATE_TRACKPAYMENT_DATES,
  DELETE_TRACKPAYMENT,
  SUBMIT_COLLATERAL_ACCEPTANCE,
  FETCH_FULFILLMENT_CHECKLIST,
  SET_FULFILLMENT_CHECKLIST,
  SAVE_FULFILLMENT_CHECKLIST,
  SUBMIT_FULFILLMENT_CHECKLIST,
  FETCH_BOOKING_DATA,
  SET_BOOKING_DATA,
  CALCULATE_CASE_RULES,
  FETCH_CALCLOGS_LOADER,
  LOCK_CASE_RULES,
  SAVE_LOCK_CASE,
  CALCULATE_CASE_LOADER,
  FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  SET_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
  SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
  FETCH_EVAL_DETAILS,
  SET_EVAL_DETAILS,
  SET_INV_GRP_CODES,
  SAVE_ACCEPTANCE_FLAG,
  FETCH_CASE_TYPES,
  SET_CASE_TYPES,
  FETCH_WORKOUTTYPE,
  SET_WORKOUTTYPE_DATA,
  SET_CREATE_BUTTON_STATUS,
  FETCH_EVALSCREEN_DETAILS,
  SET_EVALSCREEN_DETAILS,
  SAVE_DISASTERDEC_DATA,
  SET_STREAMLINE_MOD_ACCEPTANCE,
  TOGGLE_LOCK_CASE,
  ADD_CASE_TO_WATERFALL_SEQ,
  CREATE_NEW_MOD,
  SET_NEW_MOD_DETAILS,
  FETCH_LOCK_OVERRIDE,
  SET_LOCK_OVERRIDE,
  CREATE_NEW_EVAL_CASE,
  FETCH_BOOKING_TAB_DATA,
  SET_BOOKING_TAB_DATA,
  SET_BOOKING_TAB_CORP_ADVN_DATA,
  MOVE_CASE_SEQ,
  REMOVE_WATERFALL,
  UPDATE_TRACKPAYMENT_RESPONSE,
  CHECK_LETTER_GENERATION_STATUS,
  SET_LETTER_GENERATION,
  FETCH_STATES,
  SET_STATES,
  FETCH_COUNTYS,
  SET_COUNTYS,
  FETCH_DISASTERDTLS,
  SET_DISASTERDTLS,
  TOGGLE_DISASTER_POPUP,
  FETCH_CASE_NPV_DETAILS,
  SET_NPV_DATA,
  DISASTER_POPUP_LOADER,
  FETCH_FINANCIAL_DROPDOWNS,
  SAVE_FINANCIAL_DATA,
  SET_FINANCIAL_DROPDOWNS,
  CREATE_EVAL_LOADER, FETCH_CASE_UNIVERSAL_DETAILS, FETCH_WORKOUT_TYPE_LOADER,
  SET_EVAL_HEADER_IN_PROGRESS,
  SET_SELECTED_CASEID,
  CREATE_NEW_CASE, SET_RULE_ENGINE_ERROR_MSG,
  SAVE_OVERRIDDEN_RULE,
  SET_COLLATERAL_ACCEPTANCE, FINANCIAL_DATA_LOADER,
  // SET_FINANCIAL_DETAILS,
} from './types';
import mapper from './mapper';
import DashboardModel from '../../../models/Dashboard';
import selectors from './selectors';
import {
  ACTIVE_CASE_TYPES, DISASTER_PARTIAL_CLAIM,
  DISASTER_FHA_MODIFICATION,
  EVAL_STATUS_CASE_TYPES,
  FHLMCW,
  FHA_RECOVERY_REQUESTTYPE,
  FNMA_FHLMC_REQUESTTYPE,
  FHAW2,
  FNMAW,
  FNMA_FHLMC_WORKOUTTYPE,
  DISASTER_WTFALL,
  VA_WTFALL,
  DISASTER_FHA_TRIAL,
  ACTIVE,
} from '../../../constants/eval';
import { fetchEligibilityDataAction } from './actions';

const {
  Messages:
  {
    LEVEL_FAILED,
  },
} = DashboardModel;

const mapActionData = (actionData) => {
  const { mapCorporateAdvancesData, mapAmortizationData } = mapper;
  if (actionData) {
    const contractData = R.propOr(null, 'contract', actionData);
    if (contractData) {
      const generalInformation = R.propOr([], ['universalFieldValues'], contractData);
      //  generalInformation = mapGeneralAndCaseInfoData(generalInformation);
      const caseInformation = R.propOr([], ['caseSpecificFieldValues'], contractData);
      //   caseInformation = mapGeneralAndCaseInfoData(generalInformation);
      let corporateAdvances = R.propOr([], ['corporateAdvanceReportCases'], contractData);
      corporateAdvances = mapCorporateAdvancesData(corporateAdvances);
      const stepRates = R.propOr([], ['resolutionStepRates'], contractData);
      let amortizationDetails = R.propOr([], ['amortizationDetails'], contractData);
      amortizationDetails = mapAmortizationData(amortizationDetails);
      const currentFrontEndRatio = R.propOr(null, ['currentFrontEndRatio'], contractData);
      const proposedFrontEndRatio = R.propOr(null, ['proposedFrontEndRatio'], contractData);
      const currentBackEndRatio = R.propOr(null, ['currentBackEndRatio'], contractData);
      const proposedBackEndRatio = R.propOr(null, ['proposedBackEndRatio'], contractData);
      const currentDTI = R.propOr(null, ['currentDTI'], contractData);
      const proposedDTI = R.propOr(null, ['proposedDTI'], contractData);
      const preModLTVPercentage = R.propOr(null, ['ltv'], contractData);
      const postModLTVPercentage = R.propOr(null, ['netCollateralYield'], contractData);
      const finalHardShipId = R.propOr(0, 'finalHardShipId', contractData);
      const isInterestRateChanged = R.propOr(null, 'isInterestRateChanged', contractData);

      const {
        universalFieldValues, caseSpecificFieldValues,
        ...universalActionResp
      } = contractData;

      const ratios = {
        currentFrontEndRatio,
        currentBackEndRatio,
        proposedFrontEndRatio,
        proposedBackEndRatio,
        currentDTI,
        proposedDTI,
        preModLTVPercentage,
        postModLTVPercentage,
      };

      return {
        generalInformation,
        caseInformation,
        corporateAdvances,
        stepRates,
        amortizationDetails,
        ratios,
        finalHardShipId,
        isInterestRateChanged,
        universalActionResp,
      };
    }
  }
  return null;
};

function* fetchUniversalActionData(action) {
  const caseId = action.payload;
  // const caseId = 2464966;
  // const caseId = 6461014;
  // const caseId = 6828263; For Non Del Approval Date Case -1
  // const caseId = 6835175; For Non Del Approval Date Case -2
  yield put({ type: SHOW_LOADER });
  let response = null;
  let actionData = null;
  const defaultResponse = {
    contract: {
      generalInformation: [],
      caseInformation: [],
      corporateAdvances: [],
      stepRates: [],
      amortizationDetails: [],
      ratios: {
        currentFrontEndRatio: null,
        currentBackEndRatio: null,
        proposedFrontEndRatio: null,
        proposedBackEndRatio: null,
        currentDTI: null,
        proposedDTI: null,
        preModLTVPercentage: null,
        postModLTVPercentage: null,
      },
      finalHardShipId: null,
      isInterestRateChanged: null,
      universalActionResp: null,
    },
  };
  try {
    response = yield call(Api.callGet, `/api/universal-action/api/UniversalAction?caseId=${caseId}`);
    if (!response) {
      response = defaultResponse;
    }
    actionData = mapActionData(response);
    yield put({ type: SET_UNIVERSAL_ACTION_DATA, payload: actionData });
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to fetch Universal Action Data';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
    yield put({ type: SET_UNIVERSAL_ACTION_DATA, payload: actionData });
  }
  yield put({ type: HIDE_LOADER });
  return response;
}

function* fetchEligibilityData(action) {
  const caseId = action.payload;
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callGet, `/api/universal-action/api/ResolutionEligibilityResponse?caseId=${caseId}`);
    if (response) {
      yield put({ type: SET_ELIGIBILITY_DATA, payload: response });
      yield put({ type: HIDE_LOADER });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Eligibility',
        status: 'Failed to fetch Eligibility data',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchCalcLogsData() {
  const caseId = yield select(evalSelector.getSelectedCaseId);
  yield put({ type: FETCH_CALCLOGS_LOADER, payload: true });
  try {
    const calcLogResponse = yield call(Api.callGet, `/api/tkams/eval/calcLogs/${caseId}`);
    if (calcLogResponse) {
      const calcLogs = R.pathOr([], ['modCalcResponse', 'calcLog'], calcLogResponse);
      yield put({ type: SET_CALC_LOG_DATA, payload: calcLogs });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Calc Logs',
          status: 'No Calc Logs found for the selected caseId.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Calc Logs',
        status: 'Error Occured While Fetching Calc Logs for the selected CaseId.',
      },
    });
  }
  yield put({ type: FETCH_CALCLOGS_LOADER, payload: false });
}

function* saveEligibilityData(action) {
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  const userEmail = R.pathOr(null, ['payload', 'userEmail'], action);
  const reqBody = R.pathOr(null, ['payload', 'body'], action);
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.patch, `/api/universal-action/api/ResolutionEligibilityResponse/OverrideEligibilityRule?caseId=${caseId}`, reqBody,
      { userEmail });
    if (response && response.errors) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Eligibility',
          status: 'Failed to save Eligibility data',
        },
      });
      yield put({ type: HIDE_LOADER });
    } else {
      yield put(fetchEligibilityDataAction(caseId));
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Eligibility',
        status: 'Failed to save Eligibility data',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* getCaseHeaderInfoData(action) {
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  yield put({ type: SET_EVAL_HEADER_IN_PROGRESS, payload: true });
  try {
    const response = yield call(Api.callGet, `/api/dataservice/eval/getCaseHeaderByCaseId?caseId=${caseId}`);
    if (response) {
      yield put({ type: SET_CASEHEADERINFO_DATA, payload: response });
    }
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to CaseHeaderInfo Data';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
  }
  yield put({ type: SET_EVAL_HEADER_IN_PROGRESS, payload: false });
}

function* fetchRejectreasons() {
  try {
    const result = yield call(Api.callGet, '/api/dataservice/eval/evalRejectReason');
    if (result) {
      yield put({
        type: SET_REJECT_REASONS,
        payload: result,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Rejectreason',
        status: 'Service down while fetching reject reason',
      },
    });
  }
}
function* checkLetterIsGenerated() {
  const loanNumber = yield select(dashboardSelectors.loanNumber);
  const evalId = yield select(dashboardSelectors.evalId);
  // yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callGet, `/api/dataservice/eval/getIsLetterGenerated?evalId=${evalId}&loanId=${loanNumber}&approvalType=Rejected`, {});
    // yield put({ type: HIDE_LOADER });
    yield put({
      type: SET_LETTER_GENERATION,
      payload: typeof response === 'boolean' ? response : undefined,
    });
  } catch (e) {
    // yield put({ type: HIDE_LOADER });
    const sweetAlert = { status: 'Failed to Check Letter Generation Status', level: FAILED };
    yield put({
      type: SET_RESULT_OPERATION,
      payload: sweetAlert,
    });
  }
}

function* fetchTrackPaymentData(action) {
  yield put({ type: SHOW_LOADER });
  try {
    const resolutionId = R.pathOr(null, ['payload', 'resolutionId'], action);
    const isRepaymentEval = R.pathOr('No', ['payload', 'isRepaymentEval'], action);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);
    const loanId = yield select(dashboardSelectors.loanNumber);
    const caseHeaderInfoData = yield select(evalSelector.caseHeaderInfoData);
    const evalId = R.pathOr(null, ['evalId'], caseHeaderInfoData);
    const response = yield call(Api.callGet, `/api/dataservice/eval/fetchTrackPaymentInfo/${resolutionId}/${isRepaymentEval}`);
    if (response) {
      const isPymtDtAvailable = response.trackPaymentDetailsList
      && response.trackPaymentDetailsList.map(a => a.paymentDate != null).every(Boolean);
      if (isPymtDtAvailable && isRepaymentEval === 'Yes') {
        const evalCaseStatusPayload = {
          loanId,
          userId: email,
          evalId,
          evalStatus: 'Active',
          evalSubStatus: 'Completed Forbearance',
          caseId: resolutionId,
          caseStatus: 'Closed',
          caseSubStatus: null,
        };
        yield call(Api.callPost, '/api/dataservice/eval/updateEvalCaseStatus', evalCaseStatusPayload);
      }
      yield put({ type: SET_TRACKPAYMENT_DATA, payload: response });
      yield put({ type: HIDE_LOADER });
    }
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to fetch track payment data';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* updateTrackPaymentDates(action) {
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callPost, '/api/dataservice/eval/updatePaymentHSSNDates', action.payload);
    if (response) {
      yield put({ type: UPDATE_TRACKPAYMENT_RESPONSE, payload: response });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: LEVEL_FAILED,
          saga: 'Trackpayments',
          status: 'Failed to update Track payment dates',
        },
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: LEVEL_FAILED,
        saga: 'Trackpayments',
        status: 'Failed to update Track payment dates',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* deleteTrackPayment(action) {
  yield put({ type: SHOW_LOADER });
  try {
    const { id, resolutionId, isRepaymentEval } = action.payload;
    const payload = {
      caseId: resolutionId,
      seqNum: id,
    };
    const response = yield call(Api.callPost, '/api/dataservice/eval/deleteTrackPaymentInfo', payload);
    if (!response) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: LEVEL_FAILED,
          saga: 'Trackpayments',
          status: 'Failed to delete Track payment ',
        },
      });
    } else {
      const result = yield call(Api.callGet, `/api/dataservice/eval/fetchTrackPaymentInfo/${resolutionId}/${isRepaymentEval}`);
      yield put({ type: SET_TRACKPAYMENT_DATA, payload: result });
      yield put({ type: HIDE_LOADER });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: LEVEL_FAILED,
        saga: 'Trackpayments',
        status: 'Failed to delete Track payment ',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* submitCollateralAccept(action) {
  const collateralAcceptanceType = action.payload;
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  const evalId = yield select(dashboardSelectors.evalId);
  yield put({ type: SHOW_LOADER });
  const payload = {
    availableCollateralAcceptanceActions: [],
    collateralAcceptanceType,
  };
  try {
    const response = yield call(Api.callPost(`/api/dataservice/eval/saveCollateralAcceptance/${evalId}/${email}`, payload));
    if (response && response.errors) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: LEVEL_FAILED,
          saga: 'CollateralAcceptance',
          status: 'Failed to submit',
        },
      });
    } else {
      yield put({
        type: SET_COLLATERAL_ACCEPTANCE,
        payload: collateralAcceptanceType,
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: LEVEL_FAILED,
        saga: 'CollateralAcceptance',
        status: 'Failed to submit',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchFulfillmentChecklist(action) {
  try {
    const resolutionType = R.pathOr(null, ['payload', 'caseType'], action);
    const resolutionId = R.pathOr(null, ['payload', 'resolutionId'], action);
    yield put({ type: SHOW_LOADER });
    const response = yield call(Api.callGet, `/api/dataservice/eval/fulfillmentCheckList/${resolutionType}/${resolutionId}`);
    if (response) {
      yield put({
        type: SET_FULFILLMENT_CHECKLIST,
        payload: response,
      });
    }
  } catch (e) {
    yield put({ type: SET_FULFILLMENT_CHECKLIST, payload: {} });
  }
  yield put({ type: HIDE_LOADER });
}

function* saveFulfillmentChecklist(action) {
  yield put({ type: SHOW_LOADER });
  try {
    const user = yield select(loginSelectors.getUser);
    const userPrincipalName = R.path(['userDetails', 'email'], user);
    const resolutionId = yield select(evalSelector.getSelectedCaseId);
    const removeProps = ['question', 'transId', 'typeFlagComments', 'typeFlagDate', 'typeFlag', 'activeFlag', 'qid'];
    const payload = R.map(R.compose(R.assoc('resolutionId', resolutionId), R.assoc('email', userPrincipalName), R.omit(removeProps)), action.payload);
    const response = yield call(Api.callPost, '/api/dataservice/eval/fulfillmentCheckList', payload);
    if (response && response.status === 'Success') {
      const snackBar = {};
      snackBar.statusMessage = 'Checklist updated Successfully';
      snackBar.type = 'Success';
      yield put({
        type: SAVE_FULFILLMENT_CHECKLIST,
        payload: snackBar,
      });
    } else {
      const snackBar = {};
      snackBar.statusMessage = 'Failed to Update Data';
      snackBar.statusCode = 'Failed';
      yield put({
        type: SAVE_FULFILLMENT_CHECKLIST,
        payload: snackBar,
      });
    }
  } catch (e) {
    const snackBar = {};
    snackBar.statusMessage = 'Failed to Update Data';
    snackBar.statusCode = 'Failed';
    yield put({
      type: SAVE_FULFILLMENT_CHECKLIST,
      payload: snackBar,
    });
  }
  yield put({ type: HIDE_LOADER });
}

function* fetchBookingTags() {
  const caseId = yield select(evalSelector.getSelectedCaseId);
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callGet, `api/dataservice/eval/fetchBookingTags/${caseId}`);
    if (response) {
      yield put({ type: SET_BOOKING_DATA, payload: response });
      yield put({ type: HIDE_LOADER });
    }
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to fetch Booking Data';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
    yield put({ type: HIDE_LOADER });
  }
}

const fetchValidationMessage = function* fetchValidationMessage(validationMsg, isRuleEngineCall) {
  let errors = validationMsg.validationResult.messages;
  const ruleDetailList = validationMsg.ruleDetailList ? validationMsg.ruleDetailList : null;
  let errorMessages = null;
  let overridable = false;
  let ruleId;
  if (errors.length > 0) {
    errors = validationMsg.validationResult.messages.sort((a, b) => a.messageId - b.messageId);
    if (errors[0].message) {
      errorMessages = errors[0].message;
    } else if (errors[0].detailedMessage) {
      errorMessages = errors[0].detailedMessage;
    }
    if (ruleDetailList && ruleDetailList.length > 0 && errors && errors.length > 0) {
      const index = ruleDetailList.findIndex(e => e.ruleId === errors[0].messageId);
      overridable = ruleDetailList[index].overridable !== undefined
        ? ruleDetailList[index].overridable : false;
      // eslint-disable-next-line prefer-destructuring
      ruleId = ruleDetailList[index].ruleId;
    }
    if (isRuleEngineCall) {
      yield put({
        type: SET_RULE_ENGINE_ERROR_MSG,
        payload: { errorMsg: errorMessages, overridable, ruleId },
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'caseRuleValidation',
          status: errorMessages,
        },
      });
    }
  }
};

function* calculateCaseRules(action) {
  const caseId = yield select(evalSelector.getSelectedCaseId);
  yield put({ type: CALCULATE_CASE_LOADER, payload: true });
  const isCalculateRuleAction = R.propEq('type', 'app/eval/CALCULATE_CASE_RULES')(action);
  try {
    const universalFieldValues = (yield select(selectors.generalInformation));
    const caseSpecificFieldValues = (yield select(selectors.caseInformation));
    const forbearancePlanSchedule = (yield select(selectors.forbearancePlanSchedule));
    const planStartDates = (yield select(selectors.planStartDates));
    const isInterestRateChanged = (yield select(selectors.isInterestRateChanged));
    const approvalHistory = (yield select(selectors.approvalHistory));
    const brand = yield select(dashboardSelectors.brand);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);
    const loanId = yield select(dashboardSelectors.loanNumber);
    const contractData = yield select(selectors.universalActionResp);
    const finalHardShipId = yield select(selectors.finalHardShipId);

    const preLockPayload = {
      universalFieldValues,
      caseSpecificFieldValues,
      forbearancePlanSchedule,
      planStartDates,
      resolutionId: caseId,
      isInterestRateChanged,
      approvalHistory,
      // TODO - remove hardcoding of selectedGroup
      selectedGroup: 'TEST',
    };
    const headers = {
      Brand: brand, Application: 'CMOD', EmailAddress: email,
    };
    const response = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidatePreLockCaseRules', preLockPayload,
      headers);
    if (response && response.validationResult && response.validationResult.isSuccessful) {
      yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
      const calcReq = {
        ...contractData,
        universalFieldValues,
        caseSpecificFieldValues,
        loanId,
        loanIdString: String(loanId),
        brandId: brand,
        finalHardShipId: +finalHardShipId,
      };
      const calcResponse = yield call(Api.callPost, '/api/universal-calc/api/UniversalCalc/calculate', calcReq);
      if (calcResponse && calcResponse.isSuccessful) {
        // const { updateDataWithCalcResponse } = mapper;
        yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
        const actionData = mapActionData(calcResponse);
        // if (actionData.generalInformation && actionData.generalInformation.length > 0) {
        //   actionData.generalInformation = updateDataWithCalcResponse(
        //     universalFieldValues,
        //     actionData.generalInformation,
        //   );
        // }
        // if (actionData.caseInformation && actionData.caseInformation.length > 0) {
        //   actionData.caseInformation = updateDataWithCalcResponse(
        //     caseSpecificFieldValues,
        //     actionData.caseInformation,
        //   );
        // }
        yield put({ type: SET_UNIVERSAL_ACTION_DATA, payload: actionData });
        const lockPayload = {
          resolutionId: caseId,
          isInterestRateChanged,
          approvalHistory,
          // TODO - remove hardcoding of selectedGroup
          selectedGroup: 'TEST',
        };
        const lckResponse = yield call(
          Api.callPost,
          'api/rulesengine/RulesEngine/ValidateLockCaseRules',
          lockPayload,
          headers,
        );
        if (lckResponse && lckResponse.validationResult
          && lckResponse.validationResult.isSuccessful && isCalculateRuleAction) {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Success',
              saga: 'calculateRules',
              status: 'Successfully passed validation rules',
            },
          });
        } else if (lckResponse && lckResponse.validationResult
          && !lckResponse.validationResult.isSuccessful) {
          yield call(fetchValidationMessage, lckResponse, true);
        }
      } else if (calcResponse && !calcResponse.isSuccessful) {
        yield call(fetchValidationMessage,
          { validationResult: { messages: calcResponse } }, false);
      } else {
        yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: ERROR,
            saga: 'validateLockCaseRules',
            status: 'Failed to Calculate Case Rules',
          },
        });
      }
    } else if (response && response.validationResult
      && !response.validationResult.isSuccessful) {
      yield call(fetchValidationMessage, response, true);
    } else {
      yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'validatePreLockCaseRules',
          status: 'Failed to Validate Pre Lock Case Rules',
        },
      });
    }
  } catch (e) {
    yield put({ type: CALCULATE_CASE_LOADER, payload: false });
    yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'calculateRules',
        status: 'Failed to calculate rules',
      },
    });
  }
  yield put({ type: CALCULATE_CASE_LOADER, payload: false });
}

function* lockCaseRules(action) {
  try {
    yield call(calculateCaseRules, action);
    yield put({ type: CALCULATE_CASE_LOADER, payload: true });
    const validationMessage = yield select(evalSelector.getRuleEngineErrorMsg);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);

    if (!validationMessage) {
      const caseId = yield select(evalSelector.getSelectedCaseId);
      const isInterestRateChanged = (yield select(selectors.isInterestRateChanged));
      const approvalHistory = (yield select(selectors.approvalHistory));
      const brand = yield select(dashboardSelectors.brand);
      const lockExtPayload = {
        resolutionId: caseId,
        isInterestRateChanged: isInterestRateChanged || false,
        approvalHistory,
        // TODO - remove hardcoding of selectedGroup
        selectedGroup: 'TEST',
      };
      const headers = {
        Brand: brand, Application: 'CMOD', EmailAddress: email,
      };

      const extResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateLockCaseExtRules', lockExtPayload, headers);
      if (extResponse && extResponse.validationResult
         && extResponse.validationResult.isSuccessful) {
        yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
        yield put({ type: TOGGLE_LOCK_CASE, payload: true });
      } else if (extResponse && extResponse.validationResult
        && !extResponse.validationResult.isSuccessful) {
        yield call(fetchValidationMessage, extResponse, true);
      } else {
        yield put({ type: CALCULATE_CASE_LOADER, payload: false });
        yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: ERROR,
            saga: 'lockRuleValidation',
            status: 'Failed to validate lock case rules',
          },
        });
      }
    }
  } catch (e) {
    yield put({ type: SET_RULE_ENGINE_ERROR_MSG, payload: null });
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'lockRuleValidation',
        status: 'Failed to validate lock case rules',
      },
    });
  }
  yield put({ type: CALCULATE_CASE_LOADER, payload: false });
}

function* fetchCaseDetails(action) {
  yield put({ type: CALCULATE_CASE_LOADER, payload: true });
  try {
    const caseId = yield select(evalSelector.getSelectedCaseId);
    const universalActionData = yield call(fetchUniversalActionData, { payload: caseId });
    if (universalActionData) {
      yield call(calculateCaseRules, action);
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Case Details',
          status: 'No case details found for the selected caseId.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Case Details',
        status: 'Error occured while fetching case details',
      },
    });
  }
  yield put({ type: CALCULATE_CASE_LOADER, payload: false });
}

function* saveLockCase() {
  const caseId = yield select(evalSelector.getSelectedCaseId);
  yield put({ type: TOGGLE_LOCK_CASE, payload: false });
  try {
    yield put({ type: CALCULATE_CASE_LOADER, payload: true });
    const universalFieldValues = (yield select(selectors.generalInformation));
    const caseSpecificFieldValues = (yield select(selectors.caseInformation));
    const loanId = yield select(dashboardSelectors.loanNumber);
    const isRSHGroupPresent = (yield select(loginSelectors.isRSHGroupPresent));
    const brandId = isRSHGroupPresent ? 'RSH' : 'NSM';
    const contractData = yield select(selectors.universalActionResp);
    const finalHardShipId = yield select(selectors.finalHardShipId);

    const lockRequest = {
      ...contractData,
      universalFieldValues,
      caseSpecificFieldValues,
      loanId,
      loanIdString: String(loanId),
      brandId,
      finalHardShipId: +finalHardShipId,
    };

    const lockResponse = yield call(Api.callPost, '/api/universal-action/api/UniversalAction/lock', lockRequest);

    if (lockResponse && lockResponse.isSuccessful) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: 'Success',
          saga: 'saveLockCase',
          status: 'Case is locked & saved Successfully',
        },
      });
      yield call(getCaseHeaderInfoData, { payload: { caseId } });
    } else if (lockResponse && !lockResponse.isSuccessful) {
      yield call(fetchValidationMessage,
        { validationResult: { messages: lockResponse.messages } }, false);
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'saveLockCase',
          status: 'Failed to save lock case',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'saveLockCase',
        status: 'Failed to save lock case',
      },
    });
  }
  yield put({ type: CALCULATE_CASE_LOADER, payload: false });
}

function* fetchCertainMorgageLoanChecklist() {
  try {
    yield put({ type: SHOW_LOADER });
    const caseId = yield select(evalSelector.getSelectedCaseId);
    const response = yield call(Api.callGet, `/api/dataservice/eval/fetchCertainMortgageChecklist/${caseId}`);
    if (response) {
      yield put({
        type: SET_CERTAIN_MORGAGE_LOAN_CHECKLIST,
        payload: response,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'CertainMorgage',
        status: 'Failed to fetch Certain Morgage Loan Checklist',
      },
    });
  }
  yield put({ type: HIDE_LOADER });
}

function* saveCertainMortgageLoanChecklist(action) {
  yield put({ type: SHOW_LOADER });
  const user = yield select(loginSelectors.getUser);
  const userPrincipalName = R.path(['userDetails', 'email'], user);
  const caseId = yield select(evalSelector.getSelectedCaseId);
  const { mortgageData, errorDisplay } = action.payload;
  const payload = R.map(R.compose(R.assoc('resolutionId', caseId), R.assoc('createBy', userPrincipalName)), mortgageData);

  try {
    const response = yield call(Api.callPost, '/api/dataservice/eval/saveCertainMortgageChecklist', payload);
    if (response && response.status === 'Success') {
      const loanNbr = yield select(dashboardSelectors.loanNumber);
      const brandName = yield select(dashboardSelectors.brand);
      const commentsPayload = {
        loanNbr,
        brandName,
        commentCode: errorDisplay ? 'CMLF' : 'CMLP',
      };

      const lsamsResponse = yield call(Api.callPost, '/api/disposition/lsamsCommentCode', commentsPayload);
      if (lsamsResponse && lsamsResponse.success) {
        const snackBar = {};
        snackBar.statusMessage = 'Successfully saved certain Mortgage Checklist and updated LsamsCommentCode';
        snackBar.type = 'Success';
        yield put({
          type: SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
          payload: snackBar,
        });
        yield call(fetchCertainMorgageLoanChecklist);
      } else {
        const snackBar = {};
        snackBar.statusMessage = 'Successfully saved certain Mortgage Checklist but failed to updated LsamsCommentCode';
        snackBar.type = 'Failed';
        yield put({
          type: SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
          payload: snackBar,
        });
        yield call(fetchCertainMorgageLoanChecklist);
      }
    } else {
      const snackBar = {};
      snackBar.statusMessage = 'Failed to save certain Mortgage Checklist and LsamsCommentCode';
      snackBar.statusCode = 'Failed';
      yield put({
        type: SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
        payload: snackBar,
      });
    }
  } catch (e) {
    const snackBar = {};
    snackBar.statusMessage = 'Failed to save certain Mortgage Checklist and LsamsCommentCode';
    snackBar.statusCode = 'Failed';
    yield put({
      type: SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
      payload: snackBar,
    });
  }
  yield put({ type: HIDE_LOADER });
}

function* fetchEvalDetails() {
  const evalId = yield select(dashboardSelectors.evalId);
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callGet, `/api/dataservice/eval/evalDetails/${evalId}`);
    const { evalCase, invGrpCodes } = response;
    if (response) {
      yield put({ type: SET_EVAL_DETAILS, payload: evalCase });
      yield put({ type: SET_INV_GRP_CODES, payload: invGrpCodes });
      yield put({ type: HIDE_LOADER });
    }
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to Fetch Eval Details';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* saveAcceptanceFlag(action) {
  try {
    const acptFlag = action.payload;
    const evalId = yield select(dashboardSelectors.evalId);

    const payload = {
      evalId,
      acptFlag,
    };
    const response = yield call(Api.callPost, '/api/dataservice/eval/updateAcceptanceFlag', payload);
    if (response == null || response.statusCode === 204) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Rejectreason',
          status: 'Failed to save AcceptanceFlag',
        },

      });
      yield put({
        type: SET_STREAMLINE_MOD_ACCEPTANCE,
        payload: null,
      });
    } else {
      yield put({
        type: SET_STREAMLINE_MOD_ACCEPTANCE,
        payload: acptFlag,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Rejectreason',
        status: 'Failed to save AcceptanceFlag',
      },
    });
    yield put({
      type: SET_STREAMLINE_MOD_ACCEPTANCE,
      payload: null,
    });
  }
}

function* fetchCaseTypesDropdownData() {
  try {
    const evalScreenInfo = yield select(evalSelector.getEvalScreenData);
    const waterfallId = R.pathOr(null, ['waterFallInfo', 'waterfallTypeId'], evalScreenInfo);
    const lienPosition = yield select(dashboardSelectors.getLienPosition);
    const loanType = yield select(dashboardSelectors.getLoanType);
    const evalCaseStatus = R.pathOr(null, ['evalHeader', 'status'], evalScreenInfo);
    const evalId = yield select(dashboardSelectors.evalId);

    let delinquentDays = 0;
    const messageCodes = yield select(dashboardSelectors.messageCodes);
    const IsMSGCode685 = messageCodes.includes('06') || messageCodes.includes('85');
    if (!IsMSGCode685) {
      const dbResponse = yield call(Api.callGet, `api/dataservice/eval/getCaseTypes?loanType=${loanType}&waterfallId=${waterfallId}&lienPosition=${lienPosition}&evalId=${evalId}`);
      if (waterfallId === 20) {
        delinquentDays = yield select(dashboardSelectors.deliquentInfo);
      }

      const caseTypes = [];
      if (dbResponse && dbResponse.caseTypesData.length > 0) {
        const { caseTypesData } = dbResponse;
        const { isRejectedCase } = dbResponse;
        if (waterfallId === 20) {
          // Logic for Waterfall ID = 20
          caseTypesData.forEach((object) => {
            if (ACTIVE_CASE_TYPES.includes(object.caseType)) {
              if (isRejectedCase && EVAL_STATUS_CASE_TYPES.includes(evalCaseStatus)) {
                if (object.caseType === DISASTER_PARTIAL_CLAIM
                  || object.caseType === DISASTER_FHA_MODIFICATION) {
                  caseTypes.push(object.caseType);
                } else if ((object.caseType === DISASTER_FHA_TRIAL) && evalCaseStatus === ACTIVE) {
                  caseTypes.push(object.caseType);
                }
              } else if (!isRejectedCase && delinquentDays < 60
                && object.caseType === DISASTER_FHA_TRIAL) {
                caseTypes.push(object.caseType);
              } else if (!isRejectedCase && delinquentDays >= 60
                && object.caseType !== DISASTER_FHA_TRIAL) {
                caseTypes.push(object.caseType);
              }
            }
          });
        } else {
          // Logic for other waterfall IDs
          caseTypesData.forEach((object) => {
            if (evalCaseStatus === 'Approved' && object.workoutType === 'T' && object.workoutType === 'F');
            else {
              caseTypes.push(object.caseType);
            }
          });
        }
        yield put({
          type: SET_CASE_TYPES,
          payload: {
            caseTypes,
            caseTypesData,
          },
        });

        if (caseTypes.length > 0) {
          yield put({
            type: SET_CREATE_BUTTON_STATUS,
            payload: false,
          });
        }
      }
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          status: 'The Loan message Code is 06 or 85, Not eligible to open any case',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: 'Failed to fetch case types data',
      },
    });
  }
}

function* fetchWorkoutTypeData(action) {
  yield put({ type: FETCH_WORKOUT_TYPE_LOADER, payload: true });
  try {
    const resolutionTypeId = action.payload;
    const investorCode = yield select(dashboardSelectors.getInvestorCode);
    const response = yield call(Api.callGet, `api/dataservice/eval/getWorkoutTypes?resolutionTypeId=${resolutionTypeId}&investorCode=${investorCode}`);
    if (response && response.length > 0) {
      yield put({
        type: SET_WORKOUTTYPE_DATA,
        payload: response,
      });
    } else {
      yield put({
        type: SET_WORKOUTTYPE_DATA,
        payload: [],
      });
    }
  } catch (e) {
    yield put({
      type: SET_WORKOUTTYPE_DATA,
      payload: [],
    });
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: 'Failed to fetch work types data',
      },
    });
  }
  yield put({ type: FETCH_WORKOUT_TYPE_LOADER, payload: false });
}

function* fetchEvalScreenDetails(action) {
  const { type } = action;
  const evalId = yield select(dashboardSelectors.evalId);
  const loanNumber = yield select(dashboardSelectors.loanNumber);
  if (type !== SAVE_FINANCIAL_DATA) {
    yield put({ type: SHOW_LOADER });
  }
  try {
    let pvmId = null;
    const pvmResponse = yield call(Api.callGet, `/api/data-aggregator/misc/getPropertyValuationDetails/${loanNumber}`);
    if (pvmResponse && pvmResponse.length > 0) {
      const pvm = pvmResponse[0];
      if (getDaysDiffFromCurrentDate(moment(pvm.dateCreated)) < 90) {
        ({ pvmId } = pvm);
      }
    }
    const response = yield call(Api.callGet, `/api/dataservice/eval/evalCase/${evalId}`);
    if (response) {
      yield put({ type: SET_EVALSCREEN_DETAILS, payload: { response, pvmId } });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'EvalScreenData',
          status: 'Something went wrong. Please try after some time',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'EvalScreenData',
        status: 'Failed to Fetch Eval screen Details',
      },
    });
  }
  if (type !== SAVE_FINANCIAL_DATA) {
    yield put({ type: HIDE_LOADER });
  }
}

function* saveDisasterDecData(action) {
  yield put({ type: DISASTER_POPUP_LOADER, payload: true });
  try {
    let { payload } = action;
    const loanNum = yield select(dashboardSelectors.loanNumber);
    const brand = yield select(dashboardSelectors.brand);
    const loanNumber = loanNum.toString();
    const brandArray = [brand];
    const body = {
      loanNumber,
      brandArray,
    };
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);
    const evalId = yield select(dashboardSelectors.evalId);
    let disasterFlag = yield select(evalSelector.getDisasterFlag);
    if (payload.effected === 'yes') {
      disasterFlag = 'E';
    }
    payload = {
      ...payload, evalId, disasterFlag, email,
    };
    let nextPaymentDate = '';
    const lsamsInfo = yield call(Api.callPost, '/api/utility/FetchLsamsLoanInfo', body);
    nextPaymentDate = R.pathOr('', ['getLoanInformationResult', 'nextPayment', 'date'], lsamsInfo);

    const updatedPayload = {
      ...payload,
      nextPaymentDate,
    };

    const response = yield call(Api.callPost, '/api/dataservice/eval/updateEvalOnDisasterDeclaration', updatedPayload);
    if (response && response.status === 'Success') {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: 'Success',
          saga: 'EvalScreenData',
          status: 'Eval Disaster Declaration updated Successfully',
        },
      });
      yield put({
        type: TOGGLE_DISASTER_POPUP,
        payload: false,
      });
      yield put({
        type: SET_COUNTYS, payload: [],
      });
      yield put({
        type: SET_DISASTERDTLS, payload: [],
      });
      yield call(fetchEvalScreenDetails, action);
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'EvalScreenData',
          status: 'Failed to Update Eval Disaster Declaration',
        },
      });
      yield put({
        type: TOGGLE_DISASTER_POPUP,
        payload: false,
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'EvalScreenData',
        status: 'Failed to Update Eval Disaster Declaration',
      },
    });
    yield put({
      type: TOGGLE_DISASTER_POPUP,
      payload: false,
    });
  }
  yield put({ type: DISASTER_POPUP_LOADER, payload: false });
}

function* addCasetoWaterfallSeq(action) {
  yield put({ type: SHOW_LOADER });
  const { caseId } = action.payload;
  try {
    const user = yield select(loginSelectors.getUser);
    const disasterType = yield select(selectors.getDisasterFlag);
    const email = R.path(['userDetails', 'email'], user);
    let waterfallTypeId = null;
    const brandName = yield select(dashboardSelectors.brand);
    const loanNumber = yield select(dashboardSelectors.loanNumber);
    const waterfallDtlReq = {
      loanNumber, brandId: brandName, disasterType,
    };
    const waterFallDetailsRes = yield call(Api.callPost, 'api/data-aggregator/eval/getInvWaterFallCaseType', waterfallDtlReq);
    if (waterFallDetailsRes && waterFallDetailsRes.waterFallId) {
      waterfallTypeId = waterFallDetailsRes.waterFallId;
    }
    const payload = {
      caseId,
      email,
      waterfallTypeId,
    };
    const response = yield call(Api.put, '/api/dataservice/eval/insertOrUpdateWaterfallSeq', payload);
    if (response && response.status === 'Success') {
      yield call(fetchEvalScreenDetails, action);
    } else {
      let statusMsg;
      if (response.status !== 'Failed') {
        statusMsg = response.status;
      } else {
        statusMsg = 'Something went wrong. Please try after some time';
      }
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          status: statusMsg,
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: 'Failed to Update the waterfall Seq Details',
      },
    });
  }
  yield put({ type: HIDE_LOADER });
}

function* fetchlockOverride(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const caseId = R.pathOr(null, ['payload', 'caseId'], action); // mock data = 6922584
    const isInterestRateChanged = (yield select(selectors.isInterestRateChanged));
    const approvalHistory = (yield select(selectors.approvalHistory));
    const brand = yield select(dashboardSelectors.brand);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);
    const lockExtPayload = {
      resolutionId: caseId,
      isInterestRateChanged: isInterestRateChanged || false,
      approvalHistory,
      // TODO - remove hardcoding of selectedGroup
      selectedGroup: 'TEST',
    };
    const headers = {
      Brand: brand, Application: 'CMOD', EmailAddress: email,
    };
    const extResponse = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateLockCaseExtRules', lockExtPayload, headers);
    if (extResponse && extResponse.ruleDetailList
      && extResponse.validationResult.isSuccessful) {
      yield put({
        type: SET_LOCK_OVERRIDE,
        payload: extResponse.ruleDetailList[0]
          ? extResponse.ruleDetailList[0].overridable : undefined,
      });
      yield put({ type: HIDE_LOADER });
    } else if (extResponse && extResponse.validationResult
      && !extResponse.validationResult.isSuccessful) {
      yield call(fetchValidationMessage, extResponse, true);
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        status: 'Failed to Fetch Lock Override',
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchBookingTabData(action) {
  const caseId = R.pathOr(null, ['payload', 'caseId'], action);
  yield put({ type: SHOW_LOADER });
  const brand = yield select(dashboardSelectors.brand);
  const loanNumber = yield select(dashboardSelectors.loanNumber);
  const evalScreenInfo = yield select(evalSelector.getEvalScreenData);
  const sentForBookingDate = R.pathOr(null, ['evalCase', 'sentForBooking'], evalScreenInfo);
  const trialStartDate = R.pathOr(null, ['evalCase', 'trialStartDate'], evalScreenInfo);
  let response;
  try {
    const loanTrnPayload = {
      loanNumber: loanNumber.toString(),
      brandArray: [
        brand,
      ],
    };
    response = yield call(Api.callGet, `api/dataservice/eval/fetchBookingScreenData/${caseId}`);
    const corporateAdvancesData = yield call(Api.callGet, `api/dataservice/eval/fetchCorporateAdvancesData/${caseId}`);
    if (response) {
      if (response.premodInformation.trialPaymentNSF != null) {
        const findTrailPayment = yield call(Api.callPost, 'api/utility/FetchLoanTransactions', loanTrnPayload);
        const {
          getTransactionsResult: { transactionsResultSet: { transactions } },
        } = findTrailPayment;
        const TrialPaymentNSFVal = findTrailPayment
          && R.filter(val => val.transactionCode === '93'
            && (val.overrideCode === '02' || val.overrideCode === '16')
            && moment(val.postedDate)
              .isBetween(moment(trialStartDate), moment(sentForBookingDate)), transactions);
        response = {
          ...response,
          premodInformation: { ...response.premodInformation, trialPaymentNSF: TrialPaymentNSFVal.length > 0 ? 'Yes' : 'No' },
        };
      }
      yield put({ type: SET_BOOKING_TAB_DATA, payload: response });
    }
    if (corporateAdvancesData) {
      yield put({ type: SET_BOOKING_TAB_CORP_ADVN_DATA, payload: corporateAdvancesData });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    const snackBar = {};
    snackBar.message = 'Failed to fetch Booking Tab Data';
    snackBar.type = 'error';
    snackBar.open = true;
    yield put({
      type: SET_SNACK_BAR_VALUES,
      payload: snackBar,
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* createNewCase(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const brand = yield select(dashboardSelectors.brand);
    const loanNumber = yield select(dashboardSelectors.loanNumber);
    const selectedEvalId = yield select(dashboardSelectors.evalId);
    const selectedWorkoutType = R.pathOr(null, ['payload', 'workoutType'], action);
    const selectedCaseType = R.pathOr(null, ['payload', 'selectedCaseType'], action);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);

    const validationPayload = {
      loanId: loanNumber,
      evalId: selectedEvalId,
      resolutionChoiceType: selectedCaseType,
      workoutType: selectedWorkoutType,
      resolutionId: 0,
      isInterestRateChanged: true,
      approvalHistory: null,
      selectedGroup: null,
    };

    const headers = {
      Brand: brand, Application: 'CMOD', EmailAddress: email,
    };
    const response = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateNewCaseRules', validationPayload, headers);
    if (response && response.validationResult && response.validationResult.isSuccessful) {
      const caseHeaders = { Brand: brand, Application: 'CMOD', UserId: email };
      const casePayload = {
        loanID: loanNumber,
        caseType: selectedCaseType,
        lsamsID: 'CMODNP',
        deferralType: '',
        subWorkoutType: '',
        evalId: selectedEvalId,
        capitalizeFutureEscrow: 0,
        caseInterestRate: 0,
        requestedStartDate: '',
        requestedDuration: 0,
        priorCaseId: 0,
      };

      let caseResponse = null;

      caseResponse = yield call(Api.callPost, 'api/casecreation/Case/CreateNewCase', casePayload, caseHeaders);

      const responseMessage = R.propOr(null, 'responseMessage', caseResponse);
      if (responseMessage === 'Success') {
        yield call(fetchEvalScreenDetails, action);
        yield put({ type: SET_NEW_MOD_DETAILS, payload: caseResponse });
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: 'Success',
            saga: 'CreateNewCase',
            status: 'New Case Created Successfully',
          },
        });
      } else {
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: ERROR,
            saga: 'CreateNewCase',
            status: `Failed to create New Case as ${responseMessage}`,
          },
        });
      }
    } else {
      yield call(fetchValidationMessage, response, false);
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'CreateNewCase',
        status: 'Failed to validate or create New Case',
      },
    });
  }
  yield put({ type: HIDE_LOADER });
}

function* createNewMod(action) {
  try {
    yield put({ type: CREATE_EVAL_LOADER, payload: true });
    const brand = yield select(dashboardSelectors.brand);
    const loanNumber = yield select(dashboardSelectors.loanNumber);
    const selectedWorkoutType = R.pathOr(null, ['payload', 'workoutType'], action);
    const selectedCaseType = R.pathOr(null, ['payload', 'selectedCaseType'], action);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);

    const validationPayload = {
      loanId: loanNumber,
      evalId: 0,
      resolutionChoiceType: selectedCaseType,
      workoutType: selectedWorkoutType,
      resolutionId: 0,
      isInterestRateChanged: true,
      approvalHistory: null,
      selectedGroup: null,
    };

    const headers = {
      Brand: brand, Application: 'CMOD', EmailAddress: email,
    };
    const response = yield call(Api.callPost, 'api/rulesengine/RulesEngine/ValidateNewCaseRules', validationPayload, headers);
    if (response && response.validationResult && response.validationResult.isSuccessful) {
      const caseHeaders = { Brand: brand, Application: 'CMOD', UserId: email };
      const casePayload = {
        loanID: loanNumber,
        caseType: selectedCaseType,
        lsamsID: 'CMODNP',
        deferralType: '',
        subWorkoutType: '',
        evalId: 0,
        capitalizeFutureEscrow: 0,
        caseInterestRate: 0,
        requestedStartDate: '',
        requestedDuration: 0,
        priorCaseId: 0,
      };

      let caseResponse = null;

      caseResponse = yield call(Api.callPost, 'api/casecreation/Case/CreateNewCase', casePayload, caseHeaders);

      const responseMessage = R.propOr(null, 'responseMessage', caseResponse);
      if (responseMessage === 'Success') {
        yield put({ type: SET_NEW_MOD_DETAILS, payload: caseResponse });
        const { caseID } = caseResponse;
        yield put({ type: SET_SELECTED_CASEID, payload: caseID });
        yield put({ type: SET_REPAYMENT_CASE_ID, payload: caseID });
        yield put({ type: SET_CHECKLIST_CENTERPANE, payload: 'Repayment Plan Case' });
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: 'Success',
            saga: 'CreateNewCase',
            status: 'New Case Created Successfully',
          },
        });
      } else {
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: ERROR,
            saga: 'CreateNewCase',
            status: `Failed to create New Case as ${responseMessage}`,
          },
        });
      }
    } else {
      yield call(fetchValidationMessage, response, false);
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'CreateNewCase',
        status: 'Failed to validate or create New Case',
      },
    });
  }
  yield put({ type: CREATE_EVAL_LOADER, payload: false });
}

function* createNewEval(action) {
  // Optional - Do a loader component that shows various stages of process with percentage
  yield put({ type: CREATE_EVAL_LOADER, payload: true });
  try {
    const brandName = yield select(dashboardSelectors.brand);
    const loanNumber = yield select(dashboardSelectors.loanNumber);
    const disasterType = R.pathOr(null, ['payload', 'disasterType'], action);
    const evalType = R.pathOr(null, ['payload', 'evalType'], action);
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);
    const headers = {
      accept: 'text/plain',
      'Content-Type': 'application/json',
      Brand: brandName,
      Application: 'CMOD',
      EmailAddress: email,
    };
    const payload = {
      loanId: Number(loanNumber),
      isDisasterEval: disasterType !== null,
      evalCaseRuleType: 'Create',
    };
    const newEvalRulesRes = yield call(Api.callPost,
      'api/rulesengine/RulesEngine/ValidateNewEvaluationRules', payload, headers);
    if (newEvalRulesRes) {
      const isSuccessful = R.pathOr(false, ['evalCaseRuleResult', 'isSuccessful'], newEvalRulesRes);
      if (isSuccessful) {
        let requestType = disasterType;
        let workOutType = '';
        const waterfallDtlReq = {
          loanNumber, brandId: brandName, disasterType,
        };
        if (requestType == null) {
          const waterFallDetailsRes = yield call(Api.callPost, 'api/data-aggregator/eval/getInvWaterFallCaseType', waterfallDtlReq);
          if (waterFallDetailsRes && waterFallDetailsRes.waterFallId) {
            if (waterFallDetailsRes.waterFallId === FHAW2) {
              requestType = FHA_RECOVERY_REQUESTTYPE;
            } else if (waterFallDetailsRes.waterFallId === FNMAW
                || waterFallDetailsRes.waterFallId === FHLMCW) {
              requestType = FNMA_FHLMC_REQUESTTYPE;
              workOutType = FNMA_FHLMC_WORKOUTTYPE;
            } else if (waterFallDetailsRes.waterFallId === DISASTER_WTFALL) {
              requestType = 'disaster';
            } else if (waterFallDetailsRes.waterFallId === VA_WTFALL) {
              requestType = 'vastandard';
            }
          } else {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: ERROR,
                saga: 'createNewEval',
                status: `Error while fetching waterFall details ${waterFallDetailsRes}`,
              },
            });
          }
        }
        const createEvalPayload = {
          loanId: loanNumber,
          workOutType,
          requestType,
          lsamsID: 'CMODNP',
          skipEvalRules: false,
          evaluationRejectAfterCreate: false,
          evaluationRejectedReasonCode: 0,
          foreclosureDate: new Date(),
          numberOfDaysUntilForeclosure: 0,
          requestedStartDate: disasterType !== 'disaster' ? new Date() : '',
          requestedDuration: 0,
          borrowerAcceptance: 'N',
          isInflight: false,
          isPreApproved: false,
          isNonDelegated: false,
          disasterType: disasterType === 'disaster' ? 'B' : null,
        };
        const evalCreationHeaders = {
          accept: 'text/plain',
          'Content-Type': 'application/json',
          Brand: brandName,
          Application: 'CMOD',
          UserId: email,
        };
        const createEvalResponse = yield call(Api.callPost, 'api/casecreation/Evaluation/CreateEvaluation', createEvalPayload, evalCreationHeaders);
        if (createEvalResponse) {
          const { caseID, evalId } = createEvalResponse;
          if (caseID && evalId && caseID !== 0 && evalId !== 0) {
            yield put({ type: SET_EVAL_ID, payload: evalId });
            yield put({ type: SET_CHECKLIST_CENTERPANE, payload: evalType });
          } else {
            yield put({
              type: SET_RESULT_OPERATION,
              payload: {
                level: ERROR,
                saga: 'createNewEval',
                status: `Failed to create New Eval as ${createEvalResponse && createEvalResponse.responseMessage}`,
              },
            });
          }
        } else {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: ERROR,
              saga: 'createNewEval',
              status: `Failed to create New Eval as ${createEvalResponse}`,
            },
          });
        }
      } else {
        yield put({
          type: SET_RESULT_OPERATION,
          payload: {
            level: ERROR,
            saga: 'createNewEval',
            status: 'New Eval Rules validation failed',
          },
        });
      }
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'createNewEval',
          status: `New Eval Rules validation failed ${newEvalRulesRes}`,
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'createNewEval',
        status: `Failed to create New Eval as ${JSON.stringify(e)}`,
      },
    });
  }
  yield put({ type: CREATE_EVAL_LOADER, payload: false });
}

function* saveRejectReason(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const { comment, reasonCode, isOverrideLetter } = action.payload;
    const evalId = yield select(dashboardSelectors.evalId);
    const loanType = yield select(dashboardSelectors.getLoanType);
    const messageCodes = yield select(dashboardSelectors.messageCodes);
    const deliquentInfo = yield select(dashboardSelectors.deliquentInfo);
    const property = yield select(dashboardSelectors.property);
    const user = yield select(loginSelectors.getUser);

    const userPrincipalName = R.path(['userDetails', 'email'], user);

    const payload = {
      evalIds: [evalId],
      comment,
      reasonCode,
      delinquentDays: deliquentInfo,
      email: userPrincipalName,
      isOverrideLetter,
      loanType,
      messageCodes,
      state: property.state,
    };

    const response = yield call(Api.callPost, '/api/dataservice/eval/rejectEval', payload);
    if (!Array.isArray(response) || response.length === 0
    || response.findIndex(e => e === +evalId) === -1) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Rejectreason',
          status: 'Service down while saving reject reason',
        },
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: SUCCESS,
          saga: 'Rejectreason',
          status: 'Eval has been rejected Succesfully',
        },
      });
      yield put({
        type: SET_CREATE_BUTTON_STATUS,
        payload: true,
      });
      yield call(fetchEvalScreenDetails, action);
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Rejectreason',
        status: 'Service down while saving reject reason',
      },
    });
  }
  yield put({ type: HIDE_LOADER });
}

function* moveCaseSeq(action) {
  try {
    yield put({ type: SHOW_LOADER });
    const user = yield select(loginSelectors.getUser);
    const email = R.path(['userDetails', 'email'], user);
    const evalId = yield select(dashboardSelectors.evalId);
    const payload = {
      ...action.payload, userId: email, evalId, headerId: evalId,
    };
    const moveSeqResp = yield call(Api.callPost, 'api/dataservice/eval/moveCaseWaterfallSeq', payload);
    if (moveSeqResp.validationResponse && moveSeqResp.validationResponse === 'Success') {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: 'Success',
          saga: 'createNewEval',
          status: 'Waterfall sequence moved successfully!',
        },
      });
      yield call(fetchEvalScreenDetails, action);
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'createNewEval',
          status: `Waterfall sequence move failed as ${moveSeqResp.validationResponse}`,
        },
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'createNewEval',
        status: `Failed to move case sequence as ${JSON.stringify(e)}`,
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* removeCaseSeq(action) {
  const { caseId, caseType } = action.payload;
  const evalId = yield select(dashboardSelectors.evalId);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  yield put({ type: SHOW_LOADER });
  try {
    const reqPayload = {
      evalId,
      caseId,
      userId: email,
      headerId: 2, // TODO: Header ID can be removed from here and backend if needed
      caseType,
    };
    const response = yield call(Api.callPost, 'api/dataservice/eval/removeCaseWaterfallSeq', reqPayload);
    if (response.error || !response.validationResponse === 'Success') {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'removeWaterFallSeq',
          status: `Failed to remove case sequence with reason - ${response.validationResponse ? response.validationResponse : response.error}`,
        },
      });
    } else {
      yield call(fetchEvalScreenDetails, action);
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'removeWaterFallSeq',
        status: `Failed to remove case sequence as ${JSON.stringify(e)}`,
      },
    });
    yield put({ type: HIDE_LOADER });
  }
}

function* fetchStates() {
  yield put({ type: DISASTER_POPUP_LOADER, payload: true });
  try {
    const response = yield call(Api.callGet, '/api/dataservice/eval/getStatesForDisasterDeclaration');
    if (response) {
      yield put({ type: SET_STATES, payload: response });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'EvalDisaster',
        status: 'Failed to fetch states',
      },
    });
  }
  yield put({ type: DISASTER_POPUP_LOADER, payload: false });
}

function* fetchCountys(action) {
  yield put({ type: DISASTER_POPUP_LOADER, payload: true });
  try {
    const response = yield call(Api.callPost, `/api/dataservice/eval/getDisasterDeclarationCounty/${action.payload}`);
    if (response) {
      yield put({ type: SET_COUNTYS, payload: response });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'EvalDisaster',
        status: 'Failed to fetch countys',
      },
    });
  }
  yield put({ type: DISASTER_POPUP_LOADER, payload: false });
}

function* fetchDisasterDtls(action) {
  yield put({ type: DISASTER_POPUP_LOADER, payload: true });
  const { state, county } = action.payload;
  try {
    const response = yield call(Api.callPost, `/api/dataservice/eval/getDisasterDeclarations/${state.trim()}/${county.trim()}`);
    const statusCode = R.propOr(null, 'status', response);
    if (statusCode && statusCode !== 200) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'EvalDisaster',
          status: 'Failed to fetch disaster dtls',
        },
      });
    } else {
      yield put({ type: SET_DISASTERDTLS, payload: response });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'EvalDisaster',
        status: 'Failed to fetch disaster dtls',
      },
    });
  }
  yield put({ type: DISASTER_POPUP_LOADER, payload: false });
}

function* fetchCaseNpvDetails(action) {
  const caseId = action.payload;
  // const caseId = 4080885;
  yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callGet, `/api/dataservice/eval/getNPVDetails/${caseId}`);
    if (response) {
      yield put({ type: SET_NPV_DATA, payload: response });
    } else {
      yield put({ type: SET_NPV_DATA, payload: null });
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'NPV Details',
          status: 'No Npv Details found for Selected Case Id',
        },
      });
    }
  } catch (e) {
    yield put({ type: SET_NPV_DATA, payload: null });
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'NPV Details',
        status: 'No Npv Details found for Selected Case Id',
      },
    });
  }
  yield put({ type: HIDE_LOADER });
}


function* fetchFinancialDropdowns() {
  const loanId = yield select(dashboardSelectors.loanNumber);
  // const caseId = 4080885;
  // yield put({ type: SHOW_LOADER });
  try {
    const response = yield call(Api.callGet, `/api/dataservice/eval/latestFinancialDetails/${loanId}`);
    if (response) {
      yield put({ type: SET_FINANCIAL_DROPDOWNS, payload: response });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Financial Dropdowns',
        status: 'Error occured while fetching Financial dropdown values.',
      },
    });
  }
  // yield put({ type: HIDE_LOADER });
}

function* updateFinancialSection(action) {
  const {
    incomeId, expenseId, capModId, pvmId, updateDetails, isEvalActive,
  } = action.payload;
  const evalId = yield select(dashboardSelectors.evalId);
  const user = yield select(loginSelectors.getUser);
  const email = R.path(['userDetails', 'email'], user);
  if (updateDetails) {
    yield put({ type: FINANCIAL_DATA_LOADER, payload: true });
  }
  const payload = {
    evalId: evalId || 0,
    incomeId: incomeId || 0,
    expenseId: expenseId || 0,
    capModId: capModId || 0,
    pvmId: pvmId || 0,
    email,
    updateDetails,
  };
  try {
    if (updateDetails || isEvalActive) {
      const response = yield call(Api.put, '/api/dataservice/eval/financialDetail', payload);
      if (updateDetails) {
        if (response && response.status === 'Success') {
          // yield put({ type: SET_FINANCIAL_DROPDOWNS, payload: response });
          // yield call(fetchFinancialDropdowns);
          // yield call(fetchEvalScreenDetails, action);
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: 'Success',
              saga: 'Financial Id Update',
              status: 'Checklist Saved Successfully',
            },
          });
          // yield put({
          //   type: SET_FINANCIAL_DETAILS,
          //   payload: { incomeId, expenseId, capModRqstId: capModId },
          // });
        } else {
          yield put({
            type: SET_RESULT_OPERATION,
            payload: {
              level: ERROR,
              saga: 'Financial Id Update',
              status: 'Error occured while updating Financial values.',
            },
          });
        }
      }
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Financial Id Update',
        status: 'Error occured while updating Financial values.',
      },
    });
  }
  if (updateDetails) {
    yield put({ type: FINANCIAL_DATA_LOADER, payload: false });
  }
}

function* saveOverriddenRule(action) {
  try {
    const evalId = yield select(dashboardSelectors.evalId);
    const user = yield select(loginSelectors.getUser);
    const userPrincipalName = R.path(['userDetails', 'email'], user);
    const payload = {
      email: userPrincipalName,
      evalId,
      ...action.payload,
    };
    const result = yield call(Api.callPost, '/api/dataservice/eval/overriddenRule', payload);
    if (R.equals(R.propOr(null, 'status', result), 'Success')) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: 'Success',
          saga: 'Overriddenrule',
          status: 'Successfully Overrriden Rule',
        },
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          level: ERROR,
          saga: 'Overriddenrule',
          status: 'Service down while saving Overridde Rule',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        level: ERROR,
        saga: 'Overriddenrule',
        status: 'Service down while saving Overridde Rule',
      },
    });
  }
}


function* watchFetchUniversalActionData() {
  yield takeEvery(FETCH_UNIVERSAL_ACTION_DATA, fetchUniversalActionData);
}

function* watchFetchCaseUniversalData() {
  yield takeEvery(FETCH_CASE_UNIVERSAL_DETAILS, fetchCaseDetails);
}

function* watchFetchEligibilityData() {
  yield takeEvery(FETCH_ELIGIBILITY_DATA, fetchEligibilityData);
}

function* watchFetchCalcLogsData() {
  yield takeEvery(FETCH_CALC_LOG_DATA, fetchCalcLogsData);
}

function* watchFetchRejectReasons() {
  yield takeEvery(FETCH_REJECT_REASONS, fetchRejectreasons);
}

function* watchSaveRejectReason() {
  yield takeEvery(SAVE_REJECT_REASON, saveRejectReason);
}

function* watchSaveEligibilityData() {
  yield takeEvery(SAVE_ELIGIBILITY_DATA, saveEligibilityData);
}

function* watchGetCaseHeaderInfoData() {
  yield takeEvery(GET_CASEHEADERINFO_DATA, getCaseHeaderInfoData);
}

function* watchFetchTrackPaymentData() {
  yield takeEvery(FETCH_TRACKPAYMENT_DATA, fetchTrackPaymentData);
}

function* watchUpdateTrackPaymentDates() {
  yield takeEvery(UPDATE_TRACKPAYMENT_DATES, updateTrackPaymentDates);
}

function* watchDeleteTrackPayment() {
  yield takeEvery(DELETE_TRACKPAYMENT, deleteTrackPayment);
}

function* watchSubmitCollateralAccep() {
  yield takeEvery(SUBMIT_COLLATERAL_ACCEPTANCE, submitCollateralAccept);
}

function* watchFulfillmentChecklist() {
  yield takeEvery(FETCH_FULFILLMENT_CHECKLIST, fetchFulfillmentChecklist);
}

function* watchSaveFulfillmentChecklist() {
  yield takeEvery(SUBMIT_FULFILLMENT_CHECKLIST, saveFulfillmentChecklist);
}

function* watchfetchBookingTags() {
  yield takeEvery(FETCH_BOOKING_DATA, fetchBookingTags);
}

function* watchCalculateCase() {
  yield takeEvery(CALCULATE_CASE_RULES, calculateCaseRules);
}

function* watchLockCase() {
  yield takeEvery(LOCK_CASE_RULES, lockCaseRules);
}

function* watchSaveLockCase() {
  yield takeEvery(SAVE_LOCK_CASE, saveLockCase);
}

function* watchfetchCertainMorgageLoanChecklist() {
  yield takeEvery(FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST, fetchCertainMorgageLoanChecklist);
}

function* watchSaveCertainMortgageLoanChecklist() {
  yield takeEvery(SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST, saveCertainMortgageLoanChecklist);
}

function* watchFetchEvalDetails() {
  yield takeEvery(FETCH_EVAL_DETAILS, fetchEvalDetails);
}

function* watchSaveAcceptanceFlag() {
  yield takeEvery(SAVE_ACCEPTANCE_FLAG, saveAcceptanceFlag);
}

function* watchFetchCaseTypesDropdownData() {
  yield takeEvery(FETCH_CASE_TYPES, fetchCaseTypesDropdownData);
}

function* watchFetchWorkoutTypesDropdownData() {
  yield takeEvery(FETCH_WORKOUTTYPE, fetchWorkoutTypeData);
}

function* watchFetchEvalScreenDetails() {
  yield takeEvery(FETCH_EVALSCREEN_DETAILS, fetchEvalScreenDetails);
}

function* watchsaveDisasterDecData() {
  yield takeEvery(SAVE_DISASTERDEC_DATA, saveDisasterDecData);
}

function* watchAddCasetoWaterfallSeq() {
  yield takeEvery(ADD_CASE_TO_WATERFALL_SEQ, addCasetoWaterfallSeq);
}

function* watchCreateNewMod() {
  yield takeEvery(CREATE_NEW_MOD, createNewMod);
}

function* watchFetchLockOverride() {
  yield takeEvery(FETCH_LOCK_OVERRIDE, fetchlockOverride);
}

function* watchCreateNewEval() {
  yield takeEvery(CREATE_NEW_EVAL_CASE, createNewEval);
}

function* watchfetchBookingTabData() {
  yield takeEvery(FETCH_BOOKING_TAB_DATA, fetchBookingTabData);
}
function* watchLetterGenerationCount() {
  yield takeEvery(CHECK_LETTER_GENERATION_STATUS, checkLetterIsGenerated);
}
function* watchMoveCaseSeq() {
  yield takeEvery(MOVE_CASE_SEQ, moveCaseSeq);
}

function* watchRemoveCaseSeq() {
  yield takeEvery(REMOVE_WATERFALL, removeCaseSeq);
}

function* watchFetchStates() {
  yield takeEvery(FETCH_STATES, fetchStates);
}

function* watchFetchCountys() {
  yield takeEvery(FETCH_COUNTYS, fetchCountys);
}

function* watchFetchDisasterDtls() {
  yield takeEvery(FETCH_DISASTERDTLS, fetchDisasterDtls);
}

function* watchFetchCaseNpvDetails() {
  yield takeEvery(FETCH_CASE_NPV_DETAILS, fetchCaseNpvDetails);
}

function* watchFetchFinancialDropdowns() {
  yield takeEvery(FETCH_FINANCIAL_DROPDOWNS, fetchFinancialDropdowns);
}

function* watchSaveFinancialSection() {
  yield takeEvery(SAVE_FINANCIAL_DATA, updateFinancialSection);
}

function* watchCreateNewCase() {
  yield takeEvery(CREATE_NEW_CASE, createNewCase);
}

function* watchSaveOverriddenRule() {
  yield takeEvery(SAVE_OVERRIDDEN_RULE, saveOverriddenRule);
}

export const combinedSaga = function* combinedSaga() {
  yield all([
    watchFetchUniversalActionData(),
    watchFetchEligibilityData(),
    watchFetchCalcLogsData(),
    watchSaveEligibilityData(),
    watchGetCaseHeaderInfoData(),
    watchFetchRejectReasons(),
    watchSaveRejectReason(),
    watchFetchTrackPaymentData(),
    watchUpdateTrackPaymentDates(),
    watchDeleteTrackPayment(),
    watchSubmitCollateralAccep(),
    watchFulfillmentChecklist(),
    watchSaveFulfillmentChecklist(),
    watchfetchBookingTags(),
    watchCalculateCase(),
    watchLockCase(),
    watchSaveLockCase(),
    watchfetchCertainMorgageLoanChecklist(),
    watchSaveCertainMortgageLoanChecklist(),
    watchFetchEvalDetails(),
    watchSaveAcceptanceFlag(),
    watchCreateNewEval(),
    watchFetchCaseTypesDropdownData(),
    watchFetchWorkoutTypesDropdownData(),
    watchFetchEvalScreenDetails(),
    watchsaveDisasterDecData(),
    watchAddCasetoWaterfallSeq(),
    watchCreateNewMod(),
    watchFetchLockOverride(),
    watchfetchBookingTabData(),
    watchMoveCaseSeq(),
    watchRemoveCaseSeq(),
    watchLetterGenerationCount(),
    watchFetchStates(),
    watchFetchCountys(),
    watchFetchDisasterDtls(),
    watchFetchCaseNpvDetails(),
    watchFetchFinancialDropdowns(),
    watchSaveFinancialSection(),
    watchFetchCaseUniversalData(),
    watchCreateNewCase(),
    watchSaveOverriddenRule(),
  ]);
};
