import {
  submitToFNMA, fetchSMDUHistory,
  transactionData,
  fetchSMDUPriorWorkoutData,
  submitToReportInvestor, updateReportInvestorRes, savePriorWorkoutToDB, closePopUp,
} from './actions';


const submitToFNMAOperation = dispatch => (payload) => {
  dispatch(submitToFNMA(payload));
};

const handleSMDUHistory = dispatch => () => {
  dispatch(fetchSMDUHistory());
};

const fetchTransactionData = dispatch => () => {
  dispatch(transactionData());
};

const fetchSMDUPriorWorkoutDataOperation = dispatch => () => {
  dispatch(fetchSMDUPriorWorkoutData());
};

const submitToReportInvestorOperation = dispatch => (payload) => {
  dispatch(submitToReportInvestor(payload));
};

const closePopUpOperation = dispatch => () => {
  dispatch(closePopUp());
};


const updateReportInvestorOperation = dispatch => (payload) => {
  dispatch(updateReportInvestorRes(payload));
};

const savePriorWorkout = dispatch => (payload) => {
  dispatch(savePriorWorkoutToDB(payload));
};

const operations = {
  submitToFNMAOperation,
  handleSMDUHistory,
  fetchTransactionData,
  fetchSMDUPriorWorkoutDataOperation,
  submitToReportInvestorOperation,
  updateReportInvestorOperation,
  savePriorWorkout,
  closePopUpOperation,
};

export default operations;
