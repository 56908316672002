import React, { useEffect, useState } from 'react';
import {
  Button, Grid, Divider, IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import './EvalHeader.css';
import * as R from 'ramda';
import { operations as evalOperations, selectors as evalSelectors } from 'ducks/eval';
import { operations as tombstoneOperations, selectors as tombstoneSelectors } from 'ducks/tombstone';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import Loader from 'components/Loader';
import ConfirmationPopup from '../ConfirmationPopup';
import {
  INVALID_STATUS_FOR_REJECT, REQUIRED_USER_GROUPS, STANDARDEVAL_REQUIRED_USER_GROUPS,
  REPAYMENT_CASE_TYPE, NONDELAPPROVALDATE_CASETYPE, DISASTER_EDIT_USER_GROUPS,
  OPEN, NON_DELEGATED, NON_DEL_APPROVAL_DATE, LOCKED_DELEGATE_TYPE, NON_DEL_DATE_ERR_MSG1,
  NON_DEL_DATE_ERR_MSG2, NON_DEL_DATE_ERR_MSG3,
} from '../../../constants/eval';
import OverrideMessage from '../OverrideMessage';
import RejectReasonPopup from '../RejectReasonPopup/RejectReasonPopup';
import { STANDARD_EVAL_SCREEN } from '../../../constants/loanInfoComponents';
import EvalPopup from '../EvalPopup/EvalPopup';
import SweetAlertBox from '../../SweetAlertBox';

function EvalHeader(props) {
  const {
    headerData, setChecklistCenterPaneData, user, checklistCenterPaneView, calculateCaseRules,
    lockCaseRules, saveLockCase, toggleLockCase, lockValicationStatus, inProgress, caseInformation,
    universalActionResp, isEditable, generalInformation, fetchStates,
    isDisasterPopupOpen, toggleDisasterPopup, evalHeaderInProgress, isOpen,
  } = props;
  const {
    evalCaseHeader, evalCaseType, createdOn, status, subStatus, evalHeader = {}, lockedFlag,
    createBy,
    data: {
      caseId,
    }, caseInfo,
  } = headerData;

  const [isDisabled, setDisabled] = useState(true);
  const [isDisasterEditIconDisabled, setDisasterEditIconDisabled] = useState(true);
  const [lockValidation, setLockValidation] = useState({
    isPopupVisible: false,
    popUpMessage: '',
  });
  const { setRejectreasonPopupStatus } = props;

  const invalidStatusForReject = R.any(
    item => INVALID_STATUS_FOR_REJECT.includes(item.status),
  )(caseInfo || []);

  const disableReject = (status === 'Rejected' || invalidStatusForReject) || !isEditable;

  useEffect(() => {
    const isStandardEval = STANDARD_EVAL_SCREEN.includes(checklistCenterPaneView);
    const userGroups = user && user.groupList;
    if (REQUIRED_USER_GROUPS.some(group => userGroups.includes(group))) {
      setDisabled(false);
    } else if (isStandardEval && evalCaseType === REPAYMENT_CASE_TYPE
      && STANDARDEVAL_REQUIRED_USER_GROUPS.some(group => userGroups.includes(group))) {
      setDisabled(false);
    }

    if (DISASTER_EDIT_USER_GROUPS.some(group => userGroups.includes(group))) {
      setDisasterEditIconDisabled(false);
    }
  }, [user, checklistCenterPaneView]);

  const findColIndex = caseInformation.findIndex(i => (i.columnName === 'workoutType'));
  const { resolutionChoiceType } = universalActionResp;
  const enableNonDelApprovalDate = (
    NONDELAPPROVALDATE_CASETYPE.includes(resolutionChoiceType)
    || (resolutionChoiceType === 'Traditional Modification' && caseInformation[findColIndex].columnVal === 'VA Traditional Modification')
  );

  const checkNonDelegationDate = () => {
    let valid = true;
    let nonDelDateInfo = null;
    let delegateType = '';
    const delegateTypeInfo = generalInformation.find(i => (i.columnName === LOCKED_DELEGATE_TYPE));
    if (delegateTypeInfo) {
      delegateType = delegateTypeInfo.columnVal ? delegateTypeInfo.columnVal : '';
    }
    const nonDelDateFromGenInfo = generalInformation
      .find(i => (i.columnName === NON_DEL_APPROVAL_DATE));
    const nonDelDateFromCaseInfo = caseInformation
      .find(i => (i.columnName === NON_DEL_APPROVAL_DATE));
    if (nonDelDateFromGenInfo) nonDelDateInfo = nonDelDateFromGenInfo;
    if (nonDelDateFromCaseInfo) nonDelDateInfo = nonDelDateFromCaseInfo;
    const nonDelegatedDate = nonDelDateInfo.columnVal ? nonDelDateInfo.columnVal.toString() : '';
    const currentDate = moment().toString();
    if (delegateType === NON_DELEGATED && status === OPEN) {
      if (nonDelegatedDate === '') {
        setLockValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG1,
        }));
        valid = false;
      }
      if (moment(nonDelegatedDate).isBefore('2020-01-01', 'year')) {
        setLockValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG2,
        }));
        valid = false;
      }
      if (moment(nonDelegatedDate).isAfter(currentDate)) {
        setLockValidation(prev => ({
          ...prev,
          isPopupVisible: true,
          popUpMessage: NON_DEL_DATE_ERR_MSG3,
        }));
        valid = false;
      }
    }
    return valid;
  };

  const handleLock = () => {
    if (!enableNonDelApprovalDate) {
      lockCaseRules();
    } else {
      const validNonDelAppDate = checkNonDelegationDate();
      if (validNonDelAppDate) {
        lockCaseRules();
      }
    }
  };

  const handleCalculate = () => {
    if (!enableNonDelApprovalDate) {
      calculateCaseRules();
    } else {
      const validNonDelAppDate = checkNonDelegationDate();
      if (validNonDelAppDate) {
        calculateCaseRules();
      }
    }
  };
  const handleBack = () => {
    setChecklistCenterPaneData(STANDARD_EVAL_SCREEN);
  };

  const handleReject = () => {
    setRejectreasonPopupStatus(true);
  };

  const handleLockPopupClose = () => {
    setLockValidation(prev => ({ ...prev, isPopupVisible: false }));
  };

  const handleClose = () => {
    toggleLockCase(false);
  };

  const handleConfirm = () => {
    saveLockCase();
  };

  return (
    <>
      {evalHeaderInProgress && (
        <div styleName="evalHeaderLoader">
          <Loader message="Please wait" size={10} />
        </div>
      )}
      <Grid container styleName="eval-header-container">
        <Grid item styleName="evalcase-info-container">
          {evalCaseHeader ? (
            <Grid styleName="evalcase-header-content">
              <div>
                <Grid item styleName="case-id" xs={12}>
                  <h1>{!R.isNil(evalCaseType) && (`${evalCaseType}`)}</h1>
                </Grid>
                <Grid item styleName="case-id">
                  <div styleName="case-id2">
                    <span>{`${evalHeader.evalId}`}</span>
                    {!R.isNil(evalHeader && evalHeader.disasterFlag) && (
                    <>
                      <IconButton
                        aria-label="Edit"
                        component="span"
                        disabled={evalCaseType !== 'Pandemic' ? isDisasterEditIconDisabled : true}
                        onClick={() => {
                          fetchStates();
                          toggleDisasterPopup(true);
                        }}
                        styleName="btn-edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <span styleName="disaster-wrapper">
                        {!R.isNil(evalCaseType) && (
                        <>
                          {evalCaseType !== 'Pandemic' && R.isNil(evalHeader.disasterId)
                      && (
                      <>
                        <span styleName="text-red">Pending</span>
                        <span>Disaster Mapping</span>
                      </>
                      )}
                          <span styleName="text-red">{evalCaseType === 'Pandemic' ? 'COVID-19' : evalHeader.disasterName}</span>
                          {(evalCaseType === 'Pandemic' || evalHeader.disasterId !== null)
                            ? (
                              <span styleName="disaster-info">
                                {`declared on ${evalCaseType === 'Pandemic' ? '13 Mar 2020' : evalHeader.disasterDate} `}
                              </span>
                            ) : null}
                        </>
                        )}
                        <div styleName="escrowBanner">
                          {!R.isNil(evalHeader.escrowedDisasterFlag) && R.equals(evalHeader.escrowedDisasterFlag, '1') && (
                          <>
                            {`| Escrowed at the time of ${evalCaseType === 'Pandemic' ? 'COVID-19' : evalHeader.disasterName || ''} disaster `}
                          </>
                          )}
                          {!R.isNil(evalHeader.disasterFlag)
                    && (R.equals(evalHeader.disasterFlag.trim(), 'E') || R.equals(evalHeader.disasterFlag.trim(), 'PE')) && (
                      <>
                        {'| Employer Effected'}
                      </>
                          )}
                        </div>
                      </span>
                    </>
                    )}
                  </div>
                </Grid>
                <EvalPopup
                  evalDtls={{
                    disasterFlag: evalHeader.disasterFlag ? evalHeader.disasterFlag.trim() : '',
                    disasterId: evalHeader.disasterId,
                    state: evalHeader.state,
                    county: evalHeader.county,
                  }}
                  handleClose={() => toggleDisasterPopup(false)}
                  showPopup={isDisasterPopupOpen}
                  title="Disaster Search"
                />
              </div>
            </Grid>
          ) : (
            <>
              <h1>{evalCaseType}</h1>
              <div styleName="case-id3">
                <span>{caseId}</span>
              </div>
            </>
          )}

        </Grid>
        <Grid item>
          <div styleName="top-head">
            <p>
              {'Creator: '}
              <span>{createBy}</span>
            </p>
            <p>
              {'Created on '}
              <span>{createdOn}</span>
            </p>
            <p>
              {'Status: '}
              <span>{status}</span>
            </p>
            <p>
              {'Sub-Status: '}
              <span>{subStatus}</span>
            </p>
          </div>
          {!evalCaseHeader && (
            <div styleName="bottom-head">
              <div>
                {status && status.toLowerCase() === 'open' && (
                  <>
                    <Button color="primary" disabled={isDisabled || inProgress || lockedFlag === 1 || evalHeaderInProgress} onClick={handleCalculate} style={{ borderWidth: '2px' }} variant="outlined">
                  CALCULATE
                    </Button>
                    <Button color="primary" disabled={isDisabled || inProgress || lockedFlag === 1 || evalHeaderInProgress} onClick={handleLock} variant="contained">
                  LOCK
                    </Button>
                  </>
                )}
                <SweetAlertBox
                  message={lockValidation.popUpMessage}
                  onConfirm={handleLockPopupClose}
                  show={lockValidation.isPopupVisible}
                  type="Failed"
                />
                <ConfirmationPopup
                  cancelButtonText="NO"
                  confirmButtonText="YES"
                  message="Are you sure you want to lock?"
                  onCancel={handleClose}
                  onConfirm={handleConfirm}
                  show={lockValicationStatus}
                  showCancelButton
                  showConfirmButton
                />
              </div>
              <Button
                onClick={handleBack}
                size="large"
                startIcon={<ArrowBackIosIcon />}
                styleName="back-button"
              >
                BACK
              </Button>
            </div>
          )}
          {
            evalCaseHeader && (
              <div styleName="bottom-head">
                {evalHeader && evalHeader.milestoneMessage && (
                  <p styleName="small-text">
                    {`${subStatus} : `}
                    <span>{evalHeader.milestoneMessage || ''}</span>
                  </p>
                )}

                <Button color="primary" disabled={disableReject} onClick={handleReject} styleName="rejectButton" variant="outlined">
                  REJECT
                </Button>
              </div>
            )
          }
          {
            isOpen && <RejectReasonPopup />
          }
        </Grid>
        {!evalCaseHeader && <OverrideMessage />}
      </Grid>
      {!evalCaseHeader && (<Divider style={{ marginBottom: '8px' }} />)}
    </>
  );
}

EvalHeader.defaultProps = {
  setRejectreasonPopupStatus: () => { },
  caseInformation: [],
  checklistCenterPaneView: 'Checklist',
  generalInformation: [],
  inProgress: false,
  isEditable: true,
  lockValicationStatus: false,
  headerData: { evalHeader: {} },
  universalActionResp: {},
  evalHeaderInProgress: false,
};

EvalHeader.propTypes = {
  calculateCaseRules: PropTypes.func.isRequired,
  caseInformation: PropTypes.arrayOf(PropTypes.shape()),
  checklistCenterPaneView: PropTypes.string,
  evalHeaderInProgress: PropTypes.bool,
  fetchStates: PropTypes.func.isRequired,
  generalInformation: PropTypes.arrayOf(PropTypes.shape()),
  headerData: PropTypes.shape({
    caseInfo: PropTypes.shape({}),
    createBy: PropTypes.string,
    createdOn: PropTypes.string,
    data: PropTypes.shape({
      caseId: PropTypes.number,
    }),
    evalCaseHeader: PropTypes.bool,
    evalCaseType: PropTypes.string,
    evalHeader: PropTypes.any,
    lockedFlag: PropTypes.number,
    status: PropTypes.string,
    subStatus: PropTypes.string,
  }),
  inProgress: PropTypes.bool,
  isDisasterPopupOpen: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  lockCaseRules: PropTypes.func.isRequired,
  lockValicationStatus: PropTypes.bool,
  saveLockCase: PropTypes.func.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setRejectreasonPopupStatus: PropTypes.func,
  toggleDisasterPopup: PropTypes.func.isRequired,
  toggleLockCase: PropTypes.func.isRequired,
  universalActionResp: PropTypes.shape(),
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  caseInformation: evalSelectors.caseInformation(state),
  generalInformation: evalSelectors.generalInformation(state),
  user: loginSelectors.getUser(state),
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
  inProgress: evalSelectors.isCalculateLoading(state),
  universalActionResp: evalSelectors.universalActionResp(state),
  lockValicationStatus: evalSelectors.lockValicationStatus(state),
  isDisasterPopupOpen: evalSelectors.isDisasterPopupOpen(state),
  isOpen: evalSelectors.popUpStatus(state),
});
const mapDispatchToProps = dispatch => ({
  setRejectreasonPopupStatus: evalOperations.setRejectreasonPopupStatusOperation(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  calculateCaseRules: evalOperations.calculateCaseRulesOpn(dispatch),
  lockCaseRules: evalOperations.lockCaseRulesOperation(dispatch),
  saveLockCase: evalOperations.saveLockCaseOperation(dispatch),
  toggleLockCase: evalOperations.toggleLockCaseOperation(dispatch),
  fetchStates: evalOperations.fetchStatesOperation(dispatch),
  toggleDisasterPopup: evalOperations.toggleDisasterPopupOperation(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EvalHeader);
