const FETCH_UNIVERSAL_ACTION_DATA = 'app/eval/FETCH_UNIVERSAL_ACTION_DATA';
const SET_UNIVERSAL_ACTION_DATA = 'app/eval/SET_UNIVERSAL_ACTION_DATA';
const FETCH_ELIGIBILITY_DATA = 'app/eval/FETCH_ELIGIBILITY_DATA';
const SET_ELIGIBILITY_DATA = 'app/eval/SET_ELIGIBILITY_DATA';
const SET_CASEHEADERINFO_DATA = 'app/eval/SET_CASEHEADERINFO_DATA';
const FETCH_CALC_LOG_DATA = 'app/eval/FETCH_CALC_LOG_DATA';
const SET_CALC_LOG_DATA = 'app/eval/SET_CALC_LOG_DATA';
const FETCH_EVAL_DETAILS = 'app/eval/FETCH_EVAL_DETAILS';
const SET_EVAL_DETAILS = 'app/eval/SET_EVAL_DETAILS';
const SET_INV_GRP_CODES = 'app/eval/SET_INV_GRP_CODES';
const SAVE_ACCEPTANCE_FLAG = 'app/eval/SAVE_ACCEPTANCE_FLAG';
const FETCH_EVALSCREEN_DETAILS = 'app/eval/FETCH_EVALSCREEN_DETAILS';
const SET_EVALSCREEN_DETAILS = 'app/eval/SET_EVALSCREEN_DETAILS';
const SAVE_DISASTERDEC_DATA = 'app/eval/SAVE_DISASTERDEC_DATA';

// snackbar msg display
const SET_SNACK_BAR_VALUES = 'app/eval/SET_SNACK_BAR_VALUES';

// loader
const SHOW_LOADER = 'app/eval/SHOW_LOADER';
const HIDE_LOADER = 'app/eval/HIDE_LOADER';
const SAVE_ELIGIBILITY_DATA = 'app/eval/SAVE_ELIGIBILITY_DATA';
const FETCH_CALCLOGS_LOADER = 'app/eval/FETCH_CALCLOGS_LOADER';
const GET_CASEHEADERINFO_DATA = 'app/eval/GET_CASEHEADERINFO_DATA';
const CREATE_NEW_EVAL_CASE_LOADER = 'app/eval/CREATE_NEW_EVAL_CASE_LOADER';
const SET_EVAL_HEADER_IN_PROGRESS = 'app/eval/SET_EVAL_HEADER_IN_PROGRESS';


// reject reason pop up
const FETCH_REJECT_REASONS = 'app/eval/FETCH_REJECT_REASONS';
const SET_REJECT_REASONS = 'app/eval/SET_REJECT_REASONS';
const SET_POPUP_STATUS = 'app/eval/SET_POPUP_STATUS';
const SAVE_REJECT_REASON = 'app/eval/SAVE_REJECT_REASON';

const FETCH_TRACKPAYMENT_DATA = 'app/eval/FETCH_TRACKPAYMENT_DATA';
const SET_TRACKPAYMENT_DATA = 'app/eval/SET_TRACKPAYMENT_DATA';
const UPDATE_TRACKPAYMENT_DATES = 'app/eval/UPDATE_TRACKPAYMENT_DATES';
const DELETE_TRACKPAYMENT = 'app/eval/DELETE_TRACKPAYMENT';

const SUBMIT_COLLATERAL_ACCEPTANCE = 'app/eval/SUBMIT_COLLATERAL_ACCEPTANCE';
const SET_COLLATERAL_ACCEPTANCE = 'app/eval/SET_COLLATERAL_ACCEPTANCE';
const SET_STREAMLINE_MOD_ACCEPTANCE = 'app/eval/SET_STREAMLINE_MOD_ACCEPTANCE';

// fulfilment checklist
const FETCH_FULFILLMENT_CHECKLIST = 'app/eval/FETCH_FULFILLMENT_CHECKLIST';
const SET_FULFILLMENT_CHECKLIST = 'app/eval/SET_FULFILLMENT_CHECKLIST';
const SAVE_FULFILLMENT_CHECKLIST = 'app/eval/SAVE_FULFILLMENT_CHECKLIST';
const SUBMIT_FULFILLMENT_CHECKLIST = 'app/eval/SUBMIT_FULFILLMENT_CHECKLIST';

// certain mortgage loan checklist
const FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST = 'app/eval/FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST';
const SET_CERTAIN_MORGAGE_LOAN_CHECKLIST = 'app/eval/SET_CERTAIN_MORGAGE_LOAN_CHECKLIST';
const SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST = 'app/eval/SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST';
const SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST = 'app/eval/SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST';

const FETCH_BOOKING_DATA = 'app/eval/FETCH_BOOKING_DATA';
const SET_BOOKING_DATA = 'app/eval/SET_BOOKING_DATA';

const CALCULATE_CASE_RULES = 'app/eval/CALCULATE_CASE_RULES';
const LOCK_CASE_RULES = 'app/eval/LOCK_CASE_RULES';
const TOGGLE_LOCK_CASE = 'app/eval/TOGGLE_LOCK_CASE';
const SAVE_LOCK_CASE = 'app/eval/SAVE_LOCK_CASE';
const SAVE_CASEDETAILS = 'app/eval/SAVE_CASEDETAILS';
const CALCULATE_CASE_LOADER = 'app/eval/CALCULATE_CASE_LOADER';
const CREATE_NEW_EVAL_CASE = 'app/eval/CREATE_NEW_EVAL_CASE';
const FETCH_CASE_TYPES = 'app/eval/FETCH_CASE_TYPES';
const SET_CASE_TYPES = 'app/eval/SET_CASE_TYPES';
const FETCH_WORKOUTTYPE = 'app/eval/FETCH_WORKOUTTYPE';
const SET_WORKOUTTYPE_DATA = 'app/eval/SET_WORKOUTTYPE_DATA';
const SET_CREATE_BUTTON_STATUS = 'app/eval/SET_CREATE_BUTTON_STATUS';
const SET_SELECTED_CASEID = 'app/eval/SET_SELECTED_CASEID';
const CREATE_NEW_MOD = 'app/eval/CREATE_NEW_MOD';
const SET_NEW_MOD_DETAILS = 'app/eval/SET_NEW_MOD_DETAILS';
const SET_SELECTED_CASE_DETAIL = 'app/eval/SET_SELECTED_CASE_DETAIL';
const SET_EVALSCREEN_ICON = 'app/eval/SET_EVALSCREEN_ICON';
const FETCH_BOOKING_TAB_DATA = 'app/eval/FETCH_BOOKING_TAB_DATA';
const SET_BOOKING_TAB_DATA = 'app/eval/SET_BOOKING_TAB_DATA';
const SET_BOOKING_TAB_CORP_ADVN_DATA = 'app/eval/SET_BOOKING_TAB_CORP_ADVN_DATA';

const ADD_CASE_TO_WATERFALL_SEQ = 'app/eval/ADD_CASE_TO_WATERFALL_SEQ';

const FETCH_LOCK_OVERRIDE = 'app/eval/FETCH_LOCK_OVERRIDE';
const SET_LOCK_OVERRIDE = 'app/eval/SET_LOCK_OVERRIDE';
const SET_PRE_LOCK_CASE_RULES = 'app/eval/SET_PRE_LOCK_CASE_RULES';
const MOVE_CASE_SEQ = 'app/eval/MOVE_CASE_SEQ';
const REMOVE_WATERFALL = 'app/eval/REMOVE_WATERFALL';
const SET_LETTER_GENERATION = 'app/eval/SET_LETTER_GENERATION';
const CHECK_LETTER_GENERATION_STATUS = 'app/eval/CHECK_LETTER_GENERATION_STATUS';
const FETCH_STATES = 'app/eval/FETCH_STATES';
const SET_STATES = 'app/eval/SET_STATES';
const TOGGLE_DISASTER_POPUP = 'app/eval/TOGGLE_DISASTER_POPUP';
const FETCH_COUNTYS = 'app/eval/FETCH_COUNTYS';
const SET_COUNTYS = 'app/eval/SET_COUNTYS';
const FETCH_DISASTERDTLS = 'app/eval/FETCH_DISASTERDTLS';
const SET_DISASTERDTLS = 'app/eval/SET_DISASTERDTLS';
const FETCH_CASE_NPV_DETAILS = 'app/eval/FETCH_CASE_NPV_DETAILS';
const SET_NPV_DATA = 'app/eval/SET_NPV_DATA';
const DISASTER_POPUP_LOADER = 'app/eval/DISASTER_POPUP_LOADER';

const UPDATE_TRACKPAYMENT_RESPONSE = 'app/eval/UPDATE_TRACKPAYMENT_RESPONSE';
const SET_FINANCIAL_DROPDOWNS = 'app/eval/SET_FINANCIAL_DROPDOWNS';
const FETCH_FINANCIAL_DROPDOWNS = 'app/eval/FETCH_FINANCIAL_DROPDOWNS';
const SAVE_FINANCIAL_DATA = 'app/eval/SAVE_FINANCIAL_DATA';
const UPDATE_HARDSHIP_ID = 'app/eval/UPDATE_HARDSHIP_ID';
const CREATE_EVAL_LOADER = 'app/eval/CREATE_EVAL_LOADER';
const FETCH_CASE_UNIVERSAL_DETAILS = 'app/eval/FETCH_CASE_UNIVERSAL_DETAILS';
const FETCH_WORKOUT_TYPE_LOADER = 'app/eval/FETCH_WORKOUT_TYPE_LOADER';
const CREATE_NEW_CASE = 'app/eval/CREATE_NEW_CASE';
const SET_RULE_ENGINE_ERROR_MSG = 'app/eval/SET_RULE_ENGINE_ERROR_MSG';
const SAVE_OVERRIDDEN_RULE = 'app/eval/SAVE_OVERRIDDEN_RULE';
const LOAD_MOD_CALC = 'app/eval/LOAD_MOD_CALC';
const FINANCIAL_DATA_LOADER = 'app/eval/FINANCIAL_DATA_LOADER';


export {
  ADD_CASE_TO_WATERFALL_SEQ,
  FETCH_UNIVERSAL_ACTION_DATA,
  SET_UNIVERSAL_ACTION_DATA,
  FETCH_ELIGIBILITY_DATA,
  SET_ELIGIBILITY_DATA,
  SET_CASEHEADERINFO_DATA,
  FETCH_CALC_LOG_DATA,
  FETCH_CALCLOGS_LOADER,
  SET_CALC_LOG_DATA,
  SET_SNACK_BAR_VALUES,
  SHOW_LOADER,
  HIDE_LOADER,
  SAVE_ELIGIBILITY_DATA,
  GET_CASEHEADERINFO_DATA,
  SET_REJECT_REASONS,
  FETCH_REJECT_REASONS,
  SAVE_REJECT_REASON,
  SET_POPUP_STATUS,
  FETCH_TRACKPAYMENT_DATA,
  SET_TRACKPAYMENT_DATA,
  UPDATE_TRACKPAYMENT_DATES,
  DELETE_TRACKPAYMENT,
  SUBMIT_COLLATERAL_ACCEPTANCE,
  FETCH_FULFILLMENT_CHECKLIST,
  SET_FULFILLMENT_CHECKLIST,
  SAVE_FULFILLMENT_CHECKLIST,
  SUBMIT_FULFILLMENT_CHECKLIST,
  FETCH_BOOKING_DATA,
  SET_BOOKING_DATA,
  CALCULATE_CASE_RULES,
  LOCK_CASE_RULES,
  TOGGLE_LOCK_CASE,
  SAVE_LOCK_CASE,
  SAVE_CASEDETAILS,
  CALCULATE_CASE_LOADER,
  SET_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  FETCH_CERTAIN_MORGAGE_LOAN_CHECKLIST,
  SAVE_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
  SUBMIT_CERTAIN_MORTGAGE_LOAN_CHECKLIST,
  FETCH_EVAL_DETAILS,
  SET_EVAL_DETAILS,
  SET_INV_GRP_CODES,
  SAVE_ACCEPTANCE_FLAG,
  SET_CASE_TYPES,
  FETCH_CASE_TYPES,
  FETCH_WORKOUTTYPE,
  SET_WORKOUTTYPE_DATA,
  SET_CREATE_BUTTON_STATUS,
  FETCH_EVALSCREEN_DETAILS,
  SET_EVALSCREEN_DETAILS,
  SAVE_DISASTERDEC_DATA,
  SET_STREAMLINE_MOD_ACCEPTANCE,
  SET_SELECTED_CASEID,
  CREATE_NEW_MOD,
  SET_NEW_MOD_DETAILS,
  SET_SELECTED_CASE_DETAIL,
  SET_EVALSCREEN_ICON,
  FETCH_LOCK_OVERRIDE,
  SET_LOCK_OVERRIDE,
  SET_PRE_LOCK_CASE_RULES,
  CREATE_NEW_EVAL_CASE,
  CREATE_NEW_EVAL_CASE_LOADER,
  FETCH_BOOKING_TAB_DATA,
  SET_BOOKING_TAB_DATA,
  SET_BOOKING_TAB_CORP_ADVN_DATA,
  MOVE_CASE_SEQ,
  REMOVE_WATERFALL,
  UPDATE_TRACKPAYMENT_RESPONSE,
  SET_LETTER_GENERATION,
  CHECK_LETTER_GENERATION_STATUS,
  FETCH_STATES,
  SET_STATES,
  TOGGLE_DISASTER_POPUP,
  FETCH_COUNTYS,
  SET_COUNTYS,
  FETCH_DISASTERDTLS,
  SET_DISASTERDTLS,
  FETCH_CASE_NPV_DETAILS,
  SET_NPV_DATA,
  DISASTER_POPUP_LOADER,
  SET_FINANCIAL_DROPDOWNS,
  FETCH_FINANCIAL_DROPDOWNS,
  SAVE_FINANCIAL_DATA,
  UPDATE_HARDSHIP_ID,
  CREATE_EVAL_LOADER,
  FETCH_CASE_UNIVERSAL_DETAILS,
  FETCH_WORKOUT_TYPE_LOADER,
  SET_EVAL_HEADER_IN_PROGRESS,
  CREATE_NEW_CASE,
  SET_RULE_ENGINE_ERROR_MSG,
  SAVE_OVERRIDDEN_RULE,
  LOAD_MOD_CALC,
  SET_COLLATERAL_ACCEPTANCE,
  FINANCIAL_DATA_LOADER,
};
