import {
  FETCH_GRID_DATA,
  SET_SA_SELECTED_TAB,
  FILTER_RESULTS,
  UNREJECT_REQUEST,
  FETCH_SUBSTATUS_REQUEST_OPTIONS,
  SUBMIT_STATUS_OPTIONS,
  SUBMIT_BULK_STATUS_OPTIONS,
  EVAL_DISASTER_ID_REQUEST_SAGA,
  GATHER_AUTO_COMPLETE_MATCH,
  FETCH_CASE_TYPE,
  SUBMIT_FORCE_CREATE_CASE,
  SUBMIT_FORCE_CREATE_EVAL_CASE,
  FETCH_BULK_ACTION_RSN_CDS,
  EXECUTE_ACTION,
  SET_ID_TYPE,
  FETCH_BUSINESS_ADMIN_GRID_DATA,
  SUBMIT_MOD_SUPPORT_REQUEST,
  SET_BA_SELECTED_TAB,
  FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY,
  FILTER_BA_RESULTS,
  GATHER_AUTO_COMPLETE_BA_MATCH,
  SUBMIT_UPLOAD_SHEET_REQUEST,
  FETCH_MOD_INVESTORS,
  SET_INVESTOR_NAME,
} from './types';

const fetchGridDetails = (pageNumber, pageSize, idType) => ({
  type: FETCH_GRID_DATA,
  payload: {
    pageNumber,
    pageSize,
    idType,
  },
});

const fetchMatchedDataAction = value => ({
  type: GATHER_AUTO_COMPLETE_MATCH,
  payload: value,
});

const setSupportAdminActiveTabAction = value => ({
  type: SET_SA_SELECTED_TAB,
  payload: value,
});

const filterResultsAction = (value, pageNumber, pageSize) => ({
  type: FILTER_RESULTS,
  payload: {
    filterBy: value,
    pageNumber,
    pageSize,
  },
});

const fetchBusinessAdminGridData = (page, pageSize) => ({
  type: FETCH_BUSINESS_ADMIN_GRID_DATA,
  payload: { page, pageSize },
});

const submitModSupportRequest = payload => ({
  type: SUBMIT_MOD_SUPPORT_REQUEST,
  payload,
});

const setBAselectedTabAction = value => ({
  type: SET_BA_SELECTED_TAB,
  payload: value,
});

const fetchBusinessAdminUploadDataHistoryAction = (page, pageSize) => ({
  type: FETCH_BUSINESS_ADMIN_UPLOAD_DATA_HISTORY,
  payload: { page, pageSize },
});

const applyBAFilterAction = (value, pageNumber, pageSize) => ({
  type: FILTER_BA_RESULTS,
  payload: {
    filterBy: value,
    pageNumber,
    pageSize,
  },
});

const unRejectRequestAction = value => ({
  type: UNREJECT_REQUEST,
  payload: value,
});

const fetchSubstatusRequestOption = payload => ({
  type: FETCH_SUBSTATUS_REQUEST_OPTIONS,
  payload,
});

const submitStatusOption = payload => ({
  type: SUBMIT_STATUS_OPTIONS,
  payload,
});

const submitEvalDisasterIdRequestAction = payload => ({
  type: EVAL_DISASTER_ID_REQUEST_SAGA,
  payload,
});

const submitBulkStatusOption = payload => ({
  type: SUBMIT_BULK_STATUS_OPTIONS,
  payload,
});

const fetchCaseTypesAction = () => ({
  type: FETCH_CASE_TYPE,
});

const submitForceCreateCaseAction = payload => ({
  type: SUBMIT_FORCE_CREATE_CASE,
  payload,
});

const submitForceCreateEvalCaseAction = payload => ({
  type: SUBMIT_FORCE_CREATE_EVAL_CASE,
  payload,
});

const fetchBulkActionReasonCodes = () => ({
  type: FETCH_BULK_ACTION_RSN_CDS,
});

const executeAction = payload => ({
  type: EXECUTE_ACTION,
  payload,
});

const setIdType = payload => ({
  type: SET_ID_TYPE,
  payload,
});

const fetchMatchedBADataAction = value => ({
  type: GATHER_AUTO_COMPLETE_BA_MATCH,
  payload: value,
});

const submitUploadSheetDataAction = (actionType, sheetData) => ({
  type: SUBMIT_UPLOAD_SHEET_REQUEST,
  payload: { actionType, sheetData },
});

const getModInvestorsAction = () => ({
  type: FETCH_MOD_INVESTORS,
});

const setInvestorNameAction = value => ({
  type: SET_INVESTOR_NAME,
  payload: value,
});

export {
  getModInvestorsAction,
  fetchBusinessAdminGridData,
  setBAselectedTabAction,
  fetchBusinessAdminUploadDataHistoryAction,
  applyBAFilterAction,
  fetchMatchedBADataAction,
  submitModSupportRequest,
  submitUploadSheetDataAction,
  fetchCaseTypesAction,
  fetchGridDetails,
  fetchMatchedDataAction,
  setSupportAdminActiveTabAction,
  filterResultsAction,
  unRejectRequestAction,
  fetchSubstatusRequestOption,
  submitStatusOption,
  submitBulkStatusOption,
  submitEvalDisasterIdRequestAction,
  submitForceCreateCaseAction,
  submitForceCreateEvalCaseAction,
  fetchBulkActionReasonCodes,
  executeAction,
  setIdType,
  setInvestorNameAction,
};
