import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ServicerHistory from './ServicerHistory';
import styles from './ReportedData.css';

const ReportedData = ({ caseManagements = { caseManagements: [] } }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleAccordion = () => setIsExpanded(!isExpanded);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleAccordion();
    }
  };

  const reportsData = typeof caseManagements.reportsData === 'object' ? caseManagements.reportsData : [];
  const [isVisible] = reportsData.length === 0 ? useState(false) : useState(true);
  const fullDate = caseManagements.date !== null ? caseManagements.date : '';
  // const date = fullDate.replace('[HSSN Initiate Request]', '');
  return (
    < >
      <div style={{ backgroundColor: 'white', paddingBottom: '1rem', borderRadius: '.5rem' }}>
        <div
          aria-expanded={isExpanded}
          className={styles.accordion}
          onClick={toggleAccordion}
          onKeyDown={handleKeyDown}
          role="button"
          style={{ height: '1rem' }}
          tabIndex={0}
        >
          <div className={styles.accordion_content}>
            <div className={styles.accordion_title}>
           HSSN Initiate Request
            </div>

            <div className={styles.info_section}>
              <div className={styles.calendar_info}>
                { isVisible && <img alt="Calendar" className={styles.icon} src="/static/img/calendar.png" />}
                <span>
                  &nbsp;
                  {fullDate}
                </span>

              </div>
            </div>
            <div className={styles.accordion_arrow}>
              <img
                alt="toggle"
                height="7"
                src="/static/img/downArrow.svg"
                style={{ width: '14px', marginLeft: '5px' }}
              />
            </div>
          </div>
        </div>
        <hr style={{
          borderColor: 'rgba(204, 207, 214, 0.33)', width: '95%', marginLeft: '10px', marginTop: '2rem',
        }}
        />
        {isVisible && isExpanded && (
        <ServicerHistory reportsData={reportsData} />
        )}
      </div>
    </>
  );
};

ReportedData.propTypes = {
  caseManagements: PropTypes.shape({
    date: PropTypes.string.isRequired,

    errorsData: PropTypes.arrayOf(
      PropTypes.shape({
        Message: PropTypes.string.isRequired,
        MessageID: PropTypes.string.isRequired,
        Result: PropTypes.string.isRequired,
      }).isRequired,
    ),
    reportsData: PropTypes.arrayOf(
      PropTypes.shape({
        Message: PropTypes.string.isRequired,
        MessageID: PropTypes.string.isRequired,
        Result: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }),
};

ReportedData.defaultProps = {
  caseManagements: { },
};


export default ReportedData;
