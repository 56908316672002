const PAYMENT_SUPPLEMENT_STEPS = {
  Step1: 'Step 1 - Calculate Partial Claim Funds Available',
  Step2: 'Step 2 - Calculate Amount Required to Reinstate the Mortgage Using a Payment Supplement',
  Step3: 'Step 3 - Calculate Partial Claim Funds Available for Monthly Principal Reduction (MoPR)',
  Step4: 'Step 4 - Calculate Maximum MoPR',
  Step5: 'Step 5 - Calculate the MoPR',
  Step6: 'Step 6 - Payment Reduction Test: Determine if a MoPR of no less than 5% and no less than $20.00 can be achieved for 36 months',
  Step7: 'Step 7 - Compare Savings with Available Permanent Home Retention Options',
};
const PAYMENT_SUPPLEMENT_ROWS = [
  {
    id: 'A',
    name: 'A. Enter the unpaid principal balance (UPB) at time of the initial Partial Claim, or if no previous Partial Claim, enter the UPB as of the Date of Default:',
    step: 'Step1',
  },
  {
    id: 'B',
    name: 'B. Multiply the result in Step 1.A by 30%:',
    step: 'Step1',
  },
  {
    id: 'C',
    name: 'C. Enter the total of all previous Partial Claim(s) (if applicable):',
    step: 'Step1',
  },
  {
    id: 'D',
    name: 'D. Subtract Step 1.C from Step 1.B to determine the maximum Partial Claim funds available:',
    step: 'Step1',
    category: 'Are there Remaining Partial Claim funds available?',
    categoryValue: ['If Yes, go to Step 2', 'If No, Borrower is not eligible for Payment Supplement'],
  },
  {
    id: 'A',
    name: 'Enter the total amount needed to bring the Mortgage current:',
    step: 'Step2',
  },
  {
    id: 'A',
    name: 'A. Subtract the amount in Step 2 from the amount in Step 1.D to determine the amount of PC funds available for the MoPR:',
    step: 'Step3',
  },
  {
    id: 'B',
    name: 'B. Is the amount in step 3.A greater than 0?',
    step: 'Step3',
  },
  {
    id: 'A',
    name: {
      1: { name: ' Step 4.A - Calculate 25% P&I reduction:\n1. Enter the P&I portion of the Borrowers Monthly Mortgage Payment as of the date the Payment Supplement Period begins:' },
      2: { name: '2. Multiply step 4.A.1 by 25%:' },
      3: { name: '3. Enter the principal portion only of the Monthly Mortgage Payment as of the date the Payment Supplement Period begins:' },
    },
    step: 'Step4',
  },
  {
    id: 'B',
    name: 'Step 4.B - Determine Maximum MoPR:\nEnter the lesser of Step 4.A.2 or Step 4.A.3 to determine the maximum MoPR:',
    step: 'Step4',
    style: 'subheader',
    display: {
      category: 'Does the Case Payment Supplement Reduction (step 4.A.2) less than Case Principal (4.A.3)?',
      categoryValue: {
        1: { name: 'If Yes, use the Case Payment Supplement Reduction' },
        2: { name: 'If No, use the Case Principal' },
      },
    },
  },
  {
    id: 'A',
    name: {
      1: { name: 'Step 5.A - Determine if the Borrower has sufficient PC funds available to achieve the maximum MoPR for 36 months:\n1. Multiply the result of Step 4.B by 36 months to determine the amount needed to provide 36 months of the maximum MoPR:' },
      2: {
        name: '2. Is the amount of PC funds available for the MoPR in Step 3.A greater than or equal to the maximum MoPR for 36 months?',
        categoryValue: ['If Yes, the maximum MoPR calculated in Step 4.B is the MoPR for the Payment Supplement Period. Proceed to Step 6', 'If No, proceed to Step 5.B.'],
      },
    },
    step: 'Step5',
  },
  {
    id: 'B',
    name: 'Step 5.B - If the Borrower does not have sufficient PC funds available for a maximum MoPR for 36 months (as calculated in step 5.A), calculate the MoPR.\nDivide the amount of PC funds available for the MoPR in Step 3.A by 36.\nThis is the MoPR for the Payment Supplement Period. Proceed to Step 6.',
    step: 'Step5',
  },
  {
    id: 'A',
    name: 'A. Divide the MoPR as determined in Step 5 by the Current P&I Payment in Step 4.A.1:',
    step: 'Step6',
    type: '%',
  },
  {
    id: 'B',
    name: 'B. Is the result in Step 6.A greater than 5.00%?',
    step: 'Step6',
  },
  {
    id: 'C',
    name: 'C. Is the amount of the MoPR as determined in Step 5 equal to or greater than $20.00?',
    step: 'Step6',
  },
  {
    id: 'D',
    name: 'D. Are the results in both Step 6.B and Step 6.C "Yes" (MoPR percent > 5.00% and MoPR amount > $20.00)',
    step: 'Step6',
    categoryValue: ['If Yes, proceed to step 7', 'If No, Borrower is not eligible for Payment Supplement.'],
  },
  {
    id: 'A',
    name: 'Does the Payment Supplement achieve the same or lower P&I monthly payment when comparing to the COVID-19 Recovery Modification',
    step: 'Step7',
  },
  {
    id: 'B',
    name: 'If No, the Mortgagee must offer the Borrower the COVID-19 Recovery Modification',
    step: 'Step7',
  },
  {
    id: 'C',
    name: 'If Yes, and all other steps have passed above, then Mortgagee must offer the Borrower the Payment Supplement',
    step: 'Step7',
  },
];

const PAYMENT_SUPPLEMENT_DATA_MAPPING = {
  upbAtDefault: { step: 'Step1.A', defaultValue: '' },
  maxPartialClaimAmount: { step: 'Step1.B', defaultValue: '' },
  partialClaimAmount: { step: ['Step1.C', 'Step2.A'], defaultValue: '' },
  remainingPartialClaimAmount: { step: 'Step1.D', defaultValue: '' },
  totalRemainingSupplementAmount: { step: 'Step3.A', defaultValue: '' },
  remainingSupplementMoreThan0: { step: 'Step3.B', dataType: 'boolean', defaultValue: null },
  casePayment: { step: 'Step4.A.1', defaultValue: '' },
  casePayment25Percent: { step: ['Step4.A.2', 'Step4.B.1'], defaultValue: '' },
  casePrincipal: { step: ['Step4.A.3', 'Step4.B.2'], defaultValue: '' },
  // monthlyMaximumMoPR: { step: 'Step4.B.1', defaultValue: '' },
  totalMaximumMoPR: { step: 'Step5.A.1', defaultValue: '' },
  totalRemainingSupplementIsMoreOrEqualTotalMaximumMoPR: { step: 'Step5.A.2', dataType: 'boolean', defaultValue: null },
  monthlyRemainingSupplementAmount: { step: 'Step5.B', defaultValue: '' },
  moprPercent: { step: 'Step6.A', defaultValue: '' },
  moprPercentEligible: { step: 'Step6.B', dataType: 'boolean', defaultValue: null },
  moprDollarEligible: { step: 'Step6.C', dataType: 'boolean', defaultValue: null },
  moprPercentAndDollarEligible: { step: 'Step6.D', dataType: 'boolean', defaultValue: null },
  borrowerPILessOrEqualPriorCasePI: { step: 'Step7.A', dataType: 'boolean', defaultValue: null },
  borrowerCasePI: { step: 'Step7.B', defaultValue: '' },
  priorCasePI: { step: 'Step7.C', defaultValue: '' },
};

module.exports = {
  PAYMENT_SUPPLEMENT_STEPS,
  PAYMENT_SUPPLEMENT_ROWS,
  PAYMENT_SUPPLEMENT_DATA_MAPPING,
};
